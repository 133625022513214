import React, { FC, useMemo } from 'react'
import { GOOD_RISK_COLOR, HIGH_RISK_COLOR, LOW_RISK_COLOR, MEDIUM_RISK_COLOR, NOT_STARTED_RISK_COLOR } from '../Report'
import { StyledText } from '../Report.styled'
import { getTempTranslation } from '../../../utils/getTempTranslation'
import {
  RiskLevelCircleContainer,
  RiskLevelCircleMiddle,
  RiskLevelCircleMiddleTextContainer,
  RiskLevelCircleQuadrant,
} from './RiskLevelCircle.styled'

//TODO remove once risk categories are finalized
export type Score = 1 | 2 | 3 | 4

interface Props {
  score: Score
}

export const RiskLevelCircle: FC<Props> = (props) => {
  const { score } = props

  //Direction of colors is LEFT -> RIGHT / TOP -> BOTTOM
  const colors = useMemo(() => {
    let colorArray: string[] = []
    switch (score) {
      case 1: {
        colorArray = [NOT_STARTED_RISK_COLOR, GOOD_RISK_COLOR, NOT_STARTED_RISK_COLOR, NOT_STARTED_RISK_COLOR]
        break
      }
      case 2: {
        colorArray = [NOT_STARTED_RISK_COLOR, LOW_RISK_COLOR, NOT_STARTED_RISK_COLOR, LOW_RISK_COLOR]
        break
      }
      case 3: {
        colorArray = [NOT_STARTED_RISK_COLOR, MEDIUM_RISK_COLOR, MEDIUM_RISK_COLOR, MEDIUM_RISK_COLOR]
        break
      }
      case 4: {
        colorArray = [HIGH_RISK_COLOR, HIGH_RISK_COLOR, HIGH_RISK_COLOR, HIGH_RISK_COLOR]
        break
      }
      default: {
        colorArray = [NOT_STARTED_RISK_COLOR, NOT_STARTED_RISK_COLOR, NOT_STARTED_RISK_COLOR, NOT_STARTED_RISK_COLOR]
      }
    }
    return colorArray
  }, [score])

  const riskCategories = {
    1: { title: getTempTranslation('healthCheck.report.score.good'), color: GOOD_RISK_COLOR },
    2: { title: getTempTranslation('healthCheck.report.score.low'), color: LOW_RISK_COLOR },
    3: { title: getTempTranslation('healthCheck.report.score.medium'), color: MEDIUM_RISK_COLOR },
    4: { title: getTempTranslation('healthCheck.report.score.high'), color: HIGH_RISK_COLOR },
  }

  return (
    <RiskLevelCircleContainer>
      <RiskLevelCircleQuadrant color={colors[0]} />
      <RiskLevelCircleQuadrant color={colors[1]} />
      <RiskLevelCircleQuadrant color={colors[2]} />
      <RiskLevelCircleQuadrant color={colors[3]} />
      <RiskLevelCircleMiddle>
        <RiskLevelCircleMiddleTextContainer>
          <StyledText isBold={true} color={riskCategories[score].color}>
            {riskCategories[score].title}
          </StyledText>
        </RiskLevelCircleMiddleTextContainer>
      </RiskLevelCircleMiddle>
    </RiskLevelCircleContainer>
  )
}
