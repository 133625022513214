import React, { FC, useCallback, useEffect, useState } from 'react'
import { ChatbotQuestionAnswer, Question } from '../../models'
import { WrapperWithTitleAndAnimation } from '../WrapperWithTitleAndAnimation'
import { OptionItem } from '../../models/api/response/OptionItem'
import { useI18N } from '../../hooks'
import { useApiGatewayContext } from '../../context'
import { useQuestionContext } from '../../context/QuestionContext'
import { SymptomItemList } from './SymptomItemList'
import styles from './SymptomSuggestionSelector.module.less'

/**
 * @param props The props object
 * @param props.question The current question
 * @param props.animationStartsWhen The boolean that decides when to start the animation
 * @param props.sendAnswer The method that sends the answer
 * @returns The SymptomSuggestionSelector component
 */
export const SymptomSuggestionSelector: FC<{
  question: Question
  animationStartsWhen: boolean
  sendAnswer: (val: ChatbotQuestionAnswer) => void
}> = ({ question, animationStartsWhen, sendAnswer }) => {
  const [symptomSuggestions, setSymptomSuggestions] = useState<OptionItem[]>(question.options?.values || [])
  const [error, setError] = useState<Error>()
  const [isSymptomSuggestionListLoading, setIsSymptomSuggestionListLoading] = useState<boolean>(false)

  const { i18n } = useI18N()

  const { apiGateway } = useApiGatewayContext()
  const { setNextButtonLogic, setNextButtonI18nKey, setHideSkipButton, isLoading } = useQuestionContext()

  const getNewSymptoms = useCallback(async () => {
    setIsSymptomSuggestionListLoading(true)

    try {
      const { data } = await apiGateway.get<Question>('/v1/chatbot/question')

      setSymptomSuggestions(data.options?.values || [])
    } catch (err) {
      setError(err as Error)
    } finally {
      setIsSymptomSuggestionListLoading(false)
    }
  }, [apiGateway, setIsSymptomSuggestionListLoading])

  /**
   * The method handling selection
   *
   * @param id The id to select
   * @param isDeselect Whether we are deselecting an option
   */
  const onSelected = useCallback(
    async (id: string, isDeselect = false) => {
      if (!isSymptomSuggestionListLoading) {
        setIsSymptomSuggestionListLoading(true)

        try {
          if (isDeselect) {
            await apiGateway.delete(`/v1/chatbot/answer`, { data: { questionId: question.id, answer: { id } } })
          } else {
            await apiGateway.post(`/v1/chatbot/answer`, { questionId: question.id, answer: { id } })
          }

          getNewSymptoms()
        } catch (err) {
          setError(err as Error)
        }
      }
    },
    [apiGateway, getNewSymptoms, isSymptomSuggestionListLoading, question.id, setIsSymptomSuggestionListLoading],
  )

  useEffect(() => {
    setSymptomSuggestions(question.options?.values || [])
  }, [question.options?.values])

  useEffect(() => {
    if (symptomSuggestions.some((item: OptionItem) => item.isSelected)) {
      setNextButtonLogic(() => () => {
        sendAnswer({ questionId: question.id })
        setNextButtonLogic(null)
      })
      setNextButtonI18nKey('general.confirm')
      setHideSkipButton(true)
    } else {
      setHideSkipButton(false)
      setNextButtonLogic(null)
    }
  }, [i18n, question.id, sendAnswer, setHideSkipButton, setNextButtonLogic, setNextButtonI18nKey, symptomSuggestions])

  if (error) {
    throw error
  }

  return (
    <div className={styles.container}>
      {!isLoading && (
        <WrapperWithTitleAndAnimation
          title={question.text}
          isAnimated
          animationStartsWhen={animationStartsWhen}
          style={{ height: 'fit-content' }}
          hasTopMargin={false}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              position: 'relative',
            }}
          >
            <SymptomItemList symptomSuggestions={symptomSuggestions} onSelected={onSelected} />
          </div>
        </WrapperWithTitleAndAnimation>
      )}
    </div>
  )
}
