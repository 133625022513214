import styled from '@emotion/styled'

// TODO migrate MainPage.module.less to styled components

export const PageContainer = styled.div<{ isServicesOpen: boolean }>(
  `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  `,
  (props) => props.isServicesOpen && `overflow: hidden`,
)
