import React, { FC } from 'react'
import { Col } from 'antd'
import { SectionTitle, StyledText } from './Report.styled'

interface Props {
  title: string
  value: string
}
export const ProfileDataBox: FC<Props> = (props: Props) => {
  const { title, value } = props
  return (
    <Col xxl={{ span: 4 }} xl={{ span: 6 }} lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }}>
      <div
        style={{
          display: 'flex',
          borderRadius: 15,
          backgroundColor: '#F9F9F9',
          padding: '17px 42px',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <StyledText>{title}</StyledText>
        <SectionTitle>{value}</SectionTitle>
      </div>
    </Col>
  )
}
