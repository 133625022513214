import styled from '@emotion/styled'

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 6px;
`

export const SliderContainer = styled.div`
  margin: 30px auto 30px;
  width: 100%;
  flex: calc(100% - 40px);
  padding: 0 15px;

  :global {
    .ant-slider-handle {
      width: 20px;
      height: 20px;
      inset-block-start: -4px;
    }

    .ant-slider-horizontal.ant-slider-with-marks {
      margin-bottom: 11px;
    }

    .ant-slider-handle::after {
      width: 20px;
      height: 20px;
      box-shadow: 0 2px 7px rgba(0, 0, 0, 0.15);
      transition: box-shadow 0.3s;
    }

    .ant-slider-handle:hover::after,
    .ant-slider-handle:focus::after {
      width: 20px;
      height: 20px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
      inset-block-start: 0;
      inset-inline-start: 0;
    }

    .ant-slider:hover .ant-slider-handle::after {
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    }
  }
`

export const PlusMinusButton = styled.button`
  width: 20px;
  height: 20px;
  flex: 20px;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s;
  background-color: white;
  border: none;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  padding: 0;
  cursor: pointer;

  :global {
    .anticon-plus,
    .anticon-minus {
      height: 20px;

      svg {
        width: 11px;
        fill: rgb(0, 101, 189);
      }
    }
  }
  :hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  }
`
