import styled from '@emotion/styled'

export const RiskLevelCircleContainer = styled.div`
  display: block;
  position: relative;
  padding: 0;
  width: 160px;
  height: 160px;
  border: 1px;
  border-radius: 50%;
  overflow: hidden;
`
export const RiskLevelCircleQuadrant = styled.div<{ color: string }>`
  display: inline-block;
  float: left;
  margin: 0;
  padding: 0;
  width: 80px;
  height: 80px;
  background-color: ${(props) => props.color};
`
export const RiskLevelCircleMiddle = styled.div`
  position: absolute;
  width: 130px;
  height: 130px;
  background-color: white;
  border-radius: 50%;
  top: 15px;
  left: 15px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
`

export const RiskLevelCircleMiddleTextContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
