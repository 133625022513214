import styled from '@emotion/styled'
import { Button } from 'antd'
import { buttonCommon } from '../Filters.common.styled'
import { CSS_VARS } from '../../../../../resources/cssVariableConfig'

export const StyledButton = styled(Button)`
  ${buttonCommon}

  box-shadow: none;
  border-radius: 13px !important;

  span {
    font-family: ${CSS_VARS.fontFamily};
  }
`

export const SelectedLocationContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
`
