import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { GrowingScaleAnswer } from 'health-check-api'
import { SLIDER_MAX, SLIDER_MIN } from '../../../../constants'
import { ScaleSlider } from '../ScaleSlider'
import { WrapperWithTitleAndAnimation } from '../../../../components/WrapperWithTitleAndAnimation'
import { CSS_VARS } from '../../../../resources/cssVariableConfig'
import { ButtonContainer, PlusMinusButton, SliderContainer } from './SliderInput.styled'

interface Props {
  scaleStep?: number
  answers: GrowingScaleAnswer[]
  onSelect: (id: string) => void
  title: string
  isLoading: boolean
}

export const SliderInput: FC<Props> = (props) => {
  const { scaleStep, answers, onSelect, title, isLoading } = props
  const stepSize = useMemo(() => scaleStep ?? 1, [scaleStep])

  const minimumValue = useMemo(() => {
    const minimumValues = answers.map((answer) => answer.params.from ?? SLIDER_MIN)
    return Math.min(...minimumValues)
  }, [answers])

  const maximumValue = useMemo(() => {
    const maximumValues = answers.map((answer) => answer.params.to ?? SLIDER_MAX)
    return Math.max(...maximumValues)
  }, [answers])

  const [value, setValue] = useState((minimumValue + maximumValue) / 2)

  const determineAnswerId = useCallback(() => {
    if (+value <= minimumValue) {
      return answers.find((option) => option.params.from === minimumValue)?.id
    }
    if (+value >= maximumValue) {
      return answers.find((option) => option.params.to === maximumValue)?.id
    }

    return answers.find(
      (option) =>
        option.params.from !== undefined &&
        option.params.to !== undefined &&
        option.params.from <= +value &&
        option.params.to > +value,
    )?.id
  }, [answers, minimumValue, maximumValue, value])

  useEffect(() => {
    const answer = determineAnswerId()
    if (answer) {
      onSelect(answer)
    }
  }, [determineAnswerId, onSelect])

  //Set default value to middle (rounded according to step size)
  useEffect(() => {
    const middleValue = (minimumValue + maximumValue) / 2
    setValue(Math.ceil(middleValue / stepSize) * stepSize)
  }, [minimumValue, maximumValue, stepSize])

  const increaseValue = () => {
    const newValue = Math.round((value + stepSize) * 10) / 10
    if (newValue <= maximumValue) {
      setValue(newValue)
    }
  }

  const decreaseValue = () => {
    const newValue = Math.round((value - stepSize) * 10) / 10
    if (newValue >= minimumValue) {
      setValue(newValue)
    }
  }

  return (
    <WrapperWithTitleAndAnimation title={title} isAnimated animationStartsWhen={!isLoading}>
      <ButtonContainer>
        <PlusMinusButton style={{ color: CSS_VARS.questionFontColor }} onClick={decreaseValue}>
          <MinusOutlined />
        </PlusMinusButton>
        <SliderContainer>
          <ScaleSlider
            stepSize={stepSize}
            value={+value}
            values={answers}
            setValue={setValue}
            minValue={minimumValue}
            maxValue={maximumValue}
          />
        </SliderContainer>
        <PlusMinusButton style={{ color: CSS_VARS.questionFontColor }} onClick={increaseValue}>
          <PlusOutlined />
        </PlusMinusButton>
      </ButtonContainer>
    </WrapperWithTitleAndAnimation>
  )
}
