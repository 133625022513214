import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { WebAppStyling, WebAppStylingFontStyle, WebAppStylingFontWeight } from '../models'
import { convertHexToRGBA } from '../utils/convertHexToRGBA'

interface WebAppStylingDict extends WebAppStyling {
  [key: string]: string | WebAppStylingFontStyle | WebAppStylingFontWeight | SizeType
}

const DIRECT_EXPOSE = [
  'fontCssUrl', // CSS_VARS ?
  'sideMenuBackgroundColor',
]
export const JS_STYLING = {
  // exported as JS_STYLING but not as CSS_VARS
  MOBILE_MAX_WIDTH: '576px',
  MOBILE_MAX_HEIGHT: '576px',
  MOBILE_LANDSCAPE_MAX_WIDTH: '915px',
  TABLET_STANDARD_MAX_WIDTH: '768px',
  TABLET_MAX_WIDTH: '820px',
  TABLET_LARGE_MAX_WIDTH: '1368px',
} as unknown as WebAppStylingDict

const initCssVariableConfig = (styling: WebAppStyling, code?: string) => {
  const config = document.documentElement.style

  for (const [key, value] of Object.entries(styling)) {
    config.setProperty(`--${key}`, value) // creating CSS variables on the <HTML> node

    if (DIRECT_EXPOSE.includes(key)) {
      JS_STYLING[key] = value // exposing some styling values directly too to allow access to them in JS code
    }
  }

  // exported as CSS_VARS but not as JS_STYLING
  // TODO migrate into PAP-config based CSS variables
  config.setProperty(`--PRIMARY_COLOR`, '#0065BD')
  config.setProperty(`--GREEN_COLOR`, '#66D096')
  config.setProperty(`--RED_COLOR`, '#FF6666')
  config.setProperty(`--DARK_GREY_COLOR`, '#808080')
  config.setProperty(`--DARK_BLUE_COLOR`, '#00417A')

  // if not XUND then PAP-config based value else hardcoded XUND color
  config.setProperty(`--startScreenHeaderTextColor`, code ? 'var(--chatFontColor)' : 'var(--DARK_BLUE_COLOR)')
  config.setProperty(`--darkTextColor`, code ? 'var(--chatFontColor)' : 'var(--DARK_GREY_COLOR)')

  config.setProperty(`--waveBgColor`, convertHexToRGBA(JS_STYLING.sideMenuBackgroundColor, 0.1))
  config.setProperty(`--lightPrimaryColor`, convertHexToRGBA(JS_STYLING.sideMenuBackgroundColor, 0.5))
}

// dictionary with possible CSS variable names
// TODO generate from code to get rid of manual maintain need
export const CSS_VARS = {
  navBarBackgroundColor: `var(--navBarBackgroundColor)`,
  navBarHamburgerIconColor: `var(--navBarHamburgerIconColor)`,

  sideMenuCloseIconColor: `var(--sideMenuCloseIconColor)`,
  sideMenuBackgroundColor: `var(--sideMenuBackgroundColor)`,
  sideMenuFontSize: `var(--sideMenuFontSize)`,
  sideMenuFontColor: `var(--sideMenuFontColor)`,
  sideMenuFontWeight: `var(--sideMenuFontWeight)`,
  sideMenuFontStyle: `var(--sideMenuFontStyle)`,

  chatBackgroundColor: `var(--chatBackgroundColor)`,

  chatFontColor: `var(--chatFontColor)`,
  chatFontSize: `var(--chatFontSize)`,
  chatFontWeight: `var(--chatFontWeight)`,
  chatFontStyle: `var(--chatFontStyle)`,

  questionFontColor: `var(--questionFontColor)`,
  questionFontSize: `var(--questionFontSize)`,
  questionFontWeight: `var(--questionFontWeight)`,
  questionFontStyle: `var(--questionFontStyle)`,

  outlinedButtonBackgroundColor: `var(--outlinedButtonBackgroundColor)`,
  outlinedButtonBorderColor: `var(--outlinedButtonBorderColor)`,
  outlinedButtonFontColor: `var(--outlinedButtonFontColor)`,
  outlinedButtonSize: `var(--outlinedButtonSize)`,
  outlinedButtonFontWeight: `var(--outlinedButtonFontWeight)`,

  filledButtonBackgroundColor: `var(--filledButtonBackgroundColor)`,
  filledButtonBorderColor: `var(--filledButtonBorderColor)`,
  filledButtonFontColor: `var(--filledButtonFontColor)`,
  filledButtonSize: `var(--filledButtonSize)`,
  filledButtonFontWeight: `var(--filledButtonFontWeight)`,

  skipButtonBackgroundColor: `var(--skipButtonBackgroundColor)`,
  skipButtonBorderColor: `var(--skipButtonBorderColor)`,
  skipButtonFontColor: `var(--skipButtonFontColor)`,
  skipButtonSize: `var(--skipButtonSize)`,
  skipButtonFontWeight: `var(--skipButtonFontWeight)`,

  confirmButtonBackgroundColor: `var(--confirmButtonBackgroundColor)`,
  confirmButtonBorderColor: `var(--confirmButtonBorderColor)`,
  confirmButtonFontColor: `var(--confirmButtonFontColor)`,
  confirmButtonSize: `var(--confirmButtonSize)`,
  confirmButtonFontWeight: `var(--confirmButtonFontWeight)`,

  deleteButtonBackgroundColor: `var(--deleteButtonBackgroundColor)`,
  deleteButtonBorderColor: `var(--deleteButtonBorderColor)`,
  deleteButtonFontColor: `var(--deleteButtonFontColor)`,
  deleteButtonSize: `var(--deleteButtonSize)`,
  deleteButtonFontWeight: `var(--deleteButtonFontWeight)`,

  modalTitleFontColor: `var(--modalTitleFontColor)`,
  modalTitleFontSize: `var(--modalTitleFontSize)`,
  modalTitleFontWeight: `var(--modalTitleFontWeight)`,
  modalTitleFontStyle: `var(--modalTitleFontStyle)`,

  modalFontColor: `var(--modalFontColor)`,
  modalFontSize: `var(--modalFontSize)`,
  modalFontWeight: `var(--modalFontWeight)`,
  modalFontStyle: `var(--modalFontStyle)`,

  linkColor: `var(--linkColor)`,
  statusBarColor: `var(--statusBarColor)`,
  inactiveElementColor: `var(--inactiveElementColor)`,

  sliderTrackColor: `var(--sliderTrackColor)`,
  sliderHandleColor: `var(--sliderHandleColor)`,

  abdominalZoomSVGBodyOutlineColor: `var(--abdominalZoomSVGBodyOutlineColor)`,
  abdominalZoomSVGColor: `var(--abdominalZoomSVGColor)`,

  bodyPartSelectorSVGBodyOutlineAndHoverColor: `var(--bodyPartSelectorSVGBodyOutlineAndHoverColor)`,
  bodyPartSelectorSVGColor: `var(--bodyPartSelectorSVGColor)`,

  reportProgressBackgroundColor: `var(--reportProgressBackgroundColor)`,

  fontFamily: `var(--fontFamily)`,

  PRIMARY_COLOR: `var(--PRIMARY_COLOR)`,
  LIGHT_PRIMARY_COLOR: `var(--LIGHT_PRIMARY_COLOR)`,
  GREEN_COLOR: `var(--GREEN_COLOR)`,
  RED_COLOR: `var(--RED_COLOR)`,
  DARK_GREY_COLOR: `var(--DARK_GREY_COLOR)`,
  DARK_BLUE_COLOR: `var(--DARK_BLUE_COLOR)`,

  startScreenHeaderTextColor: `var(--startScreenHeaderTextColor)`,
  darkTextColor: `var(--darkTextColor)`,

  waveBgColor: `var(--waveBgColor)`,
  lightPrimaryColor: `var(--lightPrimaryColor)`,
}

export default initCssVariableConfig
