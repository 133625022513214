import React, { FC, useMemo, useState } from 'react'
import { Col, Row } from 'antd'
import { useWindowSize } from 'usehooks-ts'
import { LeftOutlined } from '@ant-design/icons'
import { mobileMaxWidth, tabletMaxWidth, useI18N } from '../../../hooks'
import { StyledButton } from '../../../components/General/StyledButton/StyledButton'
import { RestartModal } from '../../../components/Modals/RestartModal/RestartModal'
import { IconButton } from '../../../components/General/IconButton/IconButton'
import { useQuestionContext } from '../context/QuestionContext'
import { useAppStateContext } from '../../../context'

export const Footer: FC<{
  onBackClick: () => void
}> = ({ onBackClick }) => {
  const { i18n } = useI18N()
  const { isLoading, footerButtonState } = useQuestionContext()
  const { isContentLibraryOpen } = useAppStateContext()
  const [isRestartModalVisible, setIsRestartModalVisible] = useState(false)

  const { width: windowWidth } = useWindowSize()

  const isMobile = useMemo(() => windowWidth <= mobileMaxWidth, [windowWidth])
  const isTabletOrSmaller = useMemo(() => windowWidth <= tabletMaxWidth, [windowWidth])

  const breakPoints = {
    col1: { xs: 24, sm: 8, md: 8 },
    col2: { xs: 24, sm: 8, md: 8 },
    col3: { xs: 24, sm: 10, md: 10 },
    col4: { xs: 24, sm: 6, md: 6 },
  }

  return (
    <div id="footer" style={{ width: '100%' }}>
      <Row
        style={{
          marginLeft: isTabletOrSmaller && isContentLibraryOpen && !isMobile ? 36 : 0,
          marginRight: isTabletOrSmaller && isContentLibraryOpen && !isMobile ? 36 : 0,
        }}
      >
        <Col {...breakPoints.col1} order={isMobile ? 4 : 0}>
          {isContentLibraryOpen && (
            <>
              {!isMobile && (
                <div style={{ display: 'flex', marginLeft: -15 }}>
                  <IconButton
                    testId="backButton"
                    icon={<LeftOutlined />}
                    onClick={onBackClick}
                    disabled={isLoading}
                    style={{ height: 30 }}
                  />
                </div>
              )}
              {isMobile && (
                <StyledButton
                  type="skip"
                  title={i18n('general.back')}
                  disabled={isLoading}
                  onClick={() => null}
                  style={{ height: 30, width: '100%' }}
                />
              )}
            </>
          )}
        </Col>
        {!isContentLibraryOpen && (
          <>
            <Col {...breakPoints.col2} order={isMobile ? 3 : 0} style={{ textAlign: 'center' }}>
              {!footerButtonState.isSkipButtonHidden && (
                <StyledButton
                  testId="skipButton"
                  title="Skip"
                  type="skip"
                  onClick={footerButtonState.onSkipButtonClick ?? (() => null)}
                />
              )}
            </Col>
            <Col {...breakPoints.col4} style={{ textAlign: 'end' }} order={isMobile ? 1 : 0}>
              {footerButtonState.onNextButtonClick && !footerButtonState.isNextButtonHidden && (
                <StyledButton
                  testId="nextButton"
                  type="confirm"
                  title={footerButtonState.nextButtonTitle}
                  onClick={footerButtonState.onNextButtonClick}
                  disabled={footerButtonState.isNextButtonDisabled}
                  style={{ height: 30, marginBottom: isMobile ? 10 : 'unset' }}
                />
              )}
            </Col>
          </>
        )}
      </Row>
      <div />
      {isRestartModalVisible && (
        <RestartModal onConfirm={() => window.appLocation.reload()} onCancel={() => setIsRestartModalVisible(false)} />
      )}
    </div>
  )
}
