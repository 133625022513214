import styled from '@emotion/styled'
import { Input, Row } from 'antd'
import { CSS_VARS } from '../../../../resources/cssVariableConfig'

export const Label = styled.p`
  font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Avenir Next', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: ${CSS_VARS.DARK_GREY_COLOR};
`

export const NumberInput = styled(Input)`
  width: 100%;
  border-radius: 5px;
  border-width: 0.5px;
  font-size: 14px;
  font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Avenir Next', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
`

export const StyledRow = styled(Row)`
  margin-bottom: 16px;
`
