import { Question, QUESTION_STATE_PROFILE, QUESTION_TYPE_GENDER } from 'health-check-api'

export const mockGenderSelectQuestion: Question = {
  id: 'genderId',
  text: 'What is your biological sex?',
  type: QUESTION_TYPE_GENDER,
  state: QUESTION_STATE_PROFILE,
  answers: [],
  params: null,
}
