import React, { FC, Fragment, useMemo } from 'react'
import { Steps } from 'antd'
import { useWindowSize } from 'usehooks-ts'
import { mobileMaxWidth } from '../../../../hooks'
import { Flex } from '../Flex'
import { CSS_VARS } from '../../../../resources/cssVariableConfig'
import { useQuestionContext } from '../../context/QuestionContext'
import { getTempTranslation } from '../../utils/getTempTranslation'
import { CheckIcon, MobileStep, MobileStepContainer, ProgressLine, StatusBarCol } from './StatusBar.styled'

const lastStep = 5

export const StatusBar: FC = () => {
  const { width } = useWindowSize()
  const { statusStep } = useQuestionContext()

  const isMobile = useMemo(() => width <= mobileMaxWidth, [width])

  return (
    <StatusBarCol lg={{ span: 19 }} md={{ span: 19 }} sm={{ span: 24 }} xs={{ span: 24 }}>
      {!isMobile && (
        <Steps
          current={statusStep}
          size="default"
          labelPlacement="vertical"
          responsive={false}
          items={[
            { title: getTempTranslation('healthCheck.status.start') },
            { title: getTempTranslation('healthCheck.status.profile') },
            { title: getTempTranslation('healthCheck.status.riskTopics') },
            { title: getTempTranslation('healthCheck.status.healthData') },
            { title: getTempTranslation('healthCheck.status.report') },
          ]}
        />
      )}
      {isMobile && (
        <MobileStepContainer>
          {[...Array(5)].map((entry, index) => (
            <Fragment key={`status-bar--item-${index}`}>
              <MobileStep
                backgroundColor={
                  index !== lastStep && index < statusStep ? (CSS_VARS.PRIMARY_COLOR as string) : '#ffffff'
                }
                borderColor={
                  index <= statusStep || index === statusStep ? (CSS_VARS.PRIMARY_COLOR as string) : '#BFBFBF'
                }
              >
                {index <= statusStep && (
                  <Flex>
                    <CheckIcon />
                  </Flex>
                )}
              </MobileStep>
              {index !== 4 && (
                <ProgressLine borderColor={index <= statusStep ? (CSS_VARS.PRIMARY_COLOR as string) : '#BFBFBF'} />
              )}
            </Fragment>
          ))}
        </MobileStepContainer>
      )}
    </StatusBarCol>
  )
}
