import React, { FC } from 'react'
import { Modal, Typography } from 'antd'
import { StyledButton } from '../../../../General/StyledButton/StyledButton'
import { Service } from '../../../../../models'
import { useI18N } from '../../../../../hooks'

/**
 * @param props The props object
 * @param props.service The current service
 * @param props.isDetailsModalOpen Whether the details modal is visible
 * @param props.setIsDetailsModalOpen The setter for whether the details modal is visible
 * @returns The ServiceDetailsModal component
 */
export const ServiceDetailsModal: FC<{
  service: Service
  isDetailsModalOpen: boolean
  setIsDetailsModalOpen: (val: boolean) => void
}> = ({ service, isDetailsModalOpen, setIsDetailsModalOpen }) => {
  const { i18n } = useI18N()

  return (
    <Modal
      open={isDetailsModalOpen}
      title={service.name}
      onCancel={() => setIsDetailsModalOpen(false)}
      cancelButtonProps={{ style: { display: 'none' } }}
      footer={<StyledButton title={i18n('general.done')} type="filled" onClick={() => setIsDetailsModalOpen(false)} />}
    >
      <Typography style={{ fontSize: 16 }}>
        <strong>{i18n('general.phoneNumber')}: </strong>
        {service.phone}
      </Typography>

      <Typography style={{ fontSize: 16 }}>
        <strong>{i18n('general.email')}: </strong>
        {service.email}
      </Typography>
    </Modal>
  )
}
