import styled from '@emotion/styled'
import { Row } from 'antd'
import { specializationArticleCommon, specializationCommon } from '../SpecializationsFilterDetailedView.styled'
import { CSS_VARS } from '../../../../../../../resources/cssVariableConfig'

export const SpecializationCategory = styled.div`
  ${specializationCommon}

  article {
    ${specializationArticleCommon}

    div span {
      font-family: ${CSS_VARS.fontFamily};
    }
  }
`

export const CategoryRow = styled(Row)`
  width: 100%;
`

export const CategoryIcon = styled.span`
  background: white;
  border-radius: 50%;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`

export const Category = styled.span<{ selected: boolean }>(
  `
    display: flex;
  `,
  (props) => (props.selected ? { fontWeight: 'bold' } : {}),
)
