import axios from 'axios'
import { useEffect, useMemo, useState } from 'react'
import { useLanguage } from './useLanguage'

/**
 * The API Gateway axios instance factory
 *
 * @param token The token to be used for the API Gateway
 * @returns The axios instance to be used for the API Gateway
 */
export const useApiGateway = (token: string | null) => {
  const [checkId, setCheckId] = useState('')
  const [initialSymptom, setInitialSymptom] = useState('')
  const [initialIllness, setInitialIllness] = useState('')

  const { currentLanguage } = useLanguage()

  /**
   * @returns An axios instance
   */
  const apiGateway = useMemo(() => {
    return axios.create({
      baseURL: window.xundEnvironment.API_GATEWAY_BASE_URL,
      timeout: 60000,
    })
  }, [])

  useEffect(() => {
    const interceptorId = apiGateway.interceptors.request.use(
      async (config) => {
        config.headers.setAuthorization(`Bearer ${token}`)
        config.headers.set('language', currentLanguage)
        config.headers.set('check-id', checkId)
        return config
      },
      (error) => {
        return Promise.reject(error)
      },
    )
    return () => {
      apiGateway.interceptors.request.eject(interceptorId)
    }
  }, [apiGateway.interceptors.request, token, checkId, currentLanguage])

  return { apiGateway, checkId, setCheckId, initialSymptom, setInitialSymptom, initialIllness, setInitialIllness }
}
