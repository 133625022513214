import React, { CSSProperties, FC } from 'react'
import { Input } from 'antd'
import debounce from 'lodash.debounce'
import { AutocompleteResult } from '../../../../models'
import { useI18N } from '../../../../hooks'
import { DEFAULT_SEARCH_DEBOUNCE_TIME_IN_MS } from '../../../../constants'
import { CustomIcon } from '../../../CustomIcon/CustomIcon'
import { AutocompleteInput } from './AutocompleteInput/AutocompleteInput'

/**
 * @param props The props object
 * @param props.value The debounced search value
 * @param props.setSearchText The setter for the searchText
 * @param props.isSpecializationsDetailedViewVisible Whether the specializations detailed view is visible
 * @param props.setSpecializationsSearchText The setter for the search text for specializations
 * @param props.isAutocomplete Whether autocomplete is needed
 * @param props.setAutocompleteResults The setter for autocomplete results
 * @param props.style The optional stylings
 * @returns The SearchBar component
 */
export const ServicesMenuSearchBar: FC<{
  value: string
  isAutocomplete: boolean
  isSpecializationsDetailedViewVisible: boolean
  style?: CSSProperties
  setSearchText: (val: string) => void
  setSpecializationsSearchText: (val: string) => void
  setAutocompleteResults: (val: AutocompleteResult[]) => void
}> = ({
  value,
  isAutocomplete,
  isSpecializationsDetailedViewVisible,
  style,
  setSearchText,
  setSpecializationsSearchText,
  setAutocompleteResults,
}) => {
  const { i18n } = useI18N()

  const onChange = debounce((e) => {
    if (isSpecializationsDetailedViewVisible) {
      setSpecializationsSearchText(e.target.value)
    } else {
      setSearchText(e.target.value)
    }
  }, DEFAULT_SEARCH_DEBOUNCE_TIME_IN_MS)

  return isAutocomplete ? (
    <AutocompleteInput onChange={(res) => setAutocompleteResults(res)} style={style} />
  ) : (
    <Input
      placeholder={i18n('general.search')}
      type="search"
      defaultValue={value}
      style={style}
      onChange={onChange}
      suffix={<CustomIcon icon="zoom" />}
    />
  )
}
