import { Button } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import React, { CSSProperties, FC, useMemo } from 'react'
import { useWindowSize } from 'usehooks-ts'
import { mobileMaxWidth } from '../../../hooks'
import { CSS_VARS } from '../../../resources/cssVariableConfig'

type ButtonType = 'outlined' | 'filled' | 'confirm' | 'delete' | 'skip'

/**
 * @param props The props object
 * @param props.title The title to use
 * @param props.type The type of the button
 * @param props.size The size of the button
 * @param props.style The styles to use
 * @param props.classNames The class names to add
 * @param props.disabled Whether the button is disabled
 * @param props.onClick The method handling clicks
 * @param props.testId Optional id used as selector in tests
 * @returns The OutlinedButton component
 */
export const StyledButton: FC<{
  testId?: string
  title: string
  type: ButtonType
  size?: SizeType
  style?: CSSProperties
  classNames?: string[]
  disabled?: boolean
  onClick?: () => void
}> = ({ testId, title, type, size, style, classNames, disabled, onClick }) => {
  const { width: windowWidth } = useWindowSize()

  const isMobile = useMemo(() => windowWidth <= mobileMaxWidth, [windowWidth])

  const buttonStyles = useMemo(
    () => ({
      color: CSS_VARS[`${type}ButtonFontColor`],
      backgroundColor: CSS_VARS[`${type}ButtonBackgroundColor`],
      borderColor: CSS_VARS[`${type}ButtonBorderColor`],
      fontWeight: CSS_VARS[`${type}ButtonFontWeight`],
      fontSize: CSS_VARS.chatFontSize,
    }),
    [type],
  )

  const buttonSize = useMemo(() => {
    if (type === 'outlined') {
      return CSS_VARS.outlinedButtonSize
    }
    if (type === 'filled') {
      return CSS_VARS.filledButtonSize
    }
    if (type === 'confirm') {
      return CSS_VARS.confirmButtonSize
    }
    if (type === 'delete') {
      return CSS_VARS.deleteButtonSize
    }
    if (type === 'skip') {
      return CSS_VARS.skipButtonSize
    }
    return 'middle'
  }, [type])

  const buttonType = useMemo(() => {
    if (type === 'outlined' || type === 'skip') {
      return 'default'
    }
    if (type === 'filled' || type === 'confirm' || type === 'delete') {
      return 'primary'
    }
    return 'default'
  }, [type])

  return (
    <Button
      data-testid={testId}
      type={buttonType}
      size={size || (buttonSize as SizeType)}
      style={{
        ...buttonStyles,
        ...style,
        borderWidth: 0.5,
        borderRadius: 17,
        ...(isMobile ? { height: 'auto', wordBreak: 'break-word', whiteSpace: 'normal' } : {}),
      }}
      onClick={onClick}
      disabled={disabled}
      className={classNames ? classNames.join(' ') : undefined}
    >
      {title}
    </Button>
  )
}
