import React, { FC, useMemo } from 'react'
import { getTempTranslation } from '../../../utils/getTempTranslation'
import { SectionTitle, StyledText } from '../Report.styled'
import DroppyKeepGoing from '../../../../../assets/images/droppy-keep_going.svg'
import DroppyDontGiveUp from '../../../../../assets/images/droppy-dont_give_up.svg'
import { DroppyImage, EmptyStateContainer } from './EmptyState.styled'

interface Props {
  type: 'protective' | 'negative'
}

export const EmptyState: FC<Props> = (props) => {
  const { type } = props

  const [title, subTitle, image] = useMemo(() => {
    if (type === 'negative') {
      return [
        getTempTranslation('healthCheck.report.negativeRiskFactor.emptyState.title'),
        getTempTranslation('healthCheck.report.negativeRiskFactor.emptyState.subTitle'),
        DroppyKeepGoing,
      ]
    }
    if (type === 'protective') {
      return [
        getTempTranslation('healthCheck.report.protectiveRiskFactor.emptyState.title'),
        getTempTranslation('healthCheck.report.protectiveRiskFactor.emptyState.subTitle'),
        DroppyDontGiveUp,
      ]
    }
    return []
  }, [type])

  return (
    <EmptyStateContainer>
      <SectionTitle>{title}</SectionTitle>
      <StyledText>{subTitle}</StyledText>
      <DroppyImage
        src={image}
        alt={
          type === 'protective'
            ? getTempTranslation('healthCheck.report.protectiveRiskFactor.emptyState.title')
            : getTempTranslation('healthCheck.report.negativeRiskFactor.emptyState.title')
        }
      />
    </EmptyStateContainer>
  )
}
