import React, { FC, useMemo, useState } from 'react'
import { UnifiedMedicalItem } from 'medical-engine-api'
import { useI18N, useBreakpoints } from '../../../hooks'
import { CSS_VARS } from '../../../resources/cssVariableConfig'
import { Link, ListItem } from './ContentLibraryListItem.styled'

/**
 * @param props The props object
 * @param props.item The current list item
 * @param props.onSelect The method handling selection (unused if openableInPlace is true)
 * @param props.elementReference The reference object
 * @param props.lastItem Indicates if this item is the last in the list
 * @param props.openableInPlace Indicates if the description is openable in place
 * @returns The ContentLibraryListItem component
 */
export const ContentLibraryListItem: FC<{
  item: UnifiedMedicalItem
  onSelect?: (val: string) => void
  elementReference?: (node: HTMLDivElement | null) => void
  lastItem?: boolean
  openableInPlace?: boolean
}> = ({ item, onSelect, elementReference, lastItem = false, openableInPlace = false }) => {
  const { i18n } = useI18N()

  const { isScreenSizeXS } = useBreakpoints()
  const computedIllnessListItemDescriptionLength = useMemo(() => (isScreenSizeXS ? 60 : 200), [isScreenSizeXS])

  const [isOpened, setOpened] = useState(false)

  return (
    <>
      <ListItem ref={elementReference} style={{ fontFamily: CSS_VARS.fontFamily }} isLast={lastItem}>
        <h3 style={{ fontSize: CSS_VARS.modalFontSize }}>{item.name}</h3>
        {item.type === 'ILLNESS' ? (
          <p style={{ fontSize: CSS_VARS.modalFontSize }}>
            {!openableInPlace && onSelect ? (
              <>
                {item.description.slice(0, computedIllnessListItemDescriptionLength)} ...{' '}
                <Link onClick={() => onSelect(item.id)}>{i18n('general.showMore')}</Link>
              </>
            ) : (
              <>
                {isOpened
                  ? `${item.description} `
                  : `${item.description.slice(0, computedIllnessListItemDescriptionLength)}... `}
                <Link onClick={() => setOpened(!isOpened)}>
                  {isOpened ? i18n('general.showLess') : i18n('general.showMore')}
                </Link>
              </>
            )}
          </p>
        ) : (
          <p style={{ fontSize: CSS_VARS.modalFontSize }}>{item.detailedDescription}</p>
        )}
      </ListItem>
    </>
  )
}
