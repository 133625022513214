import React, { FC, useState } from 'react'
import DefaultXUNDNavbarLogo from '../../assets/images/xund_logo.svg'
import { useWebAppConfigContext } from '../../context'
import { ImageWithFallback } from '../General/ImageWithFallback/ImageWithFallback'
import { HomePageModal } from '../Modals/HomePageModal/HomePageModal'
import { CSS_VARS } from '../../resources/cssVariableConfig'
import { SideMenuToggle } from './SideMenuToggle/SideMenuToggle'
import styles from './Header.module.less'

/**
 * @param props The props object
 * @param props.onHomeClick A method handling the click action on the logo
 * @returns The navbar with the side menu
 */
export const Header: FC<{
  onHomeClick: (onHomePageModalOpen: () => void) => void
}> = ({ onHomeClick }) => {
  const { webAppConfig } = useWebAppConfigContext()
  const [isHomePageModalOpen, setIsHomePageModalOpen] = useState(false)

  return (
    <>
      {isHomePageModalOpen && (
        <HomePageModal onConfirm={() => window.appLocation.reload()} onCancel={() => setIsHomePageModalOpen(false)} />
      )}

      <header id="header" style={{ background: CSS_VARS.navBarBackgroundColor }} className={styles.Header}>
        <div className={styles.Logo}>
          <ImageWithFallback
            imageToDisplay={webAppConfig.navBarLogo ?? DefaultXUNDNavbarLogo}
            fallbackImage={DefaultXUNDNavbarLogo}
            onClick={() => onHomeClick(() => setIsHomePageModalOpen(true))}
          />
        </div>
        <SideMenuToggle />
      </header>
    </>
  )
}
