import { createContext, useContext } from 'react'
import { Translation } from '../models/Translation'

const initialValue: {
  translations: Translation[] | null
  isLocalizationsLoading: boolean
  localizationsError: Error | null
} = {
  translations: null,
  isLocalizationsLoading: false,
  localizationsError: null,
}

export const LocalizationContext = createContext(initialValue)

/**
 * The custom hook for the Localization context
 *
 * @returns The custom hook for the Localization context
 */
export const useLocalizationContext = () => useContext(LocalizationContext)
