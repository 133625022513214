import React, { FC, useCallback, useState } from 'react'
import { Select } from '@xund-packages/xund-ui'
import { getLanguageNameByCode } from 'xund-i18n'
import { useLanguage, useI18N } from '../../../hooks'
import { SupportedLanguage } from '../../../models'
import { ModalText } from '../ModalText/ModalText'
import { BaseModal } from '../BaseModal/BaseModal'

/**
 * @param props The props object
 * @param props.onCancel The method handling cancellation
 * @returns The LanguageSelectionModal component
 */
export const LanguageSelectionModal: FC<{
  onCancel: () => void
}> = ({ onCancel }) => {
  const { i18n } = useI18N()
  const { currentLanguage, changeLanguage, supportedLanguages } = useLanguage()
  const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage)

  /**
   * The method handling language change
   *
   * @param language The language to change to
   */
  const handleLanguage = useCallback(
    (language: SupportedLanguage) => {
      changeLanguage(language)
      setSelectedLanguage(language)
      window.appLocation.reload()
    },
    [changeLanguage],
  )

  return (
    <BaseModal
      title={i18n('modal.languageSelection.title')}
      onCancel={onCancel}
      onOk={() => handleLanguage(selectedLanguage)}
      okBtnTitle={i18n('general.restart')}
      okBtnDisabled={selectedLanguage === currentLanguage}
      cancelBtnTitle={i18n('general.cancel')}
    >
      <Select
        placeholder={i18n('modal.languageSelection.title')}
        items={supportedLanguages.map((language) => {
          return { id: language }
        })}
        itemToString={(item) => getLanguageNameByCode(item.id)}
        onChange={(item) => {
          if (item !== null) {
            setSelectedLanguage(item.id)
          }
        }}
      ></Select>
      <ModalText title={i18n('general.restartWarning')} style={{ marginTop: '5px', textAlign: 'right' }} />
    </BaseModal>
  )
}
