import React, { FC } from 'react'
import { MapProvider } from 'react-map-gl'
import { StopOutlined } from '@ant-design/icons'

import { ServicesContext, useServicesContext } from '../../context'
import { useI18N, useScreenSize, useServices } from '../../hooks'
import { ServicesMap } from './ServicesMap/ServicesMap'
import { ServicesMenu } from './ServicesMenu/ServicesMenu'
import { ServicesMenuMobile } from './ServicesMenuMobile/ServicesMenuMobile'
import { MainContainer, InnerContainer, Alert, InnerContainerMobile } from './Services.styled'

/**
 * @returns The Services component
 */
export const Services: FC = () => {
  const { i18n } = useI18N()
  const { isMobileView } = useScreenSize()
  const { isSupportedCountry } = useServicesContext()
  const services = useServices()

  return (
    <ServicesContext.Provider value={services}>
      {isMobileView ? (
        <InnerContainerMobile>
          <MapProvider>
            <ServicesMenuMobile />

            <ServicesMap />

            {!isSupportedCountry && (
              <Alert>
                <StopOutlined /> {i18n('services.unsupportedRegion')}
              </Alert>
            )}
          </MapProvider>
        </InnerContainerMobile>
      ) : (
        <MainContainer>
          <InnerContainer>
            <MapProvider>
              <ServicesMenu />

              <ServicesMap />

              {!isSupportedCountry && (
                <Alert>
                  <StopOutlined /> {i18n('services.unsupportedRegion')}
                </Alert>
              )}
            </MapProvider>
          </InnerContainer>
        </MainContainer>
      )}
    </ServicesContext.Provider>
  )
}
