import { AxiosInstance } from 'axios'
import { SupportedCountryCode } from '../../../models'
import { VirtualUrlService } from '../../../models/virtualServices'

type CountryCodeFilter = { countryCode?: SupportedCountryCode }
type GeolocationFilter = { location?: { latitude: number; longitude: number } }
type LocalSpecializationFilter = { specializations: string[] }

export type FetchVirtualUrlServices = {
  (o: CountryCodeFilter & LocalSpecializationFilter): Promise<VirtualUrlService[]>
  (o: GeolocationFilter & LocalSpecializationFilter): Promise<VirtualUrlService[]>
}

/**
 * @param apiGateway - the api gateway axios instance
 * @returns function fetching virtual url services
 */
export const fetchVirtualUrlServices =
  (apiGateway: AxiosInstance): FetchVirtualUrlServices =>
  async (options) =>
    (await apiGateway.post(`v1/services/virtual`, { ...options, types: ['URL'] })).data.items
