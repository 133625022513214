import styled from '@emotion/styled'

export const Flex = styled.div<{
  justifyContent?: 'start' | 'center' | 'space-between' | 'space-around' | 'space-evenly'
  alignItems?: 'start' | 'center' | 'end' | 'stretch'
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
}>`
  display: flex;
  justify-content: ${(props) => props.justifyContent ?? 'start'};
  align-items: ${(props) => props.alignItems ?? 'start'};
  flex-direction: ${(props) => props.flexDirection ?? 'row'};
`
