// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.89.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.7.3_webpack@5.89.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gx7xhSfiCcuo2dhiJGzP {\n  max-height: 70px;\n  width: 100%;\n  position: relative;\n  top: 0;\n  left: 0;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 25px;\n  box-sizing: border-box;\n  z-index: 999;\n  box-shadow: 0 0 35px 0 rgba(0, 65, 122, 0.07);\n  transform: translateY(0);\n  transition: transform 0.3s ease-in;\n}\n.K6IzGq0uBq75YRryLOVA {\n  height: 22px;\n  margin-left: 60px;\n  cursor: pointer;\n}\n.K6IzGq0uBq75YRryLOVA img {\n  max-height: 100%;\n  width: auto;\n  height: 22px;\n}\n@media screen and (orientation: portrait) and (max-width: 576px) {\n  .K6IzGq0uBq75YRryLOVA {\n    margin-left: 30px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/Header/Header.module.less"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,WAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;EACA,6CAAA;EACA,wBAAA;EACA,kCAAA;AADF;AAIA;EACE,YAAA;EACA,iBAAA;EACA,eAAA;AAFF;AAKA;EACE,gBAAA;EACA,WAAA;EACA,YAAA;AAHF;AAMA;EACE;IACE,iBAAA;EAJF;AACF","sourcesContent":["@import '../../variables.less';\n\n.Header {\n  max-height: 70px;\n  width: 100%;\n  position: relative;\n  top: 0;\n  left: 0;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 25px;\n  box-sizing: border-box;\n  z-index: 999;\n  box-shadow: 0 0 35px 0 rgba(0, 65, 122, 0.07);\n  transform: translateY(0);\n  transition: transform 0.3s ease-in;\n}\n\n.Logo {\n  height: 22px;\n  margin-left: 60px;\n  cursor: pointer;\n}\n\n.Logo img {\n  max-height: 100%;\n  width: auto;\n  height: 22px;\n}\n\n@media screen and (orientation: portrait) and (max-width: @mobile-max-width) {\n  .Logo {\n    margin-left: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Header": "gx7xhSfiCcuo2dhiJGzP",
	"Logo": "K6IzGq0uBq75YRryLOVA"
};
export default ___CSS_LOADER_EXPORT___;
