import React, { FC } from 'react'
import { useI18N } from '../../../../hooks'
import { WrapperWithTitleAndAnimation } from '../../../../components/WrapperWithTitleAndAnimation'
import { StyledButton } from '../../../../components/General/StyledButton/StyledButton'
import { CSS_VARS } from '../../../../resources/cssVariableConfig'
import { ButtonContainer, Article } from './LegalDisclaimer.styled'

interface Props {
  onStartCheck: () => void
  onCancelCheck: () => void
  termsAndConditionsUrl?: string
  privacyPolicyUrl?: string
}

export const LegalDisclaimer: FC<Props> = (props) => {
  const { onStartCheck, onCancelCheck, termsAndConditionsUrl, privacyPolicyUrl } = props
  const { i18n } = useI18N()

  return (
    <WrapperWithTitleAndAnimation title={i18n('general.generalTerms')} isAnimated animationStartsWhen={true}>
      <Article>
        <p>By accessing and using this tool, you acknowledge that you have read, understood, and agree to the</p>
        <p>
          <a
            key="termsAndConditionsUrl"
            style={{ color: CSS_VARS.linkColor }}
            rel="noopener noreferrer"
            href={termsAndConditionsUrl}
            target="_blank"
          >
            {i18n('general.generalTerms')}
          </a>{' '}
          and{' '}
          <a
            key="privacyPolicyUrl"
            style={{ color: CSS_VARS.linkColor }}
            rel="noopener noreferrer"
            href={privacyPolicyUrl}
            target="_blank"
          >
            {i18n('termsAndConditions.dataPrivacy')}
          </a>
        </p>
        <p>The message and the URLs displayed on this page will be customizable by XUND’s partners.</p>
      </Article>

      <ButtonContainer>
        <StyledButton type="outlined" title={i18n('general.accept')} onClick={onStartCheck} />
        <StyledButton
          type="outlined"
          title={i18n('general.doNotAccept')}
          style={{ marginTop: 10 }}
          onClick={onCancelCheck}
        />
      </ButtonContainer>
    </WrapperWithTitleAndAnimation>
  )
}
