import styled from '@emotion/styled'

// TODO migrate QuickSolutions.module.less to styled components

export const TransparencyRemoverContainer = styled.div`
  svg {
    path {
      fill-opacity: 1;
    }
  }
`
