import React, { FC, useEffect, useMemo, useState } from 'react'
import { Col, Divider, Row } from 'antd'
import { Report as ReportResponse, RiskTopic } from 'health-check-api'
import { WrapperWithTitleAndAnimation } from '../../../../components/WrapperWithTitleAndAnimation'
import { useQuestionContext as useMainPageQuestionContext } from '../../../../context/QuestionContext'
import { BaseModal } from '../../../../components/Modals/BaseModal/BaseModal'
import { ComingSoonLine, ReportContainer, SectionTitle } from './Report.styled'
import { ExaminationSection } from './ExaminationSection'
import { ScoreSection } from './ScoreSection'
import { ProfileSection } from './ProfileSection'
import { ProtectiveRiskFactorSection } from './ProtectiveRiskFactorSection'
import { RecommendationSection } from './RecommendationSection'
import { DisclaimerSection } from './DisclaimerSection'

export const NOT_STARTED_RISK_COLOR = '#F2F2F2'
export const GOOD_RISK_COLOR = '#66D096'
export const LOW_RISK_COLOR = '#00ADEF'
export const MEDIUM_RISK_COLOR = '#FF9248'
export const HIGH_RISK_COLOR = '#FF6666'
export const COMPLETED_TOPIC_COLOR = '#EBF3FA'
export const LABEL_COLOR = '#808080'
export const SELECTED_LABEL_COLOR = '#00417A'

export const REPORT_COLORS = [
  NOT_STARTED_RISK_COLOR,
  GOOD_RISK_COLOR,
  LOW_RISK_COLOR,
  MEDIUM_RISK_COLOR,
  HIGH_RISK_COLOR,
]

interface Props {
  isLoading: boolean
  report: ReportResponse
}

export const Report: FC<Props> = (props) => {
  const { isLoading, report } = props
  const { setNextButtonLogic, nextButtonLogic } = useMainPageQuestionContext()
  const [isExaminationModalOpen, setExaminationModalOpen] = useState<boolean>(false)

  const selectedRiskTopic: RiskTopic = useMemo(() => report.riskTopics[0], [report])

  useEffect(() => {
    if (nextButtonLogic) {
      setNextButtonLogic(null)
    }
  }, [nextButtonLogic, setNextButtonLogic])

  return (
    <WrapperWithTitleAndAnimation title={selectedRiskTopic?.name} isAnimated animationStartsWhen={!isLoading}>
      <ReportContainer>
        <ScoreSection riskTopic={selectedRiskTopic} />
        <ProfileSection report={report} />
        <ExaminationSection onBookingButtonClick={() => setExaminationModalOpen(true)} />
        <ProtectiveRiskFactorSection protectiveRiskFactors={report.recommendation.protective} />
        <RecommendationSection negativeRiskFactors={report.recommendation.negative} />
        <Divider style={{ marginTop: 80 }} />
        <DisclaimerSection />
      </ReportContainer>
      {isExaminationModalOpen && (
        <BaseModal
          hasButtons={false}
          onCancel={() => setExaminationModalOpen(false)}
          title={<SectionTitle>Book your appointment - Coming Soon!</SectionTitle>}
        >
          <Row>
            <Col span={24}>
              <ComingSoonLine>The appointment booking function is currently not available.</ComingSoonLine>
              <ComingSoonLine>We are working on adding more new features.</ComingSoonLine>
              <ComingSoonLine>Stay tuned.</ComingSoonLine>
            </Col>
          </Row>
        </BaseModal>
      )}
    </WrapperWithTitleAndAnimation>
  )
}
