import { BusinessAdminApiKeyScope } from 'xund-auth'
import { useWebAppConfigContext } from '../context'

export const useApiKeysScopes = () => {
  const {
    webAppConfig: { urlCode, apiKey },
  } = useWebAppConfigContext()

  return {
    /**
     * Check if current API key has a given scope.
     * If no webapp code is used, the returned value will always be true.
     * This means that partners not using a code will possibly receive UNAUTHORIZED errors upon backend scope check in API Gateway.
     *
     * @param scope The required scope
     * @returns boolean
     */
    hasApiKeyScope: (scope: BusinessAdminApiKeyScope): boolean => !urlCode?.length || apiKey.scopes.includes(scope),
  }
}
