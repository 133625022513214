import React, { FC, useCallback, useMemo, useState } from 'react'
import { MedicalEngineSymptomCheckReport, MedicalEngineIllnessCheckReport } from 'medical-engine-api'
import { LeftOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { useWindowSize } from 'usehooks-ts'
import {
  ResponseType,
  OverviewResponse,
  Question,
  ChatbotQuestionAnswer,
  ResponseTypeWithoutReport,
  TranslationCodeLiteral,
} from '../../models'
import { IconButton } from '../General/IconButton/IconButton'
import { StyledButton } from '../General/StyledButton/StyledButton'
import { mobileMaxWidth, tabletMaxWidth, useI18N } from '../../hooks'
import { useQuestionContext } from '../../context/QuestionContext'
import { RestartModal } from '../Modals/RestartModal/RestartModal'
import { useApiGatewayContext, useAppStateContext, useWebAppConfigContext } from '../../context'
import styles from './Footer.module.less'

const secondToLastQuestionIndex = 2

/**
 * @param props The props object
 * @param props.chatbotQuestionData The current question data
 * @param props.showReportActions The boolean that decides whether to show report actions
 * @param props.isCheckFinished The boolean that decides whether the check is finished
 * @param props.onBackClick The method handling back button clicks
 * @param props.onSendAnswer The method for sending answers
 * @param props.onRestartCheck A method handling the restart of the current check
 * @returns The footer component
 */
export const Footer: FC<{
  chatbotQuestionData: ResponseType[]
  showReportActions: boolean
  isCheckFinished: boolean
  onBackClick: () => void
  onSendAnswer: (answer: ChatbotQuestionAnswer, isInfoQuestion?: boolean) => void
  onRestartCheck: () => void
}> = ({ chatbotQuestionData, showReportActions, isCheckFinished, onBackClick, onSendAnswer, onRestartCheck }) => {
  const { i18n } = useI18N()
  const {
    hideSkipButton,
    currentResponse,
    setIsReporting,
    nextButtonI18nKey,
    nextButtonLogic,
    isLoading,
    isNextButtonDisabled,
    isReporting,
    setCurrentResponse,
  } = useQuestionContext()
  const [isRestartModalVisible, setIsRestartModalVisible] = useState(false)
  const { apiGateway } = useApiGatewayContext()
  const {
    webAppConfig: { illnessCheckIcd10Code: isDirectIllnessCheck },
  } = useWebAppConfigContext()

  const { isContentLibraryOpen } = useAppStateContext()

  const { width: windowWidth } = useWindowSize()

  const isSoteMode = window.xundEnvironment.IS_SOTE_MODE === 'true'
  const isMobile = useMemo(() => windowWidth <= mobileMaxWidth, [windowWidth])
  const isTabletOrSmaller = useMemo(() => windowWidth <= tabletMaxWidth, [windowWidth])

  const isPreviousQuestionAReport = useMemo(
    () =>
      !!(
        chatbotQuestionData[chatbotQuestionData.length - secondToLastQuestionIndex] as
          | MedicalEngineSymptomCheckReport
          | MedicalEngineIllnessCheckReport
      )?.illnessCategories,
    [chatbotQuestionData],
  )

  const isPreviousQuestionOverview = useMemo(
    () =>
      !!(chatbotQuestionData[chatbotQuestionData.length - secondToLastQuestionIndex] as OverviewResponse)
        ?.addedSymptoms,
    [chatbotQuestionData],
  )

  const isShowingBackButtonNecessary = useMemo(
    () =>
      !showReportActions &&
      !isCheckFinished &&
      !isPreviousQuestionAReport &&
      (!isPreviousQuestionOverview || (currentResponse as ResponseTypeWithoutReport)?.detailedType === 'EMERGENCY') &&
      !isReporting,
    [
      showReportActions,
      isCheckFinished,
      isPreviousQuestionAReport,
      isPreviousQuestionOverview,
      currentResponse,
      isReporting,
    ],
  )

  const hideInitialBackButton = useMemo(
    () =>
      isDirectIllnessCheck && (currentResponse as ResponseTypeWithoutReport)?.detailedType === 'TERMS_AND_CONDITIONS',
    [currentResponse, isDirectIllnessCheck],
  )

  const isBackButtonVisible = useMemo(
    () =>
      (!hideInitialBackButton &&
        (currentResponse as ResponseTypeWithoutReport)?.detailedType !== 'START_SCREEN' &&
        isShowingBackButtonNecessary) ||
      isContentLibraryOpen,
    [currentResponse, hideInitialBackButton, isContentLibraryOpen, isShowingBackButtonNecessary],
  )

  const downloadReportAsPDF = useCallback(async () => {
    const { data } = await apiGateway.get<Blob>('v1/chatbot/report/pdf', { responseType: 'blob' })

    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.download = `symptomCheck.pdf`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }, [apiGateway])

  const confirmReport = useCallback(async () => {
    await apiGateway.post('v1/chatbot/report/confirm')

    setIsReporting(false)
    setCurrentResponse(null)
  }, [apiGateway, setCurrentResponse, setIsReporting])

  const showMiddleButton = ((currentResponse as Question)?.isSkippable ?? false) && !hideSkipButton

  const breakPoints = {
    col1: showMiddleButton ? { xs: 24, sm: 8, md: 8 } : { xs: 24, sm: 8, md: 8 },
    col2: showMiddleButton ? { xs: 24, sm: 8, md: 8 } : {},
    col3: showMiddleButton ? { xs: 24, sm: 4, md: 4 } : { xs: 24, sm: 10, md: 10 },
    col4: showMiddleButton ? { xs: 24, sm: 4, md: 4 } : { xs: 24, sm: 6, md: 6 },
  }

  const commonCol1Content = isBackButtonVisible && (
    <>
      {!isMobile && (
        <div style={{ display: 'flex', marginLeft: -15 }}>
          <IconButton
            testId="backButton"
            icon={<LeftOutlined />}
            onClick={onBackClick}
            disabled={isLoading}
            style={{ height: 30 }}
          />
        </div>
      )}
      {isMobile && (
        <StyledButton
          type="skip"
          title={i18n('general.back')}
          disabled={isLoading}
          onClick={onBackClick}
          style={{ height: 30, width: '100%' }}
        />
      )}
    </>
  )

  if (isContentLibraryOpen) {
    return (
      <div className={styles.footer} id="footer">
        <Row
          style={{
            marginLeft: isTabletOrSmaller && !isMobile ? 36 : 0,
            marginRight: isTabletOrSmaller && !isMobile ? 36 : 0,
          }}
        >
          <Col {...breakPoints.col1}>{commonCol1Content}</Col>
        </Row>
        <div />
        {isRestartModalVisible && (
          <RestartModal
            onConfirm={() => {
              onRestartCheck()

              setIsRestartModalVisible(false)
            }}
            onCancel={() => setIsRestartModalVisible(false)}
          />
        )}
      </div>
    )
  }

  return (
    <div className={styles.footer} id="footer">
      <Row
        style={{
          marginLeft: isTabletOrSmaller && isReporting && !isMobile ? 36 : 0,
          marginRight: isTabletOrSmaller && isReporting && !isMobile ? 36 : 0,
        }}
      >
        <Col {...breakPoints.col1} order={isMobile ? 4 : 0}>
          {isReporting && (
            <StyledButton
              type="skip"
              title={i18n('general.downloadReport')}
              disabled={isLoading}
              onClick={downloadReportAsPDF}
              classNames={[styles.reportButton, styles.pdfButton]}
              style={{ height: 30 }}
            />
          )}
          {commonCol1Content}
        </Col>
        <Col {...breakPoints.col2} order={isMobile ? 3 : 0}>
          {showMiddleButton && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <StyledButton
                testId="skipButton"
                type="skip"
                title={
                  (currentResponse as Question).detailedType === 'SYMPTOM_SUGGESTION'
                    ? i18n('general.noneOfThese')
                    : i18n('general.skip')
                }
                disabled={isLoading}
                onClick={() => onSendAnswer({ questionId: (currentResponse as Question).id })}
                style={{ height: 30, marginBottom: isMobile ? 10 : 'unset' }}
                classNames={[
                  (currentResponse as Question).detailedType === 'SYMPTOM_SUGGESTION'
                    ? styles.noneOfTheseButton
                    : styles.skipButton,
                ]}
              />
            </div>
          )}
        </Col>
        <Col {...breakPoints.col3} className={styles.endCheckCol} order={isMobile ? 2 : 0}>
          {isReporting && (
            <StyledButton
              type="skip"
              title={i18n('general.restartCheck')}
              onClick={() => setIsRestartModalVisible(true)}
              disabled={isLoading}
              classNames={[styles.reportButton, styles.restartButton]}
              style={{ height: 30, backgroundColor: 'rgb(0, 0, 0, 0)', borderColor: '#000000', color: '#000000' }}
            />
          )}
        </Col>
        <Col {...breakPoints.col4} style={{ textAlign: 'end' }} order={isMobile ? 1 : 0}>
          {(currentResponse as Question)?.detailedType === 'EMERGENCY'
            ? (currentResponse as Question)?.options?.values?.map((option, index) => (
                <div
                  key={`emergency-option-${option.id}`}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: ((currentResponse as Question)?.options?.count ?? 0) > 1 ? 12 : 0,
                  }}
                >
                  <StyledButton
                    testId={`emergencyButton-${index}`}
                    type="filled"
                    title={option.text}
                    disabled={isLoading}
                    onClick={() =>
                      onSendAnswer({
                        questionId: (currentResponse as Question)?.id,
                        answer: { id: option.id },
                      })
                    }
                    classNames={[styles.emergencyButton]}
                    style={{ height: 30 }}
                  />
                </div>
              ))
            : !isSoteMode &&
              ['ILLNESS_CHECK_LEGAL_DISCLAIMER', 'SYMPTOM_CHECK_LEGAL_DISCLAIMER'].includes(
                (currentResponse as Question)?.checkStateInfo?.detailedState,
              ) && (
                <StyledButton
                  type="confirm"
                  title={`${(currentResponse as Question)?.options?.values[0].text}`}
                  disabled={isLoading}
                  onClick={() => {
                    onSendAnswer({
                      questionId: (currentResponse as Question)?.id,
                      answer: { id: (currentResponse as Question)?.options?.values[0].id },
                    })
                  }}
                  classNames={[styles.reportButton]}
                  style={{ height: 30 }}
                />
              )}
          {nextButtonLogic && (
            <StyledButton
              type="confirm"
              //TODO refactor question button title/logic settings
              title={
                (currentResponse as Question)?.detailedType === 'PROGRESS'
                  ? i18n('general.next')
                  : i18n(nextButtonI18nKey as TranslationCodeLiteral)
              }
              onClick={nextButtonLogic}
              disabled={isLoading || isNextButtonDisabled}
              classNames={[styles.nextButton]}
              style={{ height: 30, marginBottom: isMobile ? 10 : 'unset' }}
            />
          )}
          {isReporting && (
            //TODO create button variants
            <StyledButton
              type="confirm"
              title={i18n('general.endCheck')}
              onClick={confirmReport}
              disabled={isLoading}
              classNames={[styles.reportButton]}
              style={{ height: 30 }}
            />
          )}
        </Col>
      </Row>
      <div />
      {isRestartModalVisible && (
        <RestartModal
          onConfirm={() => {
            onRestartCheck()

            setIsRestartModalVisible(false)
          }}
          onCancel={() => setIsRestartModalVisible(false)}
        />
      )}
    </div>
  )
}
