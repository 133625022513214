import React, { FC, useMemo, useState } from 'react'
import { Col, Popover } from 'antd'
import mapboxgl from 'mapbox-gl'
import { useMap } from 'react-map-gl'
import { CSSTransition } from 'react-transition-group'
import { CustomIcon } from '../../../CustomIcon/CustomIcon'
import { OpeningHoursDisplay } from '../../ServicesMenu/ServiceDetails/OpeningHoursDisplay/OpeningHoursDisplay'
import { useServicesContext } from '../../../../context'
import { useI18N } from '../../../../hooks'
import { ServiceWithDetailedOpeningHours } from '../../../../models'
import { ServiceDetailsModal } from '../../ServicesMenu/ServiceDetails/ServiceDetailsModal/ServiceDetailsModal'
import { MAPBOX_MAX_ZOOM_LEVEL } from '../../../../constants'
import { LocationContainer, StyledCol } from '../../ServicesMenu/ServiceDetails/ServiceDetails.styled'
import { StyledTypography } from '../../ServicesMenu/SearchItem/SearchItemInfo/SearchItemInfo.styled'
import { StyledRow } from '../../ServicesMenu/SearchItem/SearchItem.styled'
import { StyledIconContainer } from '../../ServicesMenu/ServiceDetails/ServiceDetails'
import { Container } from './ServiceDetailsMobile.styled'

/**
 * @param props The props object
 * @param props.service The current service
 * @returns The ServiceDetailsMobile component
 */
export const ServiceDetailsMobile: FC<{
  service: ServiceWithDetailedOpeningHours
}> = ({ service }) => {
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false)

  const isMapboxSupported = useMemo(() => mapboxgl.supported(), [])

  const { setSelectedService } = useServicesContext()
  const { i18n } = useI18N()
  const { map } = useMap()

  return (
    <CSSTransition in={true} timeout={500} classNames="innerWindowAnimation" appear unmountOnExit>
      <Container>
        <StyledRow>
          <StyledCol flex="auto">
            <StyledTypography>
              {service?.insurers?.map((insurer, index) => (
                <span key={index}>{insurer} </span>
              ))}
            </StyledTypography>

            <LocationContainer>
              <StyledTypography>{service.location.address}</StyledTypography>

              <StyledTypography>
                {service.location.zip} {service.location.province}
              </StyledTypography>
            </LocationContainer>
          </StyledCol>

          <Col flex="20px">
            <StyledIconContainer onClick={() => setIsDetailsModalOpen(true)}>
              <CustomIcon icon="mail" />
            </StyledIconContainer>

            <Popover content={isMapboxSupported ? '' : i18n('services.mapViewNotSupported')}>
              <StyledIconContainer
                onClick={() => {
                  if (isMapboxSupported) {
                    map?.flyTo({
                      center: [service.location.longitude, service.location.latitude],
                      zoom: MAPBOX_MAX_ZOOM_LEVEL,
                    })
                    setSelectedService(service)
                  }
                }}
              >
                <CustomIcon icon="navigation" />
              </StyledIconContainer>
            </Popover>
          </Col>
        </StyledRow>

        <StyledRow>
          <Col flex="auto">
            <OpeningHoursDisplay openingHours={service.openingHours} />
          </Col>
        </StyledRow>

        <ServiceDetailsModal
          service={service}
          isDetailsModalOpen={isDetailsModalOpen}
          setIsDetailsModalOpen={setIsDetailsModalOpen}
        />
      </Container>
    </CSSTransition>
  )
}
