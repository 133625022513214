import React, { CSSProperties, FC } from 'react'
import { ActiveFilter } from '../../ServicesMenu'
import { useServicesContext } from '../../../../../context'
import { useI18N } from '../../../../../hooks'
import { FilterKey } from '../../../../../models/servicesFilters'
import { Container, RemoveFilterIcon } from '../Filters.common.styled'
import { SelectedLocationContainer, StyledButton } from './LocationsFilter.styled'

/**
 * @param props The props object
 * @param props.activeFilters The active filters
 * @param props.currentFilter The current filter
 * @param props.selectedButtonStyles The stylings for buttons in the selected state
 * @param props.setIsLocationsDetailedViewVisible The setter for whether the locations detailed view is visible
 * @returns The LocationsFilter component
 */
export const LocationsFilter: FC<{
  activeFilters: ActiveFilter
  currentFilter: FilterKey
  selectedButtonStyles: (val?: boolean) => CSSProperties
  setIsLocationsDetailedViewVisible: (val: boolean) => void
}> = ({ activeFilters, currentFilter, selectedButtonStyles, setIsLocationsDetailedViewVisible }) => {
  const {
    coordinates: { latitude, longitude },
    filterObject,
    setFilterObject,
  } = useServicesContext()

  const { i18n } = useI18N()

  if (!(activeFilters[currentFilter] && currentFilter === 'location')) {
    return null
  }

  return (
    <Container className="descriptionFadeIn">
      {filterObject?.location && (
        <SelectedLocationContainer>
          <StyledButton
            key={filterObject?.location?.text}
            type="primary"
            style={selectedButtonStyles(true)}
            onClick={() => {
              setFilterObject({
                ...filterObject,
                location: undefined,
              })
            }}
          >
            {filterObject?.location?.text}

            <RemoveFilterIcon />
          </StyledButton>
        </SelectedLocationContainer>
      )}

      {filterObject.location?.text !== i18n('services.filters.nearby') && (
        <StyledButton
          type="primary"
          style={selectedButtonStyles(false)}
          onClick={() =>
            setFilterObject({
              ...filterObject,
              location: { text: i18n('services.filters.nearby'), latitude, longitude },
            })
          }
        >
          {i18n('services.filters.nearby')}
        </StyledButton>
      )}

      <StyledButton
        type="primary"
        style={selectedButtonStyles(false)}
        onClick={() => setIsLocationsDetailedViewVisible(true)}
      >
        {i18n('services.filters.findLocation')}
      </StyledButton>
    </Container>
  )
}
