import React, { FC, useMemo } from 'react'
import { Row, Col } from 'antd'
import classnames from 'classnames'
import { renderToStaticMarkup } from 'react-dom/server'
import { Card, Heading, Text } from '@xund-packages/xund-ui'
import { useI18N } from '../../../hooks'
import { CustomIcon } from '../../CustomIcon/CustomIcon'
import { CSS_VARS } from '../../../resources/cssVariableConfig'
import { EmergencyWaveBackground } from './EmergencyWaveBackground/EmergencyWaveBackground'
import styles from './EmergencyWarningWithCallNumber.module.less'

/**
 * @param props The props object
 * @param props.withWave The boolean which decides whether the bottom of the component should have a wave cut out
 * @returns The EmergencyWarningWithCallNumber component
 */
export const EmergencyWarningWithCallNumber: FC<{ withWave?: boolean }> = ({ withWave = false }) => {
  const { i18n } = useI18N()

  const svgString = useMemo(() => encodeURIComponent(renderToStaticMarkup(<EmergencyWaveBackground />)), [])

  return (
    <>
      {withWave && (
        <Col xs={{ span: 18, offset: 0 }} md={{ offset: 4, span: 18 }}>
          <div
            className={classnames(styles.emergencyOuterCard, 'descriptionFadeIn')}
            style={{
              color: CSS_VARS.chatBackgroundColor,
              borderRadius: '10px 10px 0 0',
              background: `url('data:image/svg+xml;utf8, ${svgString}') no-repeat center center/cover`,
            }}
          >
            <Row justify="space-between" align="middle" className={styles.emergencyContainer}>
              <Row justify="center" align="middle" className={styles.emergencyNumberContainer}>
                <Row
                  justify="center"
                  align="middle"
                  className={styles.emergencyNumber}
                  style={{ background: CSS_VARS.chatBackgroundColor, color: CSS_VARS.RED_COLOR }}
                >
                  {i18n('general.emergencyNumber')}
                </Row>
              </Row>

              <Col className={styles.disclaimer}>
                <strong>{i18n('report.emergency.title')}</strong>

                <p>{i18n('report.emergency.description', i18n('general.emergencyNumber'))}</p>
              </Col>
            </Row>
          </div>
        </Col>
      )}
      {!withWave && (
        <Col xs={{ span: 18, offset: 0 }} md={{ offset: 4, span: 18 }}>
          <Card
            className={styles.card}
            shadow={-1}
            radius={'md'}
            style={{ background: CSS_VARS.RED_COLOR, display: 'flex' }}
          >
            <CustomIcon className={styles.number} icon={'servicesSos'} />
            <div className={styles.texts} style={{ lineHeight: CSS_VARS.modalTitleFontSize }}>
              <Heading as={'h4'} style={{ fontSize: CSS_VARS.modalFontSize, color: '#ffffff' }}>
                {i18n('report.emergency.title')}
              </Heading>
              <Text style={{ fontSize: CSS_VARS.modalFontSize, color: '#ffffff' }}>
                {i18n('report.emergency.description', i18n('general.emergencyNumber'))}
              </Text>
            </div>
          </Card>
        </Col>
      )}
    </>
  )
}
