import React, { FC, useEffect, useState } from 'react'
import { useDebouncedState } from 'xund-react-utils'
import { ChatbotQuestionAnswer, Question } from '../../models'
import { useSearch } from '../../hooks'
import { OptionItem } from '../../models/api/response/OptionItem'
import { WrapperWithTitleAndAnimation } from '../WrapperWithTitleAndAnimation'
import { SearchInput } from '../General/SearchInput/SearchInput'
import { useApiGatewayContext } from '../../context'
import { DEFAULT_SEARCH_DEBOUNCE_TIME_IN_MS } from '../../constants'
import { SymptomAndIllnessList } from './SymptomAndIllnessList/SymptomAndIllnessList'

/**
 * @param props The props object
 * @param props.question The current question
 * @param props.isLoading The boolean for whether the app is loading
 * @param props.sendAnswer The method that sends the answer
 * @returns The SymptomAndIllnessSelector component
 */
export const SymptomAndIllnessSelector: FC<{
  question: Question
  isLoading: boolean
  sendAnswer: (val: ChatbotQuestionAnswer) => void
}> = ({ question, isLoading, sendAnswer }) => {
  const [options, setOptions] = useState<OptionItem[]>(question.options?.values || [])
  const { setValue: setSearchText, debouncedValue: searchText } = useDebouncedState(
    '',
    DEFAULT_SEARCH_DEBOUNCE_TIME_IN_MS,
  )
  const [error, setError] = useState<Error>()

  const { apiGateway } = useApiGatewayContext()

  const getOptions = useSearch

  useEffect(() => {
    if (searchText) {
      getOptions(setOptions, setError, apiGateway, searchText)
    } else {
      setOptions(question.options?.values as OptionItem[])
    }
  }, [getOptions, searchText, question.options?.values, apiGateway])

  if (error) {
    throw error
  }

  return (
    <WrapperWithTitleAndAnimation
      title={question.text}
      isAnimated
      animationStartsWhen={!isLoading}
      style={{ justifyContent: 'flex-start', height: '100%' }}
      hasTopMargin={false}
    >
      <SearchInput setSearchText={setSearchText} />

      <SymptomAndIllnessList
        question={question}
        options={options}
        searchText={searchText}
        isLoading={isLoading}
        sendAnswer={sendAnswer}
      />
    </WrapperWithTitleAndAnimation>
  )
}
