//Holds translation keys with missing translation (allows deployment without actual translations)
const translations: Record<string, string> = {
  'healthCheck.report.mainTitle': 'Report of ',
  'healthCheck.report.legend.title': 'Risk severity chart',
  'healthCheck.report.legend.description':
    'In the chart, you find a list of the topics you have completed, where each topic is ranked on a riskfactor scale of 1 to 3 based on the answers you have given.',
  'healthCheck.report.score.high': 'High risk',
  'healthCheck.report.score.medium': 'Medium risk',
  'healthCheck.report.score.low': 'Low risk',
  'healthCheck.report.score.good': 'Normal risk',
  'healthCheck.report.score.completed': 'Completed topics',
  'healthCheck.report.score.incomplete': 'Not completed topics',
  'healthCheck.report.riskFactorsTitle': 'Factors affecting ',
  'healthCheck.report.riskTopic.title': `What is ?`,
  'healthCheck.report.riskLevel': 'Risk level',
  'healthCheck.report.profile.sex': 'Sex',
  'healthCheck.report.profile.age': 'Age',
  'healthCheck.report.profile.weight': 'Weight',
  'healthCheck.report.profile.height': 'Height',
  'healthCheck.report.profile.bmi': 'Your BMI is -',
  'healthCheck.report.protectiveRiskFactor.title': 'Positive factors that keep your cardiovascular diseases risk low',
  'healthCheck.report.protectiveRiskFactor.description':
    'Based on your answers, these are the positive factors in your lifestyle that keep your cardiovascular diseases risk low.',
  'healthCheck.report.protectiveRiskFactor.emptyState.title': "Don't give up!",
  'healthCheck.report.protectiveRiskFactor.emptyState.subTitle':
    'Follow the recommendations to make a positive impact!',
  'healthCheck.report.negativeRiskFactors.title': 'How to improve cardiovascular diseases',
  'healthCheck.report.negativeRiskFactors.description':
    'Based on your answers, you should work on the following factors to improve your health.',
  'healthCheck.report.negativeRiskFactor.emptyState.title': 'Keep going!',
  'healthCheck.report.negativeRiskFactor.emptyState.subTitle': 'No potential risk factors in the selected health topic',
  'healthCheck.report.associatedRiskFactor': 'Associated risk factor: ',
  'healthCheck.report.recommendations': 'Recommendations',
  'healthCheck.report.examination': 'About the examination',
  'healthCheck.status.start': 'Start',
  'healthCheck.status.profile': 'Profile',
  'healthCheck.status.riskTopics': 'Risk topics',
  'healthCheck.status.healthData': 'Health data',
  'healthCheck.status.report': 'Report',
  'healthCheck.footer.next': 'Next',
  'healthCheck.footer.understood': 'I have understood',
  'components.bmi.height.title': 'Height (cm)',
  'components.bmi.height.placeholder': 'Your height (in cm)',
  'components.bmi.weight.title': 'Weight (kg)',
  'components.bmi.weight.placeholder': 'Your weight (in kg)',
  'components.singleSelect.placeholder': 'Please select a value',
  'components.multiSelect.placeholder': 'Please select one or more values',
  'components.age.placeholder': 'Please select your age',
}
export const getTempTranslation = (code: string) => translations?.[code] ?? code
