import { MedicalEngineIllnessCheckReport, MedicalEngineSymptomCheckReport } from 'medical-engine-api'
import { Col, Row } from 'antd'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { flushSync } from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import { useElementSize } from 'usehooks-ts'
import { useApiGatewayContext, useAppStateContext, useWebAppConfigContext } from '../../context'
import { useQuestionContext as useHealthCheckContext } from '../../features/healthCheck/context'
import { useIsUserOnline, useScreenSize } from '../../hooks'
import {
  ChatbotQuestionAnswer,
  CheckPhaseStep,
  CheckType,
  OverviewResponse,
  Question,
  ResponseType,
  ResponseTypeWithoutReport,
  WebAppConfig,
} from '../../models'
import { legalDisclaimer, startQuestion } from '../../resources/startingQuestion'
import { changeCheckStatus, determineBackgroundImageToUse } from '../../utils'
import { ContentLibrary } from '../ContentLibrary/ContentLibrary'
import { ContentLibraryTitle } from '../ContentLibrary/ContentLibraryTitle/ContentLibraryTitle'
import { Footer } from '../Footer/Footer'
import { StatusBar } from '../General/StatusBar/StatusBar'
import { Header } from '../Header/Header'
import { NoConnection } from '../NoConnection/NoConnection'
import { Services } from '../Services/Services'
import { SideMenu } from '../SideMenu/SideMenu'
import { PoweredByXund } from '../General/PoweredByXund/PoweredByXund'
import { useQuestionContext } from '../../context/QuestionContext'
import { StartScreen } from '../StartScreen/StartScreen'
import {
  Footer as HealthCheckFooter,
  Renderer,
  StatusBar as HealthCheckStatusBar,
} from '../../features/healthCheck/components'
import { CSS_VARS } from '../../resources/cssVariableConfig'
import { Report } from '../../features/healthCheck/components/Report'
import styles from './MainPage.module.less'
import { QuestionHandler } from './QuestionHandler/QuestionHandler'
import { actualWebAppConfig, getInitializeIllnessheckPayload, getInitializeSymptomCheckPayload } from './MainPage.utils'
import { PageContainer } from './MainPage.styled'

/**
 * The main page component
 *
 * @param props The props object
 * @param props.testWebAppConfig set config as parameter only use it in tests!
 * @returns The main page component
 */
export const MainPage: FC<{
  testWebAppConfig?: WebAppConfig
}> = ({ testWebAppConfig }) => {
  const [chatbotQuestionData, setChatbotQuestionData] = useState<ResponseType[]>([startQuestion])
  const [checkIfAddMoreSymptomId, setCheckIfAddMoreSymptomId] = useState('')
  const [checkPhaseStep, setCheckPhaseStep] = useState<CheckPhaseStep>(0)
  const [isOverview, setIsOverview] = useState(false)
  const [isWarningSign, setIsWarningSign] = useState(false)
  const [isReportMissing, setIsReportMissing] = useState(false)
  const [showReportActions, setShowReportActions] = useState(false)
  const [contentLibraryItemId, setContentLibraryItemId] = useState('')
  const [error, setError] = useState<Error>()
  const [geolocation, setGeolocation] = useState<GeolocationPosition | null>(null)

  const { isMobileView } = useScreenSize()
  const { apiGateway, checkId, setCheckId, initialSymptom, initialIllness, setInitialIllness } = useApiGatewayContext()
  const isSoteMode = window.xundEnvironment.IS_SOTE_MODE === 'true'
  const isWarningSignsPopupEnabled = window.xundEnvironment.WARNING_SIGNS_POPUP_ENABLED === 'true'
  const { isUserOnline } = useIsUserOnline()
  const realWebAppConfig = useWebAppConfigContext()
  const {
    backgroundImage,
    boxShadowHidden,
    illnessCheckIcd10Code: directIllnessCheckCode,
  } = actualWebAppConfig(testWebAppConfig, realWebAppConfig.webAppConfig)

  const { report: healthCheckReport } = useHealthCheckContext()

  const {
    currentResponse,
    setCurrentResponse,
    isLoading,
    setIsLoading,
    setIsReporting,
    isCheckFinished,
    setIsCheckFinished,
    checkType,
    setCheckType,
    setMainContainerWidth,
    setHideSkipButton,
    setNextButtonLogic,
    setNextButtonI18nKey,
    setIsNextButtonDisabled,
  } = useQuestionContext()

  const { isServicesOpen, setServicesOpen, setSideMenuOpen, isContentLibraryOpen, setContentLibraryOpen } =
    useAppStateContext()

  const [mainContainerRef, { width }] = useElementSize()

  const currentResponseDetailedType = useMemo(
    () => (currentResponse as ResponseTypeWithoutReport)?.detailedType,
    [currentResponse],
  )

  /**
   * Starts the check
   */
  const startCheck = useCallback(async () => {
    try {
      setIsLoading(true)

      let checkIdToSet = ''
      if (checkType === 'SYMPTOM_CHECK') {
        const { data } = await apiGateway.post<{ checkId: string }>(
          `v1/chatbot/initialize/symptomCheck`,
          isSoteMode ? {} : getInitializeSymptomCheckPayload(initialSymptom),
        )

        checkIdToSet = data.checkId
      } else if (checkType === 'ILLNESS_CHECK') {
        const { data } = await apiGateway.post<{ checkId: string }>(
          `v1/chatbot/initialize/${isSoteMode ? 'symptomCheck' : 'illnessCheck'}`,
          isSoteMode ? {} : getInitializeIllnessheckPayload(initialIllness),
        )

        checkIdToSet = data.checkId
      }

      flushSync(() => {
        setCheckId(checkIdToSet)
      })
      setCurrentResponse(null)
    } catch (err) {
      setError(err as Error)
    } finally {
      setIsLoading(false)
    }
  }, [apiGateway, checkType, initialIllness, initialSymptom, isSoteMode, setCheckId, setCurrentResponse, setIsLoading])

  const soteStartCheck = useCallback(async () => {
    try {
      setIsLoading(true)
      setCheckType('SYMPTOM_CHECK')

      let checkIdToSet = ''
      const { data } = await apiGateway.post<{ checkId: string }>(`v1/chatbot/initialize/symptomCheck`, {})

      checkIdToSet = data.checkId

      flushSync(() => {
        setCheckId(checkIdToSet)
      })
      setCurrentResponse(null)
    } catch (err) {
      setError(err as Error)
    } finally {
      setIsLoading(false)
    }
  }, [apiGateway, setCheckId, setCurrentResponse, setIsLoading, setCheckType])
  /**
   * Show the terms and conditions component
   *
   * @param checkTypeToStart The check type to start
   */
  const showTermsAndConditions = useCallback(
    async (checkTypeToStart: CheckType) => {
      setCheckType(checkTypeToStart)
      setCurrentResponse(legalDisclaimer)
      setChatbotQuestionData([...chatbotQuestionData, legalDisclaimer])
    },
    [chatbotQuestionData, setCheckType, setCurrentResponse],
  )

  const getQuestion = useCallback(async () => {
    try {
      setIsLoading(true)

      const { data } = await apiGateway.get<Question>('/v1/chatbot/question')

      if (data.detailedType === 'CHECK_IF_ADD_MORE_SYMPTOMS') {
        setCheckIfAddMoreSymptomId(data.id)
      }

      setCurrentResponse(data)
      setChatbotQuestionData([...chatbotQuestionData, data])
      changeCheckStatus(data?.checkStateInfo?.state, setCheckPhaseStep)
      setIsWarningSign(data.detailedType === 'EMERGENCY')
    } catch (err) {
      setError(err as Error)
    } finally {
      setIsLoading(false)
    }
  }, [apiGateway, chatbotQuestionData, setCurrentResponse, setIsLoading, setIsWarningSign])

  /**
   * Show the terms and conditions component
   */
  const stepBackInTheFlow = useCallback(async () => {
    if (currentResponseDetailedType === 'TERMS_AND_CONDITIONS') {
      if (directIllnessCheckCode) {
        return
      }

      // resetting every value of QuestionContext to their initial value
      setHideSkipButton(false)
      setCurrentResponse(startQuestion)
      setNextButtonLogic(null)
      setNextButtonI18nKey('general.confirm')
      setIsLoading(false)
      setIsNextButtonDisabled(false)
      setIsReporting(false)
      setIsCheckFinished(false)
      setCheckType('')
      setMainContainerWidth(0)

      return
    }

    if ((currentResponse as Question).detailedType === 'TERMS_AND_CONDITIONS') {
      setCheckType('')
    }
    if (isOverview) {
      setIsOverview(false)
    }

    const secondToLastQuestionIndex = 2

    if (
      ((currentResponse as ResponseTypeWithoutReport)?.id &&
        (currentResponse as ResponseTypeWithoutReport).detailedType !== 'AGE') ||
      isOverview
    ) {
      setIsLoading(true)
      try {
        await apiGateway.post('v1/chatbot/restoreToQuestion', {
          questionId: (
            chatbotQuestionData[chatbotQuestionData.length - secondToLastQuestionIndex] as ResponseTypeWithoutReport
          )?.id,
        })

        await getQuestion()
      } catch (err) {
        setError(err as Error)
      } finally {
        setIsLoading(false)
      }
    } else {
      setCurrentResponse(chatbotQuestionData[chatbotQuestionData.length - secondToLastQuestionIndex])
    }

    setChatbotQuestionData(chatbotQuestionData.slice(0, chatbotQuestionData.length - 1))

    changeCheckStatus(
      (chatbotQuestionData[chatbotQuestionData.length - secondToLastQuestionIndex] as ResponseTypeWithoutReport)
        ?.checkStateInfo?.state,
      setCheckPhaseStep,
    )
  }, [
    directIllnessCheckCode,
    currentResponseDetailedType,
    currentResponse,
    isOverview,
    chatbotQuestionData,
    setCheckType,
    setIsLoading,
    apiGateway,
    getQuestion,
    setCurrentResponse,
    setHideSkipButton,
    setNextButtonLogic,
    setNextButtonI18nKey,
    setIsNextButtonDisabled,
    setIsReporting,
    setIsCheckFinished,
    setMainContainerWidth,
  ])

  const sendAnswer = useCallback(
    async (answer: ChatbotQuestionAnswer, isInfoQuestion?: boolean) => {
      if (!isLoading) {
        try {
          if (!isInfoQuestion) {
            setIsLoading(true)
          }

          const { data } = await apiGateway.post<{
            checkStatus: 'FINISHED'
            mandatoryFunction: 'OVERVIEW' | 'REPORT'
          }>('/v1/chatbot/answer', answer)

          if (isInfoQuestion) {
            return
          }

          if (data?.mandatoryFunction === 'OVERVIEW' && data.checkStatus !== 'FINISHED') {
            setIsOverview(true)
          } else if (data?.mandatoryFunction === 'REPORT') {
            setIsReportMissing(true)
          } else if (data?.checkStatus === 'FINISHED') {
            setIsCheckFinished(true)
            return
          }

          const lastDataItemIndex = chatbotQuestionData.length - 1
          const lastDataItem = chatbotQuestionData[lastDataItemIndex]

          setChatbotQuestionData([
            ...chatbotQuestionData.slice(0, lastDataItemIndex),
            {
              ...lastDataItem,
              answer,
            } as Question,
          ])

          setCurrentResponse(null)
        } catch (err) {
          setError(err as Error)
        } finally {
          if (!isInfoQuestion) {
            setIsLoading(false)
          }
        }
      }
    },
    [apiGateway, chatbotQuestionData, isLoading, setCurrentResponse, setIsCheckFinished, setIsLoading],
  )

  const getOverview = useCallback(async () => {
    try {
      setIsLoading(true)

      const { data } = await apiGateway.get<OverviewResponse>('/v1/chatbot/overview')

      setCurrentResponse({ ...data, id: checkIfAddMoreSymptomId })
      setChatbotQuestionData([...chatbotQuestionData, { ...data, id: checkIfAddMoreSymptomId }])
    } catch (err) {
      setError(err as Error)
    } finally {
      setIsOverview(false)
      setIsLoading(false)
    }
  }, [apiGateway, chatbotQuestionData, checkIfAddMoreSymptomId, setCurrentResponse, setIsLoading])

  const getReport = useCallback(
    async ({ location }: { location: GeolocationPosition | null }) => {
      try {
        setIsLoading(true)

        const locationToSend = location
          ? { location: { latitude: location.coords.latitude, longitude: location.coords.longitude } }
          : {}

        const { data } = await apiGateway.get<MedicalEngineSymptomCheckReport | MedicalEngineIllnessCheckReport>(
          'v1/chatbot/report',
          { params: locationToSend },
        )

        setCheckPhaseStep(4)
        setCurrentResponse(data)
        setChatbotQuestionData([...chatbotQuestionData, { ...data, id: 'REPORT' }])
        setIsReporting(true)
      } catch (err) {
        setError(err as Error)
      } finally {
        setShowReportActions(true)
        setIsReportMissing(false)
        setIsLoading(false)
      }
    },
    [apiGateway, chatbotQuestionData, setCurrentResponse, setIsLoading, setIsReporting],
  )

  const isNewQuestionNeeded = useMemo(
    () => checkId && (!chatbotQuestionData.length || !currentResponse),
    [checkId, currentResponse, chatbotQuestionData.length],
  )

  const isCheckNotFinalized = useMemo(
    () => !isLoading && !isOverview && !isReportMissing && !isCheckFinished,
    [isLoading, isOverview, isCheckFinished, isReportMissing],
  )

  const showReport = useMemo(() => showReportActions && !isSoteMode, [showReportActions, isSoteMode])

  const sendConfirmAnswer = useCallback(
    () =>
      sendAnswer({
        questionId: (currentResponse as Question)?.id,
        answer: {
          id: (currentResponse as Question)?.options?.values[0]?.id,
        },
      }),
    [currentResponse, sendAnswer],
  )

  useEffect(() => {
    if (isNewQuestionNeeded && isCheckNotFinalized) {
      getQuestion()
    }
  }, [isNewQuestionNeeded, isCheckNotFinalized, getQuestion])

  useEffect(() => {
    if (isOverview && !isLoading) {
      getOverview()
    }
  }, [getOverview, isOverview, isLoading])

  useEffect(() => {
    if (isReportMissing && !isLoading) {
      getReport({ location: geolocation })
    }
  }, [getReport, isReportMissing, isLoading, geolocation])

  useEffect(() => {
    if (isReportMissing && !isLoading) {
      ;(async () => {
        const geolocationOrNull = await new Promise<GeolocationPosition>((resolve, reject) =>
          navigator.geolocation.getCurrentPosition(resolve, reject),
        )
          .then((location) => location)
          .catch(() => null)

        setGeolocation(geolocationOrNull)
        geolocationOrNull && (await getReport({ location: geolocationOrNull }))
      })()
    }
  }, [isReportMissing, isLoading, getReport, setGeolocation])

  useEffect(() => {
    determineBackgroundImageToUse(backgroundImage)
  }, [backgroundImage])

  useEffect(() => {
    setMainContainerWidth(width)
  }, [setMainContainerWidth, width])

  useEffect(() => {
    if (directIllnessCheckCode && currentResponseDetailedType === 'START_SCREEN') {
      setInitialIllness(directIllnessCheckCode)
      showTermsAndConditions('ILLNESS_CHECK')
    }
  }, [currentResponseDetailedType, directIllnessCheckCode, setInitialIllness, showTermsAndConditions])

  useEffect(() => {
    if (currentResponse && isWarningSign && !isWarningSignsPopupEnabled) {
      sendConfirmAnswer()
    }
  }, [isWarningSign, isWarningSignsPopupEnabled, sendConfirmAnswer, currentResponse])

  const screenContent = useMemo(
    () => (
      <div style={{ height: '100%', width: '100%' }}>
        {checkType === '' && (
          <StartScreen showTermsAndConditions={isSoteMode ? soteStartCheck : showTermsAndConditions} />
        )}
        {checkType !== 'HEALTH_CHECK' && (
          <QuestionHandler
            currentResponse={currentResponse as ResponseType}
            chatbotQuestionData={chatbotQuestionData}
            isLoading={isLoading}
            startCheck={startCheck}
            stepBackInTheFlow={stepBackInTheFlow}
            sendAnswer={sendAnswer}
            setChatbotQuestionData={setChatbotQuestionData}
            setIsOverview={setIsOverview}
            location={geolocation}
          />
        )}
        {checkType === 'HEALTH_CHECK' && <Renderer />}
      </div>
    ),
    [
      checkType,
      isSoteMode,
      soteStartCheck,
      showTermsAndConditions,
      currentResponse,
      chatbotQuestionData,
      isLoading,
      startCheck,
      stepBackInTheFlow,
      sendAnswer,
      geolocation,
    ],
  )

  const medicalContent = useMemo(
    () => (
      <div style={{ width: '100%' }}>
        <ContentLibrary
          contentLibraryItemId={contentLibraryItemId}
          onItemSelected={(id) => setContentLibraryItemId(id)}
        />
      </div>
    ),
    [contentLibraryItemId],
  )

  const containerStyles = useMemo(() => (showReport ? styles.reportContainer : styles.mainContent), [showReport])

  if (error) {
    throw error
  }

  if (!isUserOnline) {
    return (
      <Row style={{ height: '100%', width: '100%' }} justify="center" align="middle">
        <Row style={{ height: '100%', width: '50%' }}>
          <NoConnection />
        </Row>
      </Row>
    )
  }

  /**
   * handles clicking on the home icon in the sidebar and on the logo in the header
   *
   * @param onHomePageModalOpen A method opening the confirmation modal
   */
  const onHomeClick = (onHomePageModalOpen: () => void) => {
    if (isServicesOpen) {
      if (isMobileView) {
        setSideMenuOpen(false)
      }

      setServicesOpen(!isServicesOpen)
    }

    if (isContentLibraryOpen) {
      setContentLibraryOpen(!isContentLibraryOpen)
    }

    if (currentResponseDetailedType === 'START_SCREEN') {
      setSideMenuOpen(false)
    } else {
      onHomePageModalOpen()
    }
  }

  /**
   * handles restarting the current check
   */
  const onRestartCheck = () => {
    // resetting every value of QuestionContext to their current value on terms and conditions page except checkType and mainContainerWidth
    setHideSkipButton(false)
    setCurrentResponse(legalDisclaimer)
    setNextButtonLogic(null)
    setNextButtonI18nKey('general.confirm')
    setIsLoading(false)
    setIsNextButtonDisabled(false)
    setIsReporting(false)
    setIsCheckFinished(false)

    setCheckPhaseStep(0) // to reset step number when on terms and conditions page again
  }

  const healthCheckFlowContent = (
    <>
      <PageContainer isServicesOpen={isServicesOpen}>
        <CSSTransition
          in={currentResponseDetailedType === 'START_SCREEN'}
          timeout={500}
          classNames="innerWindowAnimation"
          appear
        >
          <Row justify="center" style={{ flex: 1 }}>
            {healthCheckReport && (
              <>
                <HealthCheckStatusBar />
                <Report isLoading={isLoading} report={healthCheckReport} />
              </>
            )}
            {isContentLibraryOpen ? (
              <ContentLibraryTitle />
            ) : (
              <>{currentResponseDetailedType !== 'START_SCREEN' && !healthCheckReport && <HealthCheckStatusBar />}</>
            )}
            {!healthCheckReport && (
              <Col
                ref={mainContainerRef}
                lg={{ span: 15 }}
                md={{ span: 19 }}
                sm={{ span: 19 }}
                style={
                  currentResponseDetailedType !== 'START_SCREEN'
                    ? {
                        background: CSS_VARS.chatBackgroundColor,
                        boxShadow: boxShadowHidden ? 'none' : '0px 16px 26px 0px rgba(0,0,0,0.05)',
                      }
                    : { minWidth: '100%' }
                }
                className={currentResponseDetailedType !== 'START_SCREEN' && containerStyles}
                data-testid="testShadow"
              >
                {isContentLibraryOpen ? medicalContent : screenContent}

                <HealthCheckFooter
                  onBackClick={() => {
                    if (isLoading) {
                      return
                    }

                    if (contentLibraryItemId) {
                      setContentLibraryItemId('')
                    } else if (isContentLibraryOpen) {
                      setContentLibraryOpen(false)
                    }
                  }}
                />
              </Col>
            )}
          </Row>
        </CSSTransition>
        <PoweredByXund displayWave={currentResponseDetailedType === 'START_SCREEN'} />
      </PageContainer>
      {isServicesOpen && <Services />}
    </>
  )

  const scIcFlowContent = (
    <>
      <PageContainer isServicesOpen={isServicesOpen}>
        <CSSTransition
          in={currentResponseDetailedType === 'START_SCREEN'}
          timeout={500}
          classNames="innerWindowAnimation"
          appear
        >
          <Row justify="center" style={{ flex: 1 }}>
            {isContentLibraryOpen ? (
              <ContentLibraryTitle />
            ) : (
              <>{currentResponseDetailedType !== 'START_SCREEN' && <StatusBar checkPhaseStep={checkPhaseStep} />}</>
            )}
            <Col
              ref={mainContainerRef}
              lg={{ span: 15 }}
              md={{ span: 19 }}
              sm={{ span: 19 }}
              style={
                currentResponseDetailedType !== 'START_SCREEN'
                  ? {
                      background: CSS_VARS.chatBackgroundColor,
                      boxShadow: boxShadowHidden ? 'none' : '0px 16px 26px 0px rgba(0,0,0,0.05)',
                    }
                  : { minWidth: '100%' }
              }
              className={currentResponseDetailedType !== 'START_SCREEN' && containerStyles}
              data-testid="testShadow"
            >
              {isContentLibraryOpen ? medicalContent : screenContent}

              <Footer
                showReportActions={showReport}
                isCheckFinished={isCheckFinished}
                chatbotQuestionData={chatbotQuestionData}
                onBackClick={() => {
                  if (isLoading) {
                    return
                  }

                  if (contentLibraryItemId) {
                    setContentLibraryItemId('')
                  } else if (isContentLibraryOpen) {
                    setContentLibraryOpen(false)
                  } else {
                    stepBackInTheFlow()
                  }
                }}
                onSendAnswer={sendAnswer}
                onRestartCheck={onRestartCheck}
              />
            </Col>
          </Row>
        </CSSTransition>
        <PoweredByXund displayWave={currentResponseDetailedType === 'START_SCREEN'} />
      </PageContainer>
      {isServicesOpen && <Services />}
    </>
  )

  return (
    <div id="background_image" className={styles.container}>
      <div className={styles.innerContainer}>
        {!(isServicesOpen && isMobileView) && <Header onHomeClick={onHomeClick} />}

        <SideMenu
          onServicesOpen={() => {
            if (isMobileView) {
              setSideMenuOpen(false)
            }

            setServicesOpen(!isServicesOpen)
          }}
          onHomeClick={onHomeClick}
          onRestartCheck={onRestartCheck}
          showRestart={!['START_SCREEN', 'TERMS_AND_CONDITIONS'].includes(currentResponseDetailedType)}
        />

        {checkType === 'HEALTH_CHECK' && healthCheckFlowContent}
        {checkType !== 'HEALTH_CHECK' && scIcFlowContent}
      </div>
    </div>
  )
}
