import React, { FC, Fragment, useMemo } from 'react'
import { Col, Steps } from 'antd'
import { useWindowSize } from 'usehooks-ts'
import { CheckOutlined } from '@ant-design/icons'
import { mobileMaxWidth, useI18N } from '../../../hooks'
import styles from '../../MainPage/MainPage.module.less'
import { CheckPhaseStep } from '../../../models'
import { useQuestionContext } from '../../../context/QuestionContext'
import { CSS_VARS } from '../../../resources/cssVariableConfig'

const lastStep = 4

/**
 * @param props The props object
 * @param props.checkPhaseStep The step of the check
 * @returns The StatusBar component
 */
export const StatusBar: FC<{ checkPhaseStep: CheckPhaseStep }> = ({ checkPhaseStep }) => {
  const { i18n } = useI18N()
  const { width } = useWindowSize()
  const { isCheckFinished } = useQuestionContext()

  const isMobile = useMemo(() => width <= mobileMaxWidth, [width])

  return (
    <Col lg={{ span: 19 }} md={{ span: 19 }} sm={{ span: 24 }} className={styles.stepsContent}>
      {!isMobile && (
        <Steps
          current={checkPhaseStep}
          size="default"
          labelPlacement="vertical"
          responsive={false}
          items={[
            { title: i18n('general.start') },
            { title: i18n('general.profile') },
            { title: i18n('general.symptoms') },
            { title: i18n('general.healthData') },
            {
              title: i18n('general.report'),
              status: isCheckFinished ? 'finish' : checkPhaseStep === lastStep ? 'process' : 'wait',
            },
          ]}
        />
      )}
      {isMobile && (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          {[...Array(5)].map((entry, index) => (
            <Fragment key={`status-bar--item-${index}`}>
              <div
                style={{
                  display: 'flex',
                  height: 15,
                  width: 15,
                  backgroundColor:
                    (index !== lastStep && index < checkPhaseStep) || (index === lastStep && isCheckFinished)
                      ? CSS_VARS.PRIMARY_COLOR
                      : '#ffffff',
                  borderRadius: '50%',
                  borderWidth: 1,
                  borderColor: index <= checkPhaseStep || index === checkPhaseStep ? CSS_VARS.PRIMARY_COLOR : '#BFBFBF',
                  borderStyle: 'solid',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {index <= checkPhaseStep && (
                  <div style={{ display: 'flex' }}>
                    <CheckOutlined style={{ color: 'white', fontSize: 10 }} />
                  </div>
                )}
              </div>
              {index !== 4 && (
                <span
                  style={{
                    flexGrow: 1,
                    borderBottom: `1px solid ${index <= checkPhaseStep ? CSS_VARS.PRIMARY_COLOR : '#BFBFBF'}`,
                    marginTop: 5,
                    marginBottom: 7,
                  }}
                />
              )}
            </Fragment>
          ))}
        </div>
      )}
    </Col>
  )
}
