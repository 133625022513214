import styled from '@emotion/styled'

export const RiskTopicSelectorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`
export const RiskTopicListContainer = styled.div`
  max-height: 400px;
  overflow: scroll;
`
export const RiskTopic = styled.div<{ isLoading: boolean; isDisabled?: boolean }>`
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid #bfbfbf;
  display: flex;
  min-height: 54px;
  padding-bottom: 7px;
  padding-top: 5px;
  &:hover {
    background-color: #f7f8fa;
  }
  cursor: ${(props) => (props.isLoading || props.isDisabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${(props) => (props.isLoading || props.isDisabled ? 'none' : 'initial')};
  opacity: ${(props) => (props.isLoading || props.isDisabled ? 0.3 : 1)};
`

export const RiskTopicTitle = styled.p`
  margin: 0;
  font-weight: 700;

  @media screen and (max-width: @mobile-max-width) {
    line-height: 1.2;
  }
`
