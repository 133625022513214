import React, { CSSProperties, FC } from 'react'
import styled from '@emotion/styled'
import { ActiveFilter } from '../../ServicesMenu'
import { useServicesContext } from '../../../../../context'
import { FilterKey, InsurersFilter } from '../../../../../models/servicesFilters'
import { Container, FilterButton } from '../Filters.common.styled'
import { CSS_VARS } from '../../../../../resources/cssVariableConfig'

const StyledFilterButton = styled(FilterButton)`
  span {
    font-family: ${CSS_VARS.fontFamily};
  }
`

/**
 * @param props The props object
 * @param props.activeFilters The active filters
 * @param props.currentFilter The current filter
 * @param props.filter The genders filter
 * @param props.selectedButtonStyles The stylings for buttons in the selected state
 * @returns The InsurerFilter component
 */
export const InsurerFilter: FC<{
  activeFilters: ActiveFilter
  currentFilter: FilterKey
  filter: InsurersFilter
  selectedButtonStyles: (val?: boolean) => CSSProperties
}> = ({ activeFilters, currentFilter, filter, selectedButtonStyles }) => {
  const { filterObject, setFilterObject } = useServicesContext()

  if (!(activeFilters[currentFilter] && currentFilter === 'insurers')) {
    return null
  }

  return (
    <Container className="descriptionFadeIn">
      {filter.values.map((insurer) => (
        <StyledFilterButton
          key={insurer.value}
          type="primary"
          style={selectedButtonStyles(filterObject?.[currentFilter]?.includes(insurer.value))}
          onClick={() => {
            if (filterObject?.insurers?.includes(insurer.value)) {
              setFilterObject({
                ...filterObject,
                insurers: filterObject?.insurers?.filter((item) => item !== insurer.value),
              })
            } else {
              setFilterObject({
                ...filterObject,
                insurers: [...(filterObject.insurers || []), insurer.value],
              })
            }
          }}
        >
          {insurer.text}
        </StyledFilterButton>
      ))}
    </Container>
  )
}
