import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Col } from 'antd'
import { getTempTranslation } from '../../utils/getTempTranslation'
import { WrapperWithTitleAndAnimation } from '../../../../components/WrapperWithTitleAndAnimation'
import { Label, NumberInput, StyledRow } from './BMI.styled'

interface Props {
  isLoading: boolean
  title: string
  onCalculation: (calculatedBMI: string[] | null) => void
}

export const BMI: FC<Props> = (props: Props) => {
  const { isLoading, title, onCalculation } = props
  const [weight, setWeight] = useState<number | undefined>()
  const [height, setHeight] = useState<number | undefined>()

  const calculatedBMI = useMemo(() => {
    if (height === undefined || weight === undefined) {
      return null
    }
    if (height < 90 || weight < 10) {
      return null
    }
    return (weight / Math.pow(height / 100, 2)).toFixed(1)
  }, [height, weight])

  const handleHeightChange = useCallback(
    (value: string) => {
      const transformedHeight = Number(value)
      if (transformedHeight > 300) {
        setHeight(300)
      } else {
        setHeight(transformedHeight)
      }
    },
    [setHeight],
  )

  const handleWeightChange = useCallback(
    (value: string) => {
      const transformedWeight = Number(value)
      if (transformedWeight > 500) {
        setWeight(500)
      } else {
        setWeight(transformedWeight)
      }
    },
    [setWeight],
  )

  useEffect(() => {
    if (height && weight && calculatedBMI) {
      onCalculation([height.toString(), weight.toString(), calculatedBMI])
    } else {
      onCalculation(null)
    }
  }, [height, weight, calculatedBMI, onCalculation])

  return (
    <WrapperWithTitleAndAnimation isAnimated={isLoading} title={title}>
      <StyledRow style={{ marginBottom: 16 }}>
        <Col span={24}>
          <Label>{getTempTranslation('components.bmi.height.title')}</Label>
          <NumberInput
            data-testid="heightInput"
            type="number"
            placeholder={getTempTranslation('components.bmi.height.placeholder')}
            onChange={(e) => handleHeightChange(e.target.value)}
            size="large"
            value={height}
          />
        </Col>
      </StyledRow>
      <StyledRow style={{ marginBottom: 16 }}>
        <Col span={24}>
          <Label>{getTempTranslation('components.bmi.weight.title')}</Label>
          <NumberInput
            data-testid="weightInput"
            type="number"
            placeholder={getTempTranslation('components.bmi.weight.placeholder')}
            onChange={(e) => handleWeightChange(e.target.value)}
            size="large"
            value={weight}
          />
        </Col>
      </StyledRow>
      <StyledRow>{calculatedBMI && <Label>{`Your BMI is: ${calculatedBMI}`}</Label>}</StyledRow>
    </WrapperWithTitleAndAnimation>
  )
}
