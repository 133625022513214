import { Report } from 'health-check-api'
import { PROFILE_RULE_GENDER_FEMALE } from 'health-check-types'

export const mockReportQuestion: Report = {
  profile: {
    age: 43,
    height: 175,
    weight: 67,
    gender: PROFILE_RULE_GENDER_FEMALE,
    bmi: 18,
  },
  riskTopics: [
    {
      id: 'riskTopic1',
      name: 'Cardiovascular diseases',
      description:
        'Cardiovascular diseases (CVDs) constitute a group of disorders that affect the heart and blood vessels and include conditions such as coronary heart disease, heart failure, stroke and high blood pressure. These diseases are a leading health problem worldwide and are responsible for a significant proportion of morbidity and mortality worldwide. Many factors contribute to the development of CVD, including unhealthy diet, lack of physical activity, smoking, excessive alcohol consumption and genetic predisposition. Obesity, diabetes and stress also play a role in increasing the risk of CVDs. Prevention and treatment strategies are essential. Lifestyle changes such as a balanced diet, regular exercise, smoking cessation and stress reduction can significantly reduce the risk of CVDs. Medications and medical interventions may be necessary for individuals with existing conditions. Furthermore, public health initiatives, early detection and better management of CVD risk factors are crucial to reducing the burden of these diseases.',
      score: Math.floor(Math.random() * (4 - 1 + 1) + 1),
      riskFactors: Array.from(Array(16)).map((_, index) => ({
        id: `riskFactor${index + 1}`,
        name: `Risk Factor #${index + 1}`,
      })),
    },
  ],
  recommendation: {
    protective: [
      { id: 'protective1', factor: 'Stress free life' },
      { id: 'protective2', factor: 'Staying active' },
      { id: 'protective3', factor: 'Non-smoker' },
    ],
    negative: [
      {
        id: 'negative1',
        factor: 'Blood lipids',
        title: 'Keep your blood lipids controlled',
        description:
          'Elevated levels of cholesterol and triglycerides promote atherosclerosis, causing plaque buildup and narrowing of arteries, leading to heart disease, heart attacks, strokes and peripheral artery disease as well. Adopt a balanced diet low in saturated and trans fats, be physically active and take medication if advised by your healthcare provider.',
      },
      {
        id: 'negative2',
        factor: 'High body mass / high waist circumference',
        title: 'Lose weight',
        description:
          'Your Body Mass Index and waist circumference are above the normal range. Overweight and obesity is associated with many health risks and complications, e.g. cardiovascular diseases, type 2 diabetes, cancers, gastrointestinal issues, reproductive disorders, mental health disorders and joint problems. Try to lose some weight mainly by being active and by focusing on your eating habits.',
      },
    ],
  },
}
