import React, { FC, Ref, useLayoutEffect, useRef, useState } from 'react'
import { Col, Collapse, Typography } from 'antd'
import { UpOutlined } from '@ant-design/icons'
import { Flex } from '../Flex'
import { getTempTranslation } from '../../utils/getTempTranslation'
import {
  BookingButton,
  ExaminationBackground,
  ExaminationPanel,
  ExaminationRow,
  RecommendationContainer,
  SectionTitle,
  StyledText,
} from './Report.styled'

interface Props {
  onBookingButtonClick: () => void
}

export const ExaminationSection: FC<Props> = (props) => {
  const { onBookingButtonClick } = props
  const ref: Ref<HTMLDivElement> = useRef(null)
  const [rowLeftBorder, setRowLeftBorder] = useState(0)

  const examinations = [
    {
      title: 'Abdominal ultrasound examination',
      description:
        'An abdominal ultrasound is a painless diagnostic procedure revealing real-time images of your internal organs. Using high-frequency sound waves and a transducer, it creates a visual narrative for healthcare professionals to interpret. These images assess the size, shape, and texture of organs and structures like the liver, gallbladder and abdominal aorta among others, thus it can be used to screen for abdominal aortic aneurysm.',
    },
    {
      title: 'Blood test',
      description:
        'A blood test is a straightforward diagnostic tool used to analyze various aspects of your health. A small blood sample is collected, typically from a vein in your arm, and then analyzed e.g. for the levels of different blood cells, cholesterol, glucose. Blood test results offer valuable insights to healthcare professionals. A blood test can be used to screen for hyperlipidemia and diabetes mellitus and uncovering these conditions in time can prevent their cardiovascular consequences.',
    },
  ]

  useLayoutEffect(() => {
    const rectangle = ref?.current?.getBoundingClientRect()
    setRowLeftBorder(rectangle?.left ?? 0)
  }, [])

  return (
    <ExaminationRow ref={ref} style={{ position: 'relative' }}>
      <ExaminationBackground left={rowLeftBorder} />
      <Col span={24}>
        <Col span={24}>
          <SectionTitle>General cardiovascular examinations</SectionTitle>
        </Col>
        <Col span={24}>
          <Typography.Text>The examinations here are general and are not based on your answers.</Typography.Text>
        </Col>
        <Col span={24}>
          <Collapse
            defaultActiveKey={['0']}
            ghost={true}
            expandIconPosition="end"
            expandIcon={({ isActive }) => <UpOutlined rotate={isActive ? 180 : 0} />}
            style={{ marginTop: 42 }}
          >
            {examinations.map((examination, index) => (
              <ExaminationPanel
                key={index}
                header={
                  <Flex alignItems="center" justifyContent="space-between" style={{ flexWrap: 'wrap' }}>
                    <StyledText color="#00417A" isBold={true}>
                      {examination.title}
                    </StyledText>
                    <div onClick={(event) => event.stopPropagation()}>
                      <BookingButton
                        onClick={(event) => {
                          onBookingButtonClick()
                        }}
                      >
                        Book your appointment
                      </BookingButton>
                    </div>
                  </Flex>
                }
              >
                <RecommendationContainer>
                  <Col span={24}>
                    <StyledText isBold={true}>{getTempTranslation('healthCheck.report.examination')}</StyledText>
                  </Col>
                  <Col span={24}>
                    <StyledText>{examination.description}</StyledText>
                  </Col>
                </RecommendationContainer>
              </ExaminationPanel>
            ))}
          </Collapse>
        </Col>
      </Col>
    </ExaminationRow>
  )
}
