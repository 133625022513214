import { useCallback, useMemo } from 'react'
import axios from 'axios'
import {
  initializeRoute,
  questionRoute,
  answerRoute,
  reportRoute,
  InitializeResponse,
  Question,
  Report,
} from 'health-check-api'
import { useLanguage } from '../../../hooks'
import { useAuthToken } from '../../../hooks/useAuthToken'

export type HealthCheckApi = {
  initialize: () => Promise<string>
  getQuestion: (checkId: string) => Promise<Question>
  setAnswer: (checkId: string, questionId: string, answer: string | null | string[]) => Promise<void>
  getReport: (checkId: string) => Promise<Report>
}

export const useApi = (): HealthCheckApi => {
  const { currentLanguage } = useLanguage()
  const token = useAuthToken()

  const baseApi = useMemo(() => {
    return axios.create({
      baseURL: window.xundEnvironment.HEALTH_CHECK_API_BASE_URL,
      timeout: 30000,
    })
  }, [])

  const initialize = useCallback(async (): Promise<string> => {
    const response = await baseApi.post<InitializeResponse>(
      initializeRoute.path,
      {},
      {
        headers: {
          Language: currentLanguage,
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return response.data.checkId
  }, [baseApi, token, currentLanguage])

  const getQuestion = useCallback(
    async (checkId: string): Promise<Question> => {
      const response = await baseApi.get<Question>(questionRoute.path, {
        headers: {
          Language: currentLanguage,
          Authorization: `Bearer ${token}`,
          'Check-Id': checkId,
        },
      })

      return response.data
    },
    [baseApi, token, currentLanguage],
  )

  const setAnswer = useCallback(
    async (checkId: string, questionId: string, answer: string | null | string[]) => {
      await baseApi.post(
        answerRoute.path,
        { questionId, answer },
        {
          headers: {
            Language: currentLanguage,
            Authorization: `Bearer ${token}`,
            'Check-Id': checkId,
          },
        },
      )
    },
    [baseApi, token, currentLanguage],
  )

  const getReport = useCallback(
    async (checkId: string): Promise<Report> => {
      const response = await baseApi.get<Report>(reportRoute.path, {
        headers: {
          Language: currentLanguage,
          Authorization: `Bearer ${token}`,
          'Check-Id': checkId,
        },
      })

      return response.data
    },
    [baseApi, token, currentLanguage],
  )

  return useMemo(
    (): HealthCheckApi => ({ initialize, getQuestion, setAnswer, getReport }),
    [initialize, getQuestion, setAnswer, getReport],
  )
}
