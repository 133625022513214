import React, { FC, useCallback } from 'react'
import { CheckCircleFilled } from '@ant-design/icons'
import { OptionItem } from '../../../models/api/response/OptionItem'
import { CustomIcon } from '../../CustomIcon/CustomIcon'
import { LoadingIndicator } from '../../LoadingIndicator/LoadingIndicator'
import { useI18N } from '../../../hooks'
import { CSS_VARS } from '../../../resources/cssVariableConfig'
import styles from './SymptomItem.module.less'

/**
 * @param props The props object
 * @param props.title The title to use
 * @param props.item The current symptom item
 * @param props.isLoading The boolean for whether the app is loading
 * @param props.onSelected The method handling selection
 * @param props.elementReference The reference object
 * @returns The SymptomItem component
 */
export const SymptomItem: FC<{
  title: string
  item: OptionItem
  isLoading: boolean
  onSelected: (id: string, isSelected: boolean) => void
  elementReference?: (node: HTMLDivElement | null) => void
}> = ({ title, item, isLoading, onSelected, elementReference }) => {
  const { i18n } = useI18N()

  /**
   * @param condition The boolean determining the icon
   * @returns The appropriate icon
   */
  const determineIcon = useCallback(
    (condition: boolean) => {
      if (isLoading) {
        return <LoadingIndicator />
      }

      const iconStyling = {
        fontSize: 20,
        marginBottom: 2,
        marginRight: 2,
      }

      if (condition) {
        return <CheckCircleFilled style={{ ...iconStyling, color: CSS_VARS.GREEN_COLOR }} />
      } else {
        return (
          <CustomIcon
            title={i18n('general.add')}
            icon="addCircle"
            style={{ ...iconStyling, color: CSS_VARS.PRIMARY_COLOR }}
          />
        )
      }
    },
    [i18n, isLoading],
  )

  return (
    <div
      ref={elementReference}
      className={styles.symptomSuggestionItem}
      onClick={() => onSelected(item.id, item.isSelected)}
      style={{
        cursor: isLoading ? 'not-allowed' : 'pointer',
        pointerEvents: isLoading ? 'none' : 'initial',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <p className={styles.symptomItemTitle} style={{ color: CSS_VARS.chatFontColor }}>
          {title}
        </p>
        <p
          className={styles.symptomItemDescription}
          style={{ fontSize: CSS_VARS.chatFontSize, color: CSS_VARS.DARK_GREY_COLOR }}
        >
          {item.shortDescription}
        </p>
      </div>
      <div style={{ display: 'flex' }}>{determineIcon(item.isSelected)}</div>
    </div>
  )
}
