import { deepMerge } from '../utils/deepMerge'
import { SupportedLanguage } from '../models/CustomTranslation'
import en from './en.json'
import de from './de.json'
import deFormal from './de-formal.json'
import hu from './hu.json'
import ru from './ru.json'
import it from './it.json'
import fr from './fr.json'
import frFormal from './fr-formal.json'
import nl from './nl.json'
import nlFormal from './nl-formal.json'
import ro from './ro.json'

/**
 * @param baseValue The target translation code
 * @param args The arguments to insert
 * @returns The modified translation
 */
export const useReplacer = (baseValue: string, args: { [K: string]: string }) => {
  Object.keys(args).forEach((argKey: string) => {
    const value = args[argKey]
    const regexp = new RegExp(`{${argKey}}`)
    baseValue = baseValue.replace(regexp, value)
  })
  return baseValue
}

/**
 * @param key The key to use
 * @param obj The object to use
 * @param args The args object
 * @returns The value from the key
 */
const getFromKey = (key: string, obj: string, args?: { [K: string]: string }) => {
  const value = key.split('.').reduce((p, c) => (p && p[+c]) || `!!${key}!!`, obj)
  // This is not a hook
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return args ? useReplacer(value, args) : value
}

const enExpanded = {
  ...en,
  useReplacer,
  getFromKey,
}

export const withTypes: {
  [K in SupportedLanguage]: typeof en & {
    useReplacer: typeof useReplacer
    getFromKey: typeof getFromKey
  }
} = {
  en: enExpanded,
  de: deepMerge(enExpanded, de),
  'de-formal': deepMerge(enExpanded, deFormal),
  hu: deepMerge(enExpanded, hu),
  ru: deepMerge(enExpanded, ru),
  it: deepMerge(enExpanded, it),
  fr: deepMerge(enExpanded, fr),
  'fr-formal': deepMerge(enExpanded, frFormal),
  nl: deepMerge(enExpanded, nl),
  'it-formal': deepMerge(enExpanded, it),
  'nl-formal': deepMerge(enExpanded, nlFormal),
  ro: deepMerge(enExpanded, ro),
}
