export const getMockRiskTopics = () => [
  { id: '2', name: 'Pulmonary disease', description: '', isDisabled: true, score: 0, riskFactors: [] },
  { id: '3', name: 'Gastrointestinal diseases', description: '', isDisabled: true, score: 0, riskFactors: [] },
  { id: '4', name: 'Dermatologic diseases', description: '', isDisabled: true, score: 0, riskFactors: [] },
  { id: '5', name: 'Orthopedic diseases', description: '', isDisabled: true, score: 0, riskFactors: [] },
  { id: '6', name: 'Women’s health', description: '', isDisabled: true, score: 0, riskFactors: [] },
  { id: '7', name: 'Tumours', description: '', isDisabled: true, score: 0, riskFactors: [] },
  {
    id: '8',
    name: 'Endoctrine, metabolic and nutritional conditions',
    description: '',
    isDisabled: true,
    score: 0,
    riskFactors: [],
  },
  { id: '9', name: 'Hematologic diseases', description: '', isDisabled: true, score: 0, riskFactors: [] },
  { id: '10', name: 'Mental health', description: '', isDisabled: true, score: 0, riskFactors: [] },
  { id: '11', name: 'Neurologic diseases', description: '', isDisabled: true, score: 0, riskFactors: [] },
  { id: '12', name: 'Ophthalmology', description: '', isDisabled: true, score: 0, riskFactors: [] },
  { id: '13', name: 'Otology', description: '', isDisabled: true, score: 0, riskFactors: [] },
]
