import React, { FC } from 'react'
import { getTempTranslation } from '../utils/getTempTranslation'
import { SelectInput } from './SelectInput'

interface Props {
  title: string
  isLoading: boolean
  onSelect: (answer: string) => void
}

export const AgeSelect: FC<Props> = (props) => {
  const { title, isLoading, onSelect } = props

  const MINIMUM_AGE = 14
  const options = Array.from({ length: 126 - MINIMUM_AGE }, (value, index) => index).map((age) => ({
    label: (age + MINIMUM_AGE).toString(),
    value: (age + MINIMUM_AGE).toString(),
  }))

  return (
    <SelectInput
      options={options}
      placeholder={getTempTranslation('components.age.placeholder')}
      title={title}
      isLoading={isLoading}
      onSelect={onSelect}
    />
  )
}
