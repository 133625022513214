import { MedicalEngineIllnessCheckReport, MedicalEngineSymptomCheckReport } from 'medical-engine-api'
import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { useApiGatewayContext } from '../../../context'
import { useQuestionContext } from '../../../context/QuestionContext'
import { ChatbotQuestionAnswer, OverviewResponse, Question, ResponseType } from '../../../models'
import { AbdominalZoomQuestion } from '../../AbdominalZoomQuestion/AbdominalZoomQuestion'
import { BodyPartSelector } from '../../BodyPartSelector/BodyPartSelector'
import { CheckFinished } from '../../CheckFinished/CheckFinished'
import { Emergency } from '../../Emergency/Emergency'
import { LegalDisclaimer } from '../../LegalDisclaimer/LegalDisclaimer'
import { LoadingIndicator } from '../../LoadingIndicator/LoadingIndicator'
import { Overview } from '../../Overview/Overview'
import { Progress } from '../../Progress/Progress'
import { Report } from '../../Report/Report'
import { fetchVirtualUrlServices } from '../../Report/VirtualUrlServices'
import { AgeSelect } from '../../SelectInput/AgeSelect/AgeSelect'
import { SingleSelect } from '../../SingleSelect/SingleSelect'
import { SliderInput } from '../../SliderInput/SliderInput'
import { SymptomAndIllnessSelector } from '../../SymptomAndIllnessSelector/SymptomAndIllnessSelector'
import { SymptomSuggestionSelector } from '../../SymptomSuggestionSelector/SymptomSuggestionSelector'

/**
 * This component decides which component to display
 *
 * @param props The props object
 * @param props.currentResponse The current API Gateway response
 * @param props.chatbotQuestionData The pool of responses from API Gateway
 * @param props.isLoading The boolean for whether the app is loading
 * @param props.startCheck The method for starting a check
 * @param props.stepBackInTheFlow The method for stepping back in the flow of the check
 * @param props.sendAnswer The method that sends the answer
 * @param props.setChatbotQuestionData The setter for the pool of responses from API Gateway
 * @param props.setIsOverview The setter for the boolean that decides if the state of the app is Overview
 * @param props.location Geolocation of the user (if not given, virtual url services won't be displayed)
 * @returns The QuestionHandler component
 */
export const QuestionHandler: FC<{
  currentResponse: ResponseType
  chatbotQuestionData: ResponseType[]
  isLoading: boolean
  startCheck: () => void
  stepBackInTheFlow: () => void
  sendAnswer: (answer: ChatbotQuestionAnswer, isInfoQuestion?: boolean | undefined) => void
  setChatbotQuestionData: (val: ResponseType[]) => void
  setIsOverview: (val: boolean) => void
  location?: GeolocationPosition | null
}> = ({
  currentResponse,
  chatbotQuestionData,
  isLoading,
  startCheck,
  stepBackInTheFlow,
  sendAnswer,
  setChatbotQuestionData,
  setIsOverview,
  location,
}) => {
  type Components = {
    [key: string]: JSX.Element
    CHECK_FINISHED: JSX.Element
    TERMS_AND_CONDITIONS: JSX.Element
    AGE: JSX.Element
    TEMPERATURE: JSX.Element
    SCALE: JSX.Element
    BODY_PART_SELECTOR: JSX.Element
    SYMPTOM_SELECTOR: JSX.Element
    SYMPTOM_SUGGESTION: JSX.Element
    ILLNESS_SELECTOR: JSX.Element
    ABDOMINAL_ZOOM: JSX.Element
    SINGLE_SELECT: JSX.Element
    SYMPTOM_CHECK_REPORT: JSX.Element
    ILLNESS_CHECK_REPORT: JSX.Element
    OVERVIEW: JSX.Element
  }

  const isSoteMode = window.xundEnvironment.IS_SOTE_MODE === 'true'

  const { apiGateway } = useApiGatewayContext()

  const {
    setHideSkipButton,
    setNextButtonLogic,
    nextButtonLogic,
    isCheckFinished,
    setIsCheckFinished,
    isReporting,
    checkType,
  } = useQuestionContext()

  const currentResponseDetailedType = useMemo(() => (currentResponse as Question)?.detailedType, [currentResponse])

  const isSoteFinished = useMemo(
    () =>
      isSoteMode && (currentResponse as Question)?.checkStateInfo?.detailedState === 'SYMPTOM_CHECK_LEGAL_DISCLAIMER',
    [currentResponse, isSoteMode],
  )

  const components: Components = useMemo(
    () => ({
      CHECK_FINISHED: (
        <CheckFinished
          question={currentResponse as Question}
          animationStartsWhen={!isLoading}
          setIsCheckFinished={setIsCheckFinished}
        />
      ),
      TERMS_AND_CONDITIONS: (
        <LegalDisclaimer
          animationStartsWhen={currentResponseDetailedType === 'TERMS_AND_CONDITIONS'}
          startCheck={startCheck}
          cancelCheck={stepBackInTheFlow}
        />
      ),
      AGE: (
        <AgeSelect
          question={currentResponse as Question}
          animationStartsWhen={!isLoading}
          sendAnswer={sendAnswer}
          isLoading={isLoading}
        />
      ),
      TEMPERATURE: (
        <SliderInput
          question={currentResponse as Question}
          animationStartsWhen={!isLoading}
          sendAnswer={sendAnswer}
          isLoading={isLoading}
        />
      ),
      SCALE: (
        <SliderInput
          question={currentResponse as Question}
          animationStartsWhen={!isLoading}
          sendAnswer={sendAnswer}
          isLoading={isLoading}
        />
      ),
      BODY_PART_SELECTOR: (
        <BodyPartSelector
          question={currentResponse as Question}
          animationStartsWhen={!isLoading}
          sendAnswer={sendAnswer}
          isLoading={isLoading}
        />
      ),
      ABDOMINAL_ZOOM: (
        <AbdominalZoomQuestion
          question={currentResponse as Question}
          animationStartsWhen={!isLoading}
          sendAnswer={sendAnswer}
        />
      ),
      SYMPTOM_SELECTOR: (
        <SymptomAndIllnessSelector
          question={currentResponse as Question}
          sendAnswer={sendAnswer}
          isLoading={isLoading}
        />
      ),
      SYMPTOM_SUGGESTION: (
        <SymptomSuggestionSelector
          question={currentResponse as Question}
          animationStartsWhen={!isLoading}
          sendAnswer={sendAnswer}
        />
      ),
      ILLNESS_SELECTOR: (
        <SymptomAndIllnessSelector
          question={currentResponse as Question}
          sendAnswer={sendAnswer}
          isLoading={isLoading}
        />
      ),
      OVERVIEW: (
        <Overview
          question={currentResponse as OverviewResponse}
          animationStartsWhen={!isLoading}
          chatbotQuestionData={chatbotQuestionData}
          setChatbotQuestionData={setChatbotQuestionData}
          setIsOverview={setIsOverview}
        />
      ),
      SYMPTOM_CHECK_REPORT: (
        <Report
          reportResponse={currentResponse as MedicalEngineSymptomCheckReport}
          animationStartsWhen={!isLoading}
          fetchVirtualUrlServices={fetchVirtualUrlServices(apiGateway)}
          location={location}
        />
      ),
      ILLNESS_CHECK_REPORT: (
        <Report
          reportResponse={currentResponse as MedicalEngineIllnessCheckReport}
          animationStartsWhen={!isLoading}
          fetchVirtualUrlServices={fetchVirtualUrlServices(apiGateway)}
          location={location}
        />
      ),
      SINGLE_SELECT: (
        <SingleSelect question={currentResponse as Question} sendAnswer={sendAnswer} isLoading={isLoading} />
      ),
      PROGRESS: <Progress question={currentResponse as Question} isLoading={isLoading} sendAnswer={sendAnswer} />,
      EMERGENCY: <Emergency question={currentResponse as Question} sendAnswer={sendAnswer} isLoading={isLoading} />,
    }),
    [
      apiGateway,
      chatbotQuestionData,
      currentResponse,
      currentResponseDetailedType,
      isLoading,
      location,
      sendAnswer,
      setChatbotQuestionData,
      setIsCheckFinished,
      setIsOverview,
      startCheck,
      stepBackInTheFlow,
    ],
  )

  /**
   * This method chooses a property with which to index the components object
   *
   * @returns The index
   */
  const componentIndex = useCallback(() => {
    let returnValue: keyof Components = currentResponseDetailedType

    if (returnValue in components) {
      return returnValue
    } else if (isCheckFinished || isSoteFinished) {
      returnValue = 'CHECK_FINISHED'
    } else if ((currentResponse as OverviewResponse)?.addedSymptoms && !isReporting) {
      returnValue = 'OVERVIEW'
    } else if (checkType === 'SYMPTOM_CHECK' && isReporting) {
      returnValue = 'SYMPTOM_CHECK_REPORT'
    } else if (checkType === 'ILLNESS_CHECK' && isReporting) {
      returnValue = 'ILLNESS_CHECK_REPORT'
    } else {
      returnValue = 'SINGLE_SELECT'
    }

    return returnValue
  }, [
    checkType,
    components,
    currentResponse,
    currentResponseDetailedType,
    isCheckFinished,
    isReporting,
    isSoteFinished,
  ])

  const sendGeneralAnswer = useCallback(
    () => sendAnswer({ questionId: (currentResponse as Question).id }),
    [currentResponse, sendAnswer],
  )

  useEffect(() => {
    if (
      [
        'SYMPTOM_SUGGESTION_INFO',
        'MAIN_SYMPTOM_INFO',
        'MAIN_SYMPTOM_SURVEY_INFO',
        'SYMPTOM_CHECK_ILLNESS_SURVEY_INFO',
        'ILLNESS_CHECK_ILLNESS_SURVEY_INFO',
        'PRIMARY_SYMPTOMS_SURVEY_INFO',
        'SECONDARY_SYMPTOMS_SURVEY_INFO',
      ].includes((currentResponse as Question)?.checkStateInfo?.detailedState ?? '')
    ) {
      setHideSkipButton(true)
      setNextButtonLogic(() => sendGeneralAnswer)
    } else if (
      !['SYMPTOM_SUGGESTION', 'TEMPERATURE', 'AGE', 'SCALE', 'ABDOMINAL_ZOOM'].includes(
        (currentResponse as Question)?.detailedType,
      ) &&
      !(currentResponse as OverviewResponse)?.addedSymptoms
    ) {
      setHideSkipButton(false)
      setNextButtonLogic(null)
    }
  }, [
    currentResponse,
    isReporting,
    nextButtonLogic,
    sendAnswer,
    sendGeneralAnswer,
    setHideSkipButton,
    setNextButtonLogic,
  ])

  if (currentResponse) {
    return components[componentIndex()]
  } else {
    return <LoadingIndicator />
  }
}
