import styled from '@emotion/styled'
import { Button } from 'antd'
import { css } from '@emotion/react'
import { CSS_VARS, JS_STYLING } from '../../../../resources/cssVariableConfig'

const buttonCommon = css`
  border-radius: 13px !important;
  font-weight: normal;
  margin: 10px 0;
  min-width: 110px;

  &.ant-btn-primary {
    box-shadow: none;
  }

  span {
    font-family: ${CSS_VARS.fontFamily};
  }
`

export const ResetFilterButton = styled(Button)`
  ${buttonCommon}

  :hover, :active {
    background-color: #ebf3fa !important;
    border-color: #ebf3fa !important;
    color: ${CSS_VARS.sideMenuBackgroundColor} !important;
  }
`

export const ApplyFilterButton = styled(Button)`
  ${buttonCommon}

  background-color: ${CSS_VARS.sideMenuBackgroundColor} !important;
`

export const Container = styled.div`
  overflow: scroll;
  padding: 0 20px;

  @media screen and (max-width: ${JS_STYLING.MOBILE_MAX_WIDTH}) {
    height: 100%;
  }
`

export const FeaturesContainer = styled.div`
  @media screen and (max-width: ${JS_STYLING.MOBILE_MAX_WIDTH}) {
    overflow: scroll;
    height: calc(100% - 113px - 52px - 14px);
  }
`

export const BottomRowContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const LeftCol = styled.div`
  @media screen and (max-width: ${JS_STYLING.MOBILE_MAX_WIDTH}) {
    position: absolute;
    bottom: 128px;
  }
`

export const RightCol = styled.div`
  @media screen and (max-width: ${JS_STYLING.MOBILE_MAX_WIDTH}) {
    position: absolute;
    bottom: 113px;
    right: 17px;
  }
`

export const Filters = styled.div<{ shouldStartLower?: boolean }>(
  `
  width: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;

  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 0.7s;
  animation-delay: 0;
  animation-fill-mode: forwards;

  @media screen and (max-width: ${JS_STYLING.MOBILE_MAX_WIDTH}) {
    min-height: 50%;
    width: 100% !important;
    position: absolute;
    z-index: 300;
    top: 27px;
    height: 100%;
    overflow: hidden;
  }

  @keyframes moveIn {
    0% {
      opacity: 0;
      transform: translateY(-500px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes mobileMoveIn {
    0% {
      transform: translateY(500px);
    }
    100% {
      transform: translateY(0);
    }
  }

  animation-name: moveIn;

  @media screen and (max-width: ${JS_STYLING.MOBILE_MAX_WIDTH}) {
    animation-name: mobileMoveIn;
  }
  `,
  (props) =>
    props.shouldStartLower &&
    `
    @media screen and (max-width: ${JS_STYLING.MOBILE_MAX_WIDTH}) {
      top: 74px;
    }
    `,
)

export const BackIcon = styled.div`
  cursor: pointer;

  svg {
    height: 15px;
    width: 15px;
    stroke: ${CSS_VARS.sideMenuBackgroundColor} !important;
    fill: ${CSS_VARS.sideMenuBackgroundColor} !important;
    transform: rotate(90deg);
  }
`
