import { Question, QUESTION_STATE_REPORT, QUESTION_TYPE_CONFIRM } from 'health-check-api'

export const mockConfirmQuestion: Question = {
  id: 'confirmQuestion',
  text: 'Your health check is ready!',
  type: QUESTION_TYPE_CONFIRM,
  state: QUESTION_STATE_REPORT,
  answers: [],
  params: null,
}
