import { useCallback } from 'react'
import { useLocalizationContext } from '../context/LocalizationContext'
import { useWebAppConfigContext } from '../context/WebAppConfigContext'
import { withTypes } from '../i18n'
import { CustomTranslation } from '../models/CustomTranslation'
import { TranslationCodeLiteral } from '../models/TranslationCodeLiteral'
import { WebAppConfig } from '../models/WebAppConfig'
import { defaultWebAppConfig } from '../resources/defaultWebAppConfig'
import { useLanguage } from './useLanguage'

/**
 * @returns A translation collection
 */
export const useI18N = () => {
  const { currentLanguage } = useLanguage()
  const { translations } = useLocalizationContext()
  const { webAppConfig } = useWebAppConfigContext()

  /**
   * Checks if a translation with the given code exists and returns it
   *
   * @param code The translation code to find
   * @param arg The optional argument when using data in a translation
   * @returns The translation that matches the provided translation code
   */
  const getTranslation = useCallback(
    (code: TranslationCodeLiteral, arg?: string) => {
      const text = translations?.find((translation) => translation.code === `xund.${code}`)?.text

      if (arg) {
        return text?.replace(/\{(?:(?!}}).)*}/g, arg)
      } else {
        return text
      }
    },
    [translations],
  )

  /**
   * Gets a translation with the given code or returns the not found translation string if the translation does not exist
   *
   * @param code The translation code to use
   * @param arg The optional argument when using data in a translation
   * @returns The translation that matches the provided translation code or a string that shows the not found translation
   */
  const i18n = useCallback(
    (code: TranslationCodeLiteral, arg?: string) => getTranslation(code, arg) || `!!! ${code} !!!`,
    [getTranslation],
  )

  /**
   * Gets a custom translation with the given code or returns the fallback translation if the custom
   * translation does not exist
   *
   * @param fallbackTranslationCode The fallback translation code to use
   * @param customTranslation The custom translation to use
   * @returns The custom translation or a fallback if it was not found
   */
  const getCustomTranslationWithFallback = (
    fallbackTranslationCode: TranslationCodeLiteral,
    customTranslation?: CustomTranslation | null,
  ) => customTranslation?.[currentLanguage] ?? i18n(fallbackTranslationCode)

  /**
   * Gets a custom translation from the Web App config or from the default Web App config if the first one does not exist
   *
   * @param customTranslationPropertyName The custom translation property name
   * @returns The custom translation or a fallback if it was not found
   */
  const getCustomTranslationWithLocalFallback = (
    customTranslationPropertyName: keyof Pick<
      WebAppConfig,
      'welcomeMessage' | 'welcomeGreeting' | 'endCheckMessage' | 'termsAndConditionsExplanatoryText'
    >,
  ) =>
    webAppConfig?.[customTranslationPropertyName]?.[currentLanguage] ||
    defaultWebAppConfig?.[customTranslationPropertyName]?.[currentLanguage] ||
    `!!! ${customTranslationPropertyName} !!!`

  return {
    i18nFromLocallyProvidedJSON: withTypes[currentLanguage],
    i18n,
    getCustomTranslationWithFallback,
    getCustomTranslationWithLocalFallback,
  }
}
