import React, { FC, useMemo, useState } from 'react'
import { Card } from 'antd'
import Meta from 'antd/lib/card/Meta'
import { useBreakpoints, useI18N } from '../../../../hooks'
import { OptionItem } from '../../../../models/api/response/OptionItem'
import { ChatbotQuestionAnswer } from '../../../../models'
import { CSS_VARS } from '../../../../resources/cssVariableConfig'
import styles from './ListItem.module.less'

const maximumListItemDescriptionLengthInMobileView = 60

const maximumIllnessListItemDescriptionLengthInDesktopView = 220

const frequentMarginBottom = 10

/**
 * @param props The props object
 * @param props.item The current list item
 * @param props.questionId The ID of the current question
 * @param props.isFrequent Whether the current item is frequent
 * @param props.sendAnswer The method that sends the answer
 * @param props.elementReference The reference object
 * @returns The ListItem component
 */
export const ListItem: FC<{
  item: OptionItem
  questionId: string
  isFrequent?: boolean
  sendAnswer: (val: ChatbotQuestionAnswer) => void
  elementReference?: (node: HTMLDivElement | null) => void
}> = ({ item, questionId, isFrequent, sendAnswer, elementReference }) => {
  const [extendedItem, setExtendedItem] = useState('')

  const { i18n } = useI18N()

  const fontColor = useMemo(() => CSS_VARS.chatFontColor, [])

  const isSymptomCheck = useMemo(() => item.type === 'SYMPTOM', [item.type])

  const { isScreenSizeXS } = useBreakpoints()

  const computedIllnessListItemDescriptionLength = useMemo(
    () =>
      isScreenSizeXS
        ? maximumListItemDescriptionLengthInMobileView
        : maximumIllnessListItemDescriptionLengthInDesktopView,
    [isScreenSizeXS],
  )

  const dynamicMarginBottom = useMemo(() => (isFrequent ? frequentMarginBottom : 0), [isFrequent])

  const description = useMemo(
    () =>
      extendedItem === item.id ? (
        <div style={{ color: fontColor, fontSize: CSS_VARS.chatFontSize }}>
          <p style={{ color: CSS_VARS.DARK_GREY_COLOR }}>
            {item.description}
            <a
              style={{
                color: CSS_VARS.PRIMARY_COLOR,
                marginBottom: dynamicMarginBottom,
                marginLeft: 6,
              }}
              onClick={(event) => {
                event.preventDefault()
                event.stopPropagation()
                setExtendedItem('')
              }}
            >
              {i18n('general.showLess')}
            </a>
          </p>
        </div>
      ) : (
        <div style={{ color: fontColor, fontSize: CSS_VARS.chatFontSize }}>
          <p style={{ color: CSS_VARS.DARK_GREY_COLOR }}>
            {item.description?.slice(0, computedIllnessListItemDescriptionLength)}...{' '}
            <a
              style={{ color: CSS_VARS.PRIMARY_COLOR, marginBottom: dynamicMarginBottom }}
              onClick={(event) => {
                event.preventDefault()
                event.stopPropagation()
                setExtendedItem(item.id)
              }}
            >
              {i18n('general.showMore')}
            </a>
          </p>
        </div>
      ),
    [
      computedIllnessListItemDescriptionLength,
      dynamicMarginBottom,
      extendedItem,
      fontColor,
      i18n,
      item.description,
      item.id,
    ],
  )

  const illnessCardContent = useMemo(
    () => (
      <Meta
        style={{ color: fontColor, padding: 0 }}
        title={
          <p className={styles.listText} style={{ color: fontColor }}>
            {item.text}
          </p>
        }
        description={description}
      />
    ),
    [item.text, description, fontColor],
  )

  const symptomCardContent = useMemo(
    () => (
      <Meta
        style={{ color: fontColor, padding: 0 }}
        title={
          <p className={styles.listText} style={{ color: fontColor }}>
            {item.text}
          </p>
        }
        description={item.shortDescription}
      />
    ),
    [fontColor, item.shortDescription, item.text],
  )

  return (
    <Card
      ref={elementReference}
      data-testid="listItem"
      style={{
        color: fontColor,
        marginBottom: '5px',
        cursor: 'pointer',
      }}
      onClick={() => sendAnswer({ questionId, answer: { id: item.id } })}
      bordered={false}
    >
      {isSymptomCheck ? symptomCardContent : illnessCardContent}
    </Card>
  )
}
