import React, { FC } from 'react'
import { Row } from 'antd'
import { Report } from 'health-check-api'
import { getTempTranslation } from '../../utils/getTempTranslation'
import { capitalizeString } from '../../../../utils/capitalizeString'
import { ProfileDataBox } from './ProfileDataBox'
import { BMIDataBox } from './BMIDataBox'

interface Props {
  report: Report
}

export const ProfileSection: FC<Props> = (props) => {
  const { report } = props

  return (
    <Row gutter={[32, 32]} justify="center">
      <ProfileDataBox
        title={getTempTranslation('healthCheck.report.profile.sex')}
        value={capitalizeString(report.profile.gender.toLowerCase())}
      />
      <ProfileDataBox
        title={getTempTranslation('healthCheck.report.profile.age')}
        value={report.profile.age.toString()}
      />
      <ProfileDataBox
        title={getTempTranslation('healthCheck.report.profile.height')}
        value={`${report.profile.height}cm`}
      />
      <ProfileDataBox
        title={getTempTranslation('healthCheck.report.profile.weight')}
        value={`${report.profile.weight}kg`}
      />
      <BMIDataBox value={report.profile.bmi} />
    </Row>
  )
}
