import { QUESTION_STATE_PROFILE, QUESTION_TYPE_AGE, Question } from 'health-check-api'

export const mockAgeSelectQuestion: Question = {
  id: 'ageId',
  text: 'In which year were you born?',
  type: QUESTION_TYPE_AGE,
  state: QUESTION_STATE_PROFILE,
  answers: [],
  params: null,
}
