import { createContext, useContext } from 'react'
import { CheckType, ResponseType, TranslationCodeLiteral } from '../models'

const initialValue: {
  hideSkipButton: boolean
  setHideSkipButton: (val: boolean) => void
  currentResponse: ResponseType | null
  setCurrentResponse: (val: ResponseType | null) => void
  nextButtonLogic: (() => void) | null
  setNextButtonLogic: (val: (() => void) | null) => void
  nextButtonI18nKey: TranslationCodeLiteral | ''
  setNextButtonI18nKey: (val: TranslationCodeLiteral | '') => void
  isLoading: boolean
  setIsLoading: (val: boolean) => void
  isNextButtonDisabled: boolean
  setIsNextButtonDisabled: (val: boolean) => void
  isReporting: boolean
  setIsReporting: (val: boolean) => void
  isCheckFinished: boolean
  setIsCheckFinished: (val: boolean) => void
  checkType: CheckType
  setCheckType: (val: CheckType) => void
  //TODO move to new context during style/theme refactors
  mainContainerWidth: number
  setMainContainerWidth: (val: number) => void
} = {
  hideSkipButton: false,
  setHideSkipButton: () => null,
  currentResponse: null,
  setCurrentResponse: () => null,
  nextButtonLogic: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setNextButtonLogic: () => {},
  nextButtonI18nKey: '',
  setNextButtonI18nKey: () => null,
  isLoading: false,
  setIsLoading: () => null,
  isNextButtonDisabled: false,
  setIsNextButtonDisabled: () => null,
  isReporting: false,
  setIsReporting: () => null,
  isCheckFinished: false,
  setIsCheckFinished: () => null,
  checkType: '',
  setCheckType: () => null,
  mainContainerWidth: 0,
  setMainContainerWidth: () => 0,
}

export const QuestionContext = createContext(initialValue)

/**
 * The custom hook for the Question context
 *
 * @returns The custom hook for the Question context
 */
export const useQuestionContext = () => useContext(QuestionContext)
