import React, { FC } from 'react'
import { BasicAnswer } from 'health-check-api'
import { getTempTranslation } from '../utils/getTempTranslation'
import { SelectInput } from './SelectInput'

interface Props {
  title: string
  onDropdownSelect: (value: string) => void
  onSingleSelectClick: (value: string) => void
  answers: BasicAnswer[]
  isLoading: boolean
}

export const MultiSelect: FC<Props> = (props) => {
  const { title, onDropdownSelect, answers, isLoading } = props

  return (
    <SelectInput
      isMulti={true}
      options={answers.map((answer) => ({ label: answer.text, value: answer.id }))}
      placeholder={getTempTranslation('components.multiSelect.placeholder')}
      title={title}
      isLoading={isLoading}
      onSelect={onDropdownSelect}
    />
  )
}
