import React, { FC, useMemo } from 'react'
import { BasicAnswer } from 'health-check-api'
import { WrapperWithTitleAndAnimation } from '../../../../components/WrapperWithTitleAndAnimation'
import { getMockRiskTopics } from '../../mock'
import { RiskTopicSelectorContainer } from './RiskTopicSelector.styled'
import { RiskTopicList } from './RiskTopicList'

interface Props {
  title: string
  isLoading: boolean
  answers: BasicAnswer[]
  onSelect: (answer: string | null) => void
}

export const RiskTopicSelector: FC<Props> = (props) => {
  const { title, isLoading, answers, onSelect } = props
  const riskTopicViews = useMemo(
    () =>
      [
        ...answers.map((answer) => ({ id: answer.id, name: answer.text, isDisabled: false })),
        ...getMockRiskTopics(),
      ].map((riskTopic) => ({
        id: riskTopic.id,
        name: riskTopic.name,
        isDisabled: riskTopic.isDisabled ?? false,
        isSelected: false,
      })),
    [answers],
  )

  return (
    <div>
      {!isLoading && (
        <WrapperWithTitleAndAnimation
          title={title}
          isAnimated
          animationStartsWhen={!isLoading}
          style={{ height: 'fit-content' }}
          hasTopMargin={false}
        >
          <RiskTopicSelectorContainer>
            <RiskTopicList riskTopicViews={riskTopicViews} onSelect={onSelect} isLoading={isLoading} />
          </RiskTopicSelectorContainer>
        </WrapperWithTitleAndAnimation>
      )}
    </div>
  )
}
