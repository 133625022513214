import { useState } from 'react'
import { availableLanguages, ClientLanguage, finalizedClientLanguages } from 'xund-i18n'
import { useWebAppConfigContext } from '../context'
import { SupportedLanguage } from '../models'

/**
 * The hook responsible for the language of the application
 *
 * @returns The current language, the changeLanguage method, the supported languages
 */
export const useLanguage = () => {
  const { webAppConfig } = useWebAppConfigContext()

  const supportedLanguagesByCurrentEnvironment: readonly ClientLanguage[] =
    availableLanguages[window.xundEnvironment.EXEC_ENV] ?? finalizedClientLanguages

  /**
   * Filter the supported languages based on which languages are permitted for the current environment
   *
   * @param supportedLanguagesArray The languages supported by a Web App config
   * @returns The supported languages which are supported by both the environment and the Web App config
   */
  const getSupportedLanguagesCompatibleWithEnvironment = (supportedLanguagesArray: SupportedLanguage[]) =>
    supportedLanguagesArray.filter((language) => supportedLanguagesByCurrentEnvironment.includes(language))

  const supportedLanguagesInSoteMode = getSupportedLanguagesCompatibleWithEnvironment([
    'en',
    'de',
    'de-formal',
    'hu',
    'it',
    'it-formal',
    'ro',
  ])

  const supportedLanguagesByWebAppConfig = getSupportedLanguagesCompatibleWithEnvironment(
    webAppConfig.supportedLanguages,
  )

  const supportedLanguages: SupportedLanguage[] =
    window.xundEnvironment.IS_SOTE_MODE === 'true' ? supportedLanguagesInSoteMode : supportedLanguagesByWebAppConfig

  const browserLanguageLength = 2

  const userBrowserLanguage = navigator.language.slice(0, browserLanguageLength) as SupportedLanguage

  const xundWebAppLanguage =
    (window.appStorage.getItem('xundWebAppLanguage') as SupportedLanguage) || webAppConfig.defaultLanguage

  const languageChoice =
    !xundWebAppLanguage && supportedLanguages.includes(userBrowserLanguage) ? userBrowserLanguage : xundWebAppLanguage

  const [currentLanguage, setCurrentLanguage] = useState<SupportedLanguage>(languageChoice || 'en')

  /**
   * The method responsible for changing the language
   *
   * @param lang The language to change to
   */
  const changeLanguage = (lang: SupportedLanguage) => {
    window.appStorage.setItem('xundWebAppLanguage', lang)
    setCurrentLanguage(lang)
  }

  return { currentLanguage, changeLanguage, supportedLanguages }
}
