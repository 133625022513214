import React, { FC } from 'react'
import { Col, Row } from 'antd'
import { RiskTopic } from 'health-check-api'
import { getTempTranslation } from '../../utils/getTempTranslation'
import { RiskDescriptionContainer, RiskLevelContainer, SectionTitle, StyledRow, StyledText } from './Report.styled'
import { RiskLevelCircle, Score } from './RiskLevelCircle'

interface Props {
  riskTopic: RiskTopic
}

export const ScoreSection: FC<Props> = (props) => {
  const { riskTopic } = props

  return (
    <StyledRow gutter={[32, 32]} margin="54px 0 60px 0">
      <Col lg={8} md={24} xs={24}>
        <RiskLevelContainer>
          <Row style={{ alignItems: 'center', justifyContent: 'center', marginBottom: 35 }}>
            <SectionTitle>{getTempTranslation('healthCheck.report.riskLevel')}</SectionTitle>
          </Row>
          <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
            {/*TODO remove once API signature is updated*/}
            <RiskLevelCircle score={riskTopic.score as Score} />
          </Row>
        </RiskLevelContainer>
      </Col>
      <Col lg={16} md={24}>
        <RiskDescriptionContainer>
          <StyledRow margin="0 0 35px 0">
            <Col span={24}>
              <SectionTitle>{`What is ${riskTopic.name}?`}</SectionTitle>
            </Col>
          </StyledRow>
          <Row>
            <Col span={24}>
              <StyledText>{riskTopic.description}</StyledText>
            </Col>
          </Row>
        </RiskDescriptionContainer>
      </Col>
    </StyledRow>
  )
}
