import React, { FC, useMemo } from 'react'
import { useWindowSize } from 'usehooks-ts'
import { mobileMaxWidth } from '../../hooks'

/**
 * @param props The props object
 * @param props.isHidden The boolean for whether the overlay is visible
 * @param props.height The list item's height, given in pixels
 * @returns The OpaqueListOverlay component
 */
export const OpaqueListOverlay: FC<{
  isHidden: boolean
  height: number
}> = ({ isHidden, height }) => {
  const { width } = useWindowSize()

  //TODO move to custom hook?
  const isMobile = useMemo(() => width < mobileMaxWidth, [width])

  return (
    <div
      style={{
        position: 'absolute',
        bottom: 0,
        height: height * (isMobile ? 0.75 : 1),
        width: '100%',
        background: 'linear-gradient(to top, white, rgba(255, 255, 255, 0))',
        zIndex: 1,
        opacity: 0.9,
        pointerEvents: 'none',
        display: isHidden ? 'none' : 'initial',
      }}
    />
  )
}
