import React, { FC, useMemo } from 'react'
import { Col, Row } from 'antd'
import { getTempTranslation } from '../../../utils/getTempTranslation'
import { StyledText } from '../Report.styled'
import { GOOD_RISK_COLOR, HIGH_RISK_COLOR, MEDIUM_RISK_COLOR } from '../Report'
import { BarColoredSegment, BarContainer, BarPointer, BMINumber, MainContainer } from './BMIDataBox.styled'

interface Props {
  value: number
}
export const BMIDataBox: FC<Props> = (props: Props) => {
  const { value } = props
  const minBMI = 16
  const maxBMI = 40
  const range = maxBMI - minBMI
  const buffer = 0.15 //Used for better representation on the bar

  const validatedBMI = useMemo(() => {
    if (value <= minBMI) {
      return buffer
    }
    if (value >= maxBMI) {
      return range - buffer
    }
    return value - minBMI
  }, [value, range])

  const pinPosition: number = useMemo(() => {
    return (validatedBMI / range) * 100
  }, [range, validatedBMI])

  return (
    <Col xxl={{ span: 8 }} xl={{ span: 12 }} lg={{ span: 16 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
      <MainContainer>
        <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
          <StyledText>{getTempTranslation('healthCheck.report.profile.bmi')}</StyledText>
          <BMINumber>{value}</BMINumber>
        </Row>
        <BarContainer>
          <BarColoredSegment color={HIGH_RISK_COLOR} widthPercent={4.5} isFirst={true} />
          <BarColoredSegment color={MEDIUM_RISK_COLOR} widthPercent={5.5} />
          <BarColoredSegment color={GOOD_RISK_COLOR} widthPercent={27.5} />
          <BarColoredSegment color={MEDIUM_RISK_COLOR} widthPercent={21} />
          <BarColoredSegment color={HIGH_RISK_COLOR} widthPercent={41.5} isLast={true} />
          <BarPointer position={pinPosition} />
        </BarContainer>
      </MainContainer>
    </Col>
  )
}
