import { AxiosInstance } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { Translation, TranslationResponse } from '../models/Translation'
import { useLanguage } from './useLanguage'

/**
 * The hook responsible for fetching localizations
 *
 * @param apiGateway The Axios instance used to access the API Gateway
 * @returns The translations, whether localizations are loading and the potential error
 */
export const useLocalizations = (apiGateway: AxiosInstance | null) => {
  const [translations, setTranslations] = useState<Translation[] | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const { currentLanguage } = useLanguage()

  /**
   * Get the translations for the current language
   */
  const getTranslations = useCallback(async () => {
    if (!apiGateway) {
      return
    }
    try {
      setIsLoading(true)

      const { data } = await apiGateway.get<TranslationResponse>(`/v1/texts`, {
        headers: {
          language: currentLanguage,
        },
      })

      setTranslations(data.texts)
    } catch (err) {
      setError(err as Error)
    } finally {
      setIsLoading(false)
    }
  }, [currentLanguage, apiGateway])

  useEffect(() => {
    getTranslations()
  }, [getTranslations])

  return { translations, isLocalizationsLoading: isLoading, localizationsError: error }
}
