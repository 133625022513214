export const translationCodes = [
  'overview.notAddedSymptoms',
  'overview.noSymptomsAdded',
  'overview.editSymptomModalTitle',
  'general.save',
  'general.next',
  'general.showLess',
  'general.showMore',
  'general.add',
  'contentLibrary.title',
  'contentLibrary.illnessesAndSymptomsAZ',
  'contentLibrary.frequentlySearched',
  'contentLibrary.details.informationBy',
  'contentLibrary.details.outlook',
  'contentLibrary.details.quickSolutions',
  'contentLibrary.details.diagnosis',
  'contentLibrary.details.commonSymptoms',
  'contentLibrary.details.overview',
  'general.emergencyNumber',
  'general.startSymptomCheck',
  'general.startIllnessCheck',
  'general.skip',
  'general.rotate',
  'general.accept',
  'general.doNotAccept',
  'general.back',
  'general.backToHome',
  'general.search',
  'general.start',
  'general.profile',
  'general.symptom',
  'general.symptoms',
  'general.illnesses',
  'general.healthData',
  'general.report',
  'general.generalTerms',
  'general.noConnectionTitle',
  'general.noConnectionDescription',
  'general.commonIllnesses',
  'general.illnessesAZ',
  'general.commonSymptoms',
  'general.symptomsAZ',
  'general.select',
  'general.details',
  'general.done',
  'general.cancel',
  'general.checkWillBeLost',
  'general.okay',
  'general.yes',
  'general.no',
  'general.quickSolutions',
  'general.downloadReport',
  'general.information',
  'general.settings',
  'general.selectLanguage',
  'general.restart',
  'general.noneOfThese',
  'general.confirm',
  'general.restartWarning',
  'general.results',
  'general.possibleCauses',
  'general.restartCheck',
  'general.symptomQuestions',
  'general.important',
  'general.email',
  'general.phoneNumber',
  'general.endCheck',
  'general.modal.cancel',
  'general.modal.submit',
  'imprint.title',
  'imprint.contact',
  'imprint.medicalProductInfo',
  'imprint.medicalProductInfo.product_name',
  'imprint.medicalProductInfo.interface',
  'imprint.medicalProductInfo.UDI_DI',
  'imprint.medicalProductInfo.basic_UDI_DI',
  'imprint.medicalProductInfo.UDI_PI_software_version',
  'imprint.medicalProductInfo.UDI_PI_date_of_manufacture',
  'overview.title',
  'overview.showMoreSymptoms',
  'overview.noSymptomQuestions',
  'overview.symptomRemovalQuestion',
  'overview.noMoreSymptomsToAdd',
  'overview.addedSymptoms',
  'report.bottomBoxText',
  'report.emergency.description',
  'report.emergency.title',
  'report.loadLessProbableDiseases',
  'report.selectedSymptoms',
  'report.notSelectedSymptoms',
  'services.mapViewNotSupported',
  'services.closed',
  'services.closesAt',
  'services.open',
  'services.opensAt',
  'services.openingHours.header',
  'services.openingHours.noInfo',
  'services.title',
  'services.filters.matching',
  'services.filters.notMatching',
  'services.filters.title',
  'services.filters.reset',
  'services.filters.apply',
  'services.filters.noResults',
  'services.filters.searchLocation',
  'services.filters.findLocation',
  'services.filters.nearby',
  'services.mon',
  'services.tue',
  'services.wed',
  'services.thu',
  'services.fri',
  'services.sat',
  'services.sun',
  'services.specializations.doctors',
  'services.specializations.hospitals',
  'services.specializations.pharmacies',
  'services.specializations.all',
  'services.specializations.clearAll',
  'services.unsupportedRegion',
  'termsAndConditions.dataPrivacy',
  //'modal.homepage.title',
  'modal.restart.title',
  'modal.languageSelection.title',
  'menu.restart',
  'menu.home',
  'menu.medicalLibrary',
  'start.searchHeader',
  'start.symptomCheckHeader',
  'start.symptomCheckDescription',
  'start.startSymptomCheck',
  'start.illnessCheckHeader',
  'start.illnessCheckDescription',
  'start.startIllnessCheck',
  'start.commonSymptoms',
  'start.commonIllnesses',
] as const

export type TranslationCodeLiteral = (typeof translationCodes)[number]
