import axios from 'axios'

/**
 * The CMS axios instance factory
 *
 * @returns The axios instance to be used for the CMS
 */
export const useCMS = () => {
  const instance = axios.create({
    timeout: 60000,
  })

  instance.interceptors.request.use(
    async (config) => {
      config.headers.set('apiKey', window.xundEnvironment.CMS_API_KEY)
      /**
       * Specifically request CMS images in SVG format
       */
      config.headers.set('format', 'svg')
      config.responseType = 'blob'
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  return instance
}
