import styled from '@emotion/styled'
import { Collapse, Row, Typography } from 'antd'
import { CSS_VARS, JS_STYLING } from '../../../../resources/cssVariableConfig'
import { CustomIcon } from '../../../../components/CustomIcon/CustomIcon'

export const Card = styled.div<{ isHeightAdjusted?: boolean }>`
  min-height: ${(props) => (props.isHeightAdjusted ? 'initial' : '586px')};
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.05);
  margin-bottom: 14px;
  padding: 20px 30px;
`

export const LegendScoreDot = styled.div<{ color: string }>`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin-right: 11px;
`

export const SectionTitle = styled(Typography.Text)`
  font-size: 20px;
  font-weight: 700;
  font-family: ${CSS_VARS.fontFamily};
  color: ${CSS_VARS.DARK_BLUE_COLOR};
  @media (max-width: ${JS_STYLING.TABLET_MAX_WIDTH}) {
    font-size: 18px;
  }
`
export const SectionSubtitle = styled(Typography.Text)`
  font-size: 16px;
  font-weight: 700;
  font-family: ${CSS_VARS.fontFamily};
  color: ${CSS_VARS.DARK_BLUE_COLOR};
`

export const RiskFactorPill = styled.div`
  padding: 4px 14px;
  color: #00417a;
  border-radius: 17px;
  border: 0.5px solid #00417a;
  height: 27px;
  width: fit-content;
  margin-right: 5px;
  margin-bottom: 10px;
  font-family: ${CSS_VARS.fontFamily};
`

export const StyledText = styled(Typography.Text, {
  shouldForwardProp: (prop) => !['fontSize', 'isBold'].includes(prop),
})<{ fontSize?: number; isBold?: boolean; color?: string }>`
  font-family: ${CSS_VARS.fontFamily};
  color: ${(props) => props.color ?? CSS_VARS.chatFontColor};
  font-size: ${(props) => `${props.fontSize}px` ?? CSS_VARS.chatFontSize};
  font-weight: ${(props) => `${props.isBold ? 700 : 400}`};
`

export const RecommendationContainer = styled(Row)`
  margin-top: 10px;
  margin-bottom: 18px;
  padding-left: 15px;
  height: 100%;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 4px;
    height: 100%;
    background-color: #00417a;
    border-radius: 15px;
  }
`

export const RiskFactorCategoryButton = styled.button<{ fontWeight: number }>`
  color: #00417a;
  font-size: ${CSS_VARS.chatFontSize};
  font-weight: ${(props) => `${props.fontWeight}`};
  font-family: ${CSS_VARS.fontFamily} !important;
  border: unset;
  margin-right: 20px;
  background-color: unset;
  cursor: pointer;
  padding: 10px 0;
  display: inline-block;
  :hover {
    font-weight: bold;
  }
  :after {
    display: block;
    content: attr(data-text);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
  :disabled {
    color: #808080;
    :hover {
      font-weight: normal;
      cursor: not-allowed;
    }
  }
`

export const RiskLevelContainer = styled.div`
  border-radius: 15px;
  box-shadow: 0 16px 26px 0 rgba(0, 0, 0, 0.05);
  height: 100%;
  padding-bottom: 45px;
  padding-top: 20px;
`

export const RiskDescriptionContainer = styled.div`
  border-radius: 15px;
  box-shadow: 0 16px 26px 0 rgba(0, 0, 0, 0.05);
  height: 100%;
  padding: 20px 30px 45px 30px;
`

export const RecommendationPanel = styled(Collapse.Panel)`
  &&& {
    margin-bottom: 10px;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
  }
`

export const ExaminationPanel = styled(Collapse.Panel)`
  &&& {
    .ant-collapse-header {
      align-items: center;
    }
    margin-bottom: 10px;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    background-color: white;
  }
`

export const AssociatedRiskFactorContainer = styled.div`
  padding: 4px 12px;
  border: 0.5px solid black;
  border-radius: 17px;
  margin-left: 10px;
  margin-right: 18px;
`

export const PositiveRiskFactorContainer = styled.div`
  display: flex;
  background-color: rgba(0, 173, 239, 0.05);
  min-width: 100%;
  min-height: 93px;
  flex-wrap: wrap;
  padding: 27px 0 27px 10px;
  border-radius: 15px;
`

export const ReportContainer = styled.div`
  margin: 0 180px 0 180px;
`

export const StyledRow = styled(Row)<{ margin?: string }>`
  margin: ${(props) => props.margin ?? 'initial'};
`

export const RiskFactorTitleIcon = styled(CustomIcon)`
  margin-right: 10px;
`

export const BookingButton = styled.button`
  background-color: rgba(0, 101, 189, 0.5);
  color: white;
  border-radius: 5px;
  border: unset;
  padding: 6px 12px;
  cursor: pointer;
`
export const ExaminationBackground = styled.div<{ left: number }>`
  background-color: rgba(0, 101, 189, 0.05);
  height: 100%;
  left: -${(props) => props.left}px;
  position: absolute;
  top: 0;
  width: 100vw;
`
export const ExaminationRow = styled(Row)`
  margin: 80px 0 80px 0;
  padding: 40px 0 40px 0;
`

export const ComingSoonLine = styled.p`
  margin-bottom: 0;
`
