import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { AxiosError } from 'axios'
import { CustomIcon } from '../../../CustomIcon/CustomIcon'
import { useApiGatewayContext, useServicesContext } from '../../../../context'
import { LoadingIndicator } from '../../../LoadingIndicator/LoadingIndicator'
import { useFetchData, useI18N, useScreenSize } from '../../../../hooks'
import { Specialization, AutocompleteResult } from '../../../../models'
import { ActiveFilter } from '../ServicesMenu'
import { convertObjectToArray } from '../../../../utils'
import {
  FiltersResponse,
  FiltersWithKeysWithoutName,
  Filters as FiltersType,
} from '../../../../models/servicesFilters/filter'
import { CSS_VARS } from '../../../../resources/cssVariableConfig'
import { SpecializationsFilterDetailedView } from './SpecializationsFilter/SpecializationsFilterDetailedView/SpecializationsFilterDetailedView'
import { FilterHandler } from './FilterHandler/FilterHandler'
import { LocationsFilterDetailedView } from './LocationsFilter/LocationsFilterDetailedView/LocationsFilterDetailedView'
import {
  Container,
  Filters as FiltersContainer,
  ApplyFilterButton,
  BackIcon,
  ResetFilterButton,
  FeaturesContainer,
  BottomRowContainer,
  RightCol,
  LeftCol,
} from './Filters.styled'

const ORDER_OF_FILTERS: { [key in keyof Omit<FiltersType, 'name'>]: number } = {
  genders: 0,
  insurers: 1,
  specializations: 2,
  openingHours: 3,
  location: 4,
}

/**
 * @param props The props object
 * @param props.activeFilters The filter(s) currently visible
 * @param props.setActiveFilters The setter for the currently visible filter(s)
 * @param props.searchText The search text value of the search bar
 * @param props.onCloseFilters The method handling the closing of the filters
 * @param props.isSpecializationsDetailedViewVisible Whether the specializations detailed view is visible
 * @param props.setIsSpecializationsDetailedViewVisible The setter for whether the specializations detailed view is visible
 * @param props.isLocationsDetailedViewVisible Whether the locations detailed view is visible
 * @param props.setIsLocationsDetailedViewVisible The setter for whether the locations detailed view is visible
 * @param props.autocompleteResults The auto complete search results
 * @returns The Filters component
 */
export const Filters: FC<{
  activeFilters: ActiveFilter
  setActiveFilters: (val: ActiveFilter) => void
  autocompleteResults: AutocompleteResult[]
  onCloseFilters: () => void
  searchText: string
  isSpecializationsDetailedViewVisible: boolean
  setIsSpecializationsDetailedViewVisible: (val: boolean) => void
  isLocationsDetailedViewVisible: boolean
  setIsLocationsDetailedViewVisible: (val: boolean) => void
}> = ({
  activeFilters,
  setActiveFilters,
  searchText,
  autocompleteResults,
  onCloseFilters,
  isSpecializationsDetailedViewVisible,
  setIsSpecializationsDetailedViewVisible,
  isLocationsDetailedViewVisible,
  setIsLocationsDetailedViewVisible,
}) => {
  const [features, setFeatures] = useState<FiltersResponse | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | AxiosError>()

  const { apiGateway } = useApiGatewayContext()
  const { isMobileView } = useScreenSize()
  const { i18n } = useI18N()

  const { lastSupportedCountryVisited, setFilterObject } = useServicesContext()

  const { fetchedData: specializations } = useFetchData<Specialization[]>(
    `v1/services/specializations/${lastSupportedCountryVisited}`,
  )

  /**
   * Fetches Features data
   */
  const getFeaturesData = useCallback(async () => {
    try {
      setIsLoading(true)

      const { data } = await apiGateway.get<FiltersResponse>(
        `v1/services/filters?countryCode=${lastSupportedCountryVisited}`,
      )

      const formattedResponse = convertObjectToArray(data)
        .map((item) => (item.key === 'openingHours' ? { ...item, filters: convertObjectToArray(item.filters) } : item))
        .filter((item) => item.key !== 'name')
        .filter((item) => !(item.key === 'insurers' && !item.values.length))

      const sortedFormattedResponse = [...formattedResponse].sort(
        (x: FiltersWithKeysWithoutName, y: FiltersWithKeysWithoutName) =>
          ORDER_OF_FILTERS[x.key] - ORDER_OF_FILTERS[y.key],
      )

      setFeatures(sortedFormattedResponse)
    } catch (err) {
      if ((err as AxiosError<{ customType: string }>)?.response?.data?.customType !== 'INVALID_REQUEST') {
        setError(err as Error)
      }
    } finally {
      setIsLoading(false)
    }
  }, [apiGateway, lastSupportedCountryVisited])

  useEffect(() => {
    getFeaturesData()
  }, [getFeaturesData])

  useEffect(() => {
    if (!Object.keys(activeFilters)?.length) {
      const filterKeysWithDefaultValues = features?.map((item) => [item.key, false])

      const defaultActiveFilters = filterKeysWithDefaultValues?.length
        ? Object.fromEntries(filterKeysWithDefaultValues)
        : {}

      setActiveFilters(defaultActiveFilters)
    }
  }, [activeFilters, setActiveFilters, features])

  const filtersContent = useMemo(() => {
    if (isSpecializationsDetailedViewVisible) {
      return (
        <SpecializationsFilterDetailedView
          searchText={searchText}
          setIsSpecializationsDetailedViewVisible={setIsSpecializationsDetailedViewVisible}
        />
      )
    } else if (isLocationsDetailedViewVisible) {
      return (
        <LocationsFilterDetailedView
          autocompleteResults={autocompleteResults}
          setIsLocationsDetailedViewVisible={setIsLocationsDetailedViewVisible}
        />
      )
    } else {
      return (
        <Container>
          <FeaturesContainer>
            {features?.map((filter) => (
              <FilterHandler
                key={filter.key}
                filter={filter}
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
                specializations={specializations}
                setIsSpecializationsDetailedViewVisible={setIsSpecializationsDetailedViewVisible}
                setIsLocationsDetailedViewVisible={setIsLocationsDetailedViewVisible}
              />
            ))}
          </FeaturesContainer>

          <BottomRowContainer>
            <LeftCol>
              <BackIcon onClick={onCloseFilters}>
                <CustomIcon icon="arrowDown" />
              </BackIcon>
            </LeftCol>

            <RightCol>
              <ResetFilterButton type="text" onClick={() => setFilterObject({})}>
                {i18n('services.filters.reset')}
              </ResetFilterButton>

              <ApplyFilterButton
                type="primary"
                style={{
                  background: CSS_VARS.filledButtonBackgroundColor,
                  borderColor: CSS_VARS.filledButtonFontColor,
                  color: CSS_VARS.filledButtonFontColor,
                }}
                onClick={onCloseFilters}
              >
                {i18n('services.filters.apply')}
              </ApplyFilterButton>
            </RightCol>
          </BottomRowContainer>
        </Container>
      )
    }
  }, [
    activeFilters,
    autocompleteResults,
    features,
    i18n,
    isLocationsDetailedViewVisible,
    isSpecializationsDetailedViewVisible,
    onCloseFilters,
    searchText,
    setActiveFilters,
    setFilterObject,
    setIsLocationsDetailedViewVisible,
    setIsSpecializationsDetailedViewVisible,
    specializations,
  ])

  if (isLoading && !isMobileView) {
    return <LoadingIndicator />
  }

  if (error) {
    throw error
  }

  return (
    <FiltersContainer shouldStartLower={isSpecializationsDetailedViewVisible || isLocationsDetailedViewVisible}>
      {isLoading ? <LoadingIndicator /> : filtersContent}
    </FiltersContainer>
  )
}
