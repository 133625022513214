import React, { FC } from 'react'
import classnames from 'classnames'
import { CustomIcon } from '../../CustomIcon/CustomIcon'
import { useAppStateContext } from '../../../context'
import { CSS_VARS } from '../../../resources/cssVariableConfig'
import styles from './SideMenuToggle.module.less'

/**
 * @returns The hamburger menu for the navbar
 */
export const SideMenuToggle: FC = () => {
  const { isSideMenuOpen, setSideMenuOpen } = useAppStateContext()

  return (
    <div id="menu-btn" className={classnames(styles.SideMenuToggle)} onClick={() => setSideMenuOpen(!isSideMenuOpen)}>
      <CustomIcon
        style={{
          color: CSS_VARS.navBarHamburgerIconColor,
        }}
        icon="menuV2"
        className={classnames(styles.icon)}
      />
    </div>
  )
}
