import styled from '@emotion/styled'
import { SectionTitle } from '../Report.styled'

export const MainContainer = styled.div`
  border-radius: 15px;
  background-color: #f9f9f9;
  padding: 17px 42px;
`
export const BMINumber = styled(SectionTitle)`
  margin-left: 2px;
`
export const BarContainer = styled.div`
  position: relative;
`
export const BarColoredSegment = styled.div<{
  color: string
  widthPercent: number
  isFirst?: boolean
  isLast?: boolean
}>`
  display: inline-block;
  height: 10px;
  width: ${(props) => `${props.widthPercent}`}%;
  background-color: ${(props) => `${props.color}`};
  ${(props) =>
    props.isFirst &&
    `border-top-left-radius: 10px;
     border-bottom-left-radius: 10px;`}
  ${(props) =>
    props.isLast &&
    `border-top-right-radius: 10px;
     border-bottom-right-radius: 10px;`}
`
export const BarPointer = styled.div<{ position: number }>`
  position: absolute;
  height: 20px;
  background-color: #525252;
  width: 1px;
  left: ${(props) => `${props.position}`}%;
  bottom: -2px;
`
