import { BBox } from '@turf/helpers'
import { Row, Typography } from 'antd'
import mapboxgl from 'mapbox-gl'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import ReactMapboxGl, { AttributionControl, GeolocateControl, MapRef, NavigationControl } from 'react-map-gl'
import useSupercluster from 'use-supercluster'
import {
  DEFAULT_BOUNDS,
  DEFAULT_MAPBOX_RADIUS,
  DEFAULT_MAPBOX_ZOOM_LEVEL,
  MAPBOX_MAX_ZOOM_LEVEL,
} from '../../../constants'
import { useServicesContext } from '../../../context'
import { useI18N, useScreenSize } from '../../../hooks'
import { ClusterPoint, ServiceWithDetailedOpeningHours } from '../../../models'
import { ServicesMapMarker } from './ServicesMapMarker/ServicesMapMarker'
import { ServicesMapMarkerGroup } from './ServicesMapMarkerGroup/ServicesMapMarkerGroup'

/**
 * @returns The ServicesMap component
 */
export const ServicesMap = () => {
  const {
    matchingFiltersServicesData,
    isMatchingFiltersServicesLoading,
    setCoordinates,
    setCountryCodeFromCurrentCoordinates,
    setSelectedService,
    isSupportedCountry,
    coordinates: { longitude, latitude },
  } = useServicesContext()

  const [zoomLevel, setZoomLevel] = useState(DEFAULT_MAPBOX_ZOOM_LEVEL)
  const [isMapLoading, setIsMapLoading] = useState(false)

  const { i18n } = useI18N()
  const { isMobileView } = useScreenSize()
  const mapboxAccessToken = window.xundEnvironment.MAPBOX_ACCESS_TOKEN

  const mapRef = useRef<MapRef | null>(null)

  const isMapboxSupported = useMemo(() => mapboxgl.supported(), [])

  /**
   * Triggered whenever coordinates change
   *
   * @param lng The current longitude
   * @param lat The current latitude
   */
  const onCoordinatesChanged = useCallback(
    (lng: number, lat: number) => {
      setCoordinates({ longitude: lng, latitude: lat })
      setCountryCodeFromCurrentCoordinates({ longitude: lng, latitude: lat })
    },
    [setCoordinates, setCountryCodeFromCurrentCoordinates],
  )

  const points = useMemo(
    () =>
      matchingFiltersServicesData.items.map((item) => ({
        type: 'Feature',
        service: item,
        properties: {
          id: item.id,
          cluster: false,
          category: item.type,
        },
        geometry: {
          type: 'Point',
          coordinates: [item.location.longitude, item.location.latitude],
        },
      })),
    [matchingFiltersServicesData],
  )

  const bounds = (mapRef?.current?.getMap().getBounds().toArray().flat() || DEFAULT_BOUNDS) as BBox

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom: zoomLevel,
    options: { radius: DEFAULT_MAPBOX_RADIUS, maxZoom: MAPBOX_MAX_ZOOM_LEVEL },
  })

  const markers = useMemo(
    () =>
      clusters?.length &&
      clusters.map((cluster) => {
        const { cluster: isCluster, point_count: pointCount } = cluster.properties

        const [lng, lat] = cluster.geometry.coordinates

        const dynamicDimensionValue = 20 + (pointCount / points.length) * 50

        const style = {
          width: dynamicDimensionValue,
          height: dynamicDimensionValue,
        }

        if (isCluster) {
          const servicesInCluster: ServiceWithDetailedOpeningHours[] = supercluster
            .getLeaves(cluster.id, Infinity)
            .map((item: ClusterPoint) => item.service)

          return (
            <ServicesMapMarkerGroup
              key={`cluster_${cluster.id}`}
              coordinates={[lng, lat]}
              style={style}
              services={servicesInCluster}
              setZoom={setZoomLevel}
            />
          )
        }

        return <ServicesMapMarker key={cluster.service.id} service={cluster.service} />
      }),
    [clusters, points.length, supercluster],
  )

  if (!isMapboxSupported) {
    return (
      <Row style={{ height: '100%', width: '100%' }} justify="center" align="middle">
        <Typography.Title level={5} style={{ marginLeft: 300 }}>
          {i18n('services.mapViewNotSupported')}
        </Typography.Title>
      </Row>
    )
  }

  return (
    <ReactMapboxGl
      id="map"
      ref={mapRef}
      mapboxAccessToken={mapboxAccessToken}
      mapStyle="mapbox://styles/mapbox/streets-v11?optimize=true"
      logoPosition={isMobileView ? 'top-left' : 'bottom-right'}
      padding={{ top: 0, right: 0, bottom: 0, left: isMobileView ? 0 : 350 }}
      maxZoom={MAPBOX_MAX_ZOOM_LEVEL}
      reuseMaps
      attributionControl={!isMobileView}
      cursor={isMapLoading || isMatchingFiltersServicesLoading ? 'wait' : 'grab'}
      initialViewState={{ longitude, latitude, zoom: zoomLevel }}
      style={{
        filter: !isSupportedCountry ? 'grayscale(100%)' : '',
        transition: 'filter 0.5s ease-in-out',
        width: 'auto',
        height: 'auto',
      }}
      zoom={zoomLevel}
      onZoomStart={() => setIsMapLoading(true)}
      onZoomEnd={() => setIsMapLoading(false)}
      onRotateStart={() => setIsMapLoading(true)}
      onRotateEnd={() => setIsMapLoading(false)}
      onMoveStart={() => setIsMapLoading(true)}
      onClick={() => setSelectedService(null)}
      onMoveEnd={({ viewState: { longitude: lng, latitude: lat, zoom } }) => {
        onCoordinatesChanged(lng, lat)
        setZoomLevel(zoom)
        setIsMapLoading(false)
      }}
    >
      <NavigationControl showCompass={false} />

      <GeolocateControl
        showAccuracyCircle={false}
        onGeolocate={({ coords: { longitude: lng, latitude: lat } }) => onCoordinatesChanged(lng, lat)}
      />

      {isMobileView && <AttributionControl position="top-left" style={{ left: '-4px' }} />}

      {markers}
    </ReactMapboxGl>
  )
}
