import React, { FC, useMemo } from 'react'
import { BasicAnswer } from 'health-check-api'
import { WrapperWithTitleAndAnimation } from '../../../components/WrapperWithTitleAndAnimation'
import { StyledButton } from '../../../components/General/StyledButton/StyledButton'
import { getTempTranslation } from '../utils/getTempTranslation'
import { SelectInput } from './SelectInput'

interface Props {
  title: string
  onDropdownSelect: (value: string) => void
  onSingleSelectClick: (value: string) => void
  answers: BasicAnswer[]
  isLoading: boolean
}

export const SingleSelect: FC<Props> = (props) => {
  const { title, onDropdownSelect, onSingleSelectClick, answers, isLoading } = props

  const isDropDown = useMemo(() => (answers?.length ?? 0) >= 7, [answers])

  return (
    <>
      {!isDropDown && (
        <WrapperWithTitleAndAnimation title={title} isAnimated animationStartsWhen={!isLoading}>
          <div className="buttonContainer">
            {answers?.map((answer) => (
              <StyledButton
                key={answer.id}
                type="outlined"
                title={answer.text}
                style={{ marginTop: 10, height: 'auto', whiteSpace: 'normal' }}
                disabled={isLoading}
                onClick={() => onSingleSelectClick(answer.id)}
              />
            ))}
          </div>
        </WrapperWithTitleAndAnimation>
      )}
      {isDropDown && (
        <SelectInput
          options={answers.map((answer) => ({ label: answer.text, value: answer.id }))}
          placeholder={getTempTranslation('components.singleSelect.placeholder')}
          title={title}
          isLoading={isLoading}
          onSelect={onDropdownSelect}
        />
      )}
    </>
  )
}
