import React, { FC, useMemo } from 'react'
import { CheckCircleFilled } from '@ant-design/icons'
import { useI18N } from '../../../../hooks'
import { LoadingIndicator } from '../../../../components/LoadingIndicator/LoadingIndicator'
import { CustomIcon } from '../../../../components/CustomIcon/CustomIcon'
import { CSS_VARS } from '../../../../resources/cssVariableConfig'
import { RiskTopic, RiskTopicTitle } from './RiskTopicSelector.styled'
import { RiskTopicView } from './RiskTopicList'

interface Props {
  riskTopicView: RiskTopicView
  isLoading: boolean
  onSelected: () => void
}

export const RiskTopicItem: FC<Props> = (props) => {
  const { riskTopicView, isLoading, onSelected } = props
  const { i18n } = useI18N()

  const icon = useMemo(() => {
    if (isLoading) {
      return <LoadingIndicator />
    }

    const iconStyling = {
      fontSize: 20,
      marginBottom: 2,
      marginRight: 2,
    }

    if (riskTopicView.isSelected) {
      return <CheckCircleFilled style={{ ...iconStyling, color: CSS_VARS.GREEN_COLOR }} />
    } else {
      return (
        <CustomIcon
          title={i18n('general.add')}
          icon="addCircle"
          style={{ ...iconStyling, color: CSS_VARS.PRIMARY_COLOR }}
        />
      )
    }
  }, [i18n, isLoading, riskTopicView])

  return (
    <RiskTopic onClick={() => onSelected()} isLoading={isLoading} isDisabled={riskTopicView.isDisabled}>
      <div style={{ display: 'flex' }}>
        <RiskTopicTitle style={{ color: CSS_VARS.chatFontColor }}>{riskTopicView.name}</RiskTopicTitle>
      </div>
      <div style={{ display: 'flex' }}>{icon}</div>
    </RiskTopic>
  )
}
