import React, { FC, Fragment, useMemo, useState } from 'react'
import { useAppStateContext, useWebAppConfigContext } from '../../context'
import { useI18N } from '../../hooks'
import { CustomIcon } from '../CustomIcon/CustomIcon'
import { Impressum } from '../Modals/Impressum/Impressum'
import { LanguageSelectionModal } from '../Modals/LanguageSelectionModal/LanguageSelectionModal'
import { RestartModal } from '../Modals/RestartModal/RestartModal'
import styles from '../SideMenu/SideMenu.module.less'
import { HomePageModal } from '../Modals/HomePageModal/HomePageModal'
import { CSS_VARS } from '../../resources/cssVariableConfig'
import { useApiKeysScopes } from '../../hooks/useApiKeyScopes'
import { MenuItem } from './MenuItem/MenuItem'

/**
 * @param props The props object
 * @param props.onServicesOpen The method handling the opening of services
 * @param props.onHomeClick A method handling the click action on the home icon
 * @param props.onRestartCheck A method handling the restart of the current check
 * @param props.showRestart The boolean which decides whether to show the restart side menu item
 * @returns The SideMenu component
 */
export const SideMenu: FC<{
  onServicesOpen: () => void
  onHomeClick: (onHomePageModalOpen: () => void) => void
  onRestartCheck: () => void
  showRestart: boolean
}> = ({ onServicesOpen, onHomeClick, onRestartCheck, showRestart }) => {
  const servicesEnabled = window.xundEnvironment.SERVICES_ENABLED

  const [isHomePageModalOpen, setIsHomePageModalOpen] = useState(false)
  const [isRestartModalOpen, setIsRestartModalOpen] = useState(false)
  const [isLanguageSelectModalOpen, setIsLanguageSelectModalOpen] = useState(false)
  const [isImpressumModalOpen, setIsImpressumModalOpen] = useState(false)

  const {
    webAppConfig: { illnessCheckIcd10Code: isDirectIllnessCheck },
  } = useWebAppConfigContext()

  const { hasApiKeyScope } = useApiKeysScopes()

  const { isServicesOpen, isSideMenuOpen, setSideMenuOpen, isContentLibraryOpen, setContentLibraryOpen } =
    useAppStateContext()

  const { i18n } = useI18N()

  const attachedClasses = useMemo(
    () => (isSideMenuOpen ? [styles.SideDrawer, 'sideBar', styles.Open] : [styles.SideDrawer, 'sideBar', styles.Close]),
    [isSideMenuOpen],
  )

  return (
    <>
      {!isDirectIllnessCheck && isHomePageModalOpen && (
        <HomePageModal onConfirm={() => window.appLocation.reload()} onCancel={() => setIsHomePageModalOpen(false)} />
      )}

      {showRestart && isRestartModalOpen && (
        <RestartModal
          onConfirm={() => {
            onRestartCheck()

            setIsRestartModalOpen(false)
            setSideMenuOpen(!isSideMenuOpen)
          }}
          onCancel={() => setIsRestartModalOpen(false)}
        />
      )}

      {isLanguageSelectModalOpen && <LanguageSelectionModal onCancel={() => setIsLanguageSelectModalOpen(false)} />}

      {isImpressumModalOpen && <Impressum onCancel={() => setIsImpressumModalOpen(false)} />}

      <Fragment>
        <div className={attachedClasses.join(' ')} style={{ background: CSS_VARS.sideMenuBackgroundColor }}>
          <div id="close-menu-btn" className={styles.CancelContainer} onClick={() => setSideMenuOpen(!isSideMenuOpen)}>
            <CustomIcon
              style={{
                fill: CSS_VARS.sideMenuCloseIconColor,
                stroke: CSS_VARS.sideMenuCloseIconColor,
              }}
              icon="cancel"
            />
          </div>

          {!isDirectIllnessCheck && (
            <MenuItem
              onClick={() => onHomeClick(() => setIsHomePageModalOpen(true))}
              id="home-menu-item"
              icon="home"
              title={i18n('menu.home')}
            />
          )}

          <MenuItem
            onClick={() => setIsLanguageSelectModalOpen(true)}
            id="change-language-menu-item"
            icon="changeLanguage"
            title={i18n('general.selectLanguage')}
          />

          {hasApiKeyScope('medical_library') && !isDirectIllnessCheck && (
            <MenuItem
              onClick={() => {
                if (isServicesOpen) {
                  onServicesOpen()
                }
                setContentLibraryOpen(!isContentLibraryOpen)
                setSideMenuOpen(!isSideMenuOpen)
              }}
              id="content-library-menu-item"
              icon="book"
              title={i18n('menu.medicalLibrary')}
              active={isContentLibraryOpen}
            />
          )}

          {!isDirectIllnessCheck && servicesEnabled && (
            <MenuItem
              onClick={() => {
                if (isContentLibraryOpen) {
                  setContentLibraryOpen(!isContentLibraryOpen)
                }
                onServicesOpen()
              }}
              id="services-menu-item"
              icon="healthSolutions"
              title={i18n('services.title')}
              active={isServicesOpen}
            />
          )}

          <MenuItem
            onClick={() => setIsImpressumModalOpen(true)}
            id="impressum-menu-item"
            icon="imprint"
            title={i18n('imprint.title')}
          />

          {showRestart && (
            <MenuItem
              onClick={() => setIsRestartModalOpen(true)}
              id="restart-menu-item"
              icon="restart"
              title={i18n('menu.restart')}
              style={{ position: 'absolute', bottom: '35px' }}
            />
          )}
        </div>
      </Fragment>
    </>
  )
}
