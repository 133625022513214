import React, { FC, useMemo } from 'react'
import { CURRENT_HOUR, NAME_OF_TODAYS_DAY } from '../../../../../../constants'
import { useI18N } from '../../../../../../hooks'
import { OpeningHours } from '../../../../../../models'
import { getHoursValueAsNumberFromTimeString } from '../../../../../../utils'
import { convertCurrentServiceOpeningHoursToObject } from '../../../../../../utils/services/convertCurrentServiceOpeningHoursToObject'
import { CSS_VARS } from '../../../../../../resources/cssVariableConfig'

/**
 * @param props The props object
 * @param props.openingHours The current opening hours
 * @returns The text that is displayed based on the opening hours
 */
export const ServiceOpeningHoursText: FC<{ openingHours?: OpeningHours }> = ({ openingHours }) => {
  const { i18n } = useI18N()

  const todaysOpeningHours = useMemo(
    () => convertCurrentServiceOpeningHoursToObject(openingHours)[NAME_OF_TODAYS_DAY],
    [openingHours],
  )

  const isStillOpenToday = useMemo(
    () => todaysOpeningHours.find((hours) => getHoursValueAsNumberFromTimeString(hours.finish) > CURRENT_HOUR),
    [todaysOpeningHours],
  )

  if (!todaysOpeningHours?.length) {
    return <span style={{ color: CSS_VARS.DARK_GREY_COLOR }}>{i18n('services.openingHours.noInfo')}</span>
  }

  if (
    isStillOpenToday &&
    getHoursValueAsNumberFromTimeString(isStillOpenToday.finish) - CURRENT_HOUR >= 2 &&
    getHoursValueAsNumberFromTimeString(isStillOpenToday.start) <= CURRENT_HOUR
  ) {
    return <span style={{ color: CSS_VARS.GREEN_COLOR }}>{i18n('services.open')}</span>
  }

  if (isStillOpenToday && getHoursValueAsNumberFromTimeString(isStillOpenToday.finish) - CURRENT_HOUR <= 2) {
    return <span style={{ color: CSS_VARS.RED_COLOR }}>{i18n('services.closesAt', isStillOpenToday.finish)}</span>
  }

  if (isStillOpenToday && getHoursValueAsNumberFromTimeString(isStillOpenToday.start) - CURRENT_HOUR > 2) {
    return <span style={{ color: CSS_VARS.GREEN_COLOR }}>{i18n('services.opensAt', isStillOpenToday.start)}</span>
  }

  return <span style={{ color: CSS_VARS.RED_COLOR }}>{i18n('services.closed')}</span>
}
