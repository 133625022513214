import React, { FC, useCallback, useEffect, useState } from 'react'
import { CustomIcon } from '../../CustomIcon/CustomIcon'
import { useApiGatewayContext } from '../../../context'
import { useI18N } from '../../../hooks'
import { Imprint } from '../../../models'
import { LoadingIndicator } from '../../LoadingIndicator/LoadingIndicator'
import { ModalText } from '../ModalText/ModalText'
import { BaseModal } from '../BaseModal/BaseModal'
import { CSS_VARS } from '../../../resources/cssVariableConfig'
import styles from './Impressum.module.less'

/**
 * @param props The props object
 * @param props.onCancel The method handling cancellation
 * @returns The Impressum component
 */
export const Impressum: FC<{ onCancel: () => void }> = ({ onCancel }) => {
  const [impressumData, setImpressumData] = useState<Imprint | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error>()

  const { i18n } = useI18N()
  const { apiGateway } = useApiGatewayContext()

  /**
   * Gets the data needed for the impressum
   */
  const getImpressumData = useCallback(async () => {
    try {
      setIsLoading(true)

      const { data } = await apiGateway.get(`v1/imprintResources`)

      setImpressumData(data)
    } catch (err) {
      setError(err as Error)
    } finally {
      setIsLoading(false)
    }
  }, [apiGateway])

  useEffect(() => {
    getImpressumData()
  }, [getImpressumData])

  if (error) {
    throw error
  }

  return (
    <BaseModal title={i18n('imprint.title')} onCancel={onCancel} hasButtons={false} contentClassName={styles.impressum}>
      {!isLoading ? (
        <>
          <div className={styles.impressumContainer}>
            <div>
              <h2 style={{ color: CSS_VARS.questionFontColor, fontFamily: CSS_VARS.fontFamily }}>
                {i18n('imprint.contact')}
              </h2>
              <div className={styles.impressumItemWithIcon}>
                <CustomIcon icon="https" className={styles.impressumIcon} />

                <a
                  href={impressumData?.impressum?.companyWebsite.path}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: CSS_VARS.linkColor, fontSize: CSS_VARS.modalFontSize }}
                >
                  {impressumData?.impressum?.companyWebsite.path}
                </a>
              </div>

              <div className={styles.impressumItemWithIcon}>
                <CustomIcon icon="envelope" className={styles.impressumIcon} />

                <ModalText title={impressumData?.impressum?.contactEmail.path} />
              </div>

              <div className={styles.impressumItemWithIcon}>
                <CustomIcon icon="phone" className={styles.impressumIcon} />

                <ModalText title={impressumData?.impressum?.bugReportEmail.path} />
              </div>

              <div className={styles.impressumItemWithIcon}>
                <CustomIcon icon="address" className={styles.impressumIcon} />

                <ModalText title={impressumData?.impressum?.contactAddress} />
              </div>
            </div>
            <hr></hr>
            <div className={styles.impressumInformation}>
              <h2 style={{ color: CSS_VARS.questionFontColor, fontFamily: CSS_VARS.fontFamily }}>
                {i18n('imprint.medicalProductInfo')}
              </h2>
              <div>
                <ModalText
                  title={`${i18n('imprint.medicalProductInfo.product_name')}: ${
                    impressumData?.medicalProductInfo?.productName
                  }`}
                />
                <ModalText
                  title={`${i18n('imprint.medicalProductInfo.interface')}: ${
                    impressumData?.medicalProductInfo?.interface
                  }`}
                />
                <ModalText
                  title={`${i18n('imprint.medicalProductInfo.UDI_DI')}: ${impressumData?.medicalProductInfo?.udiDi}`}
                />
                <ModalText
                  title={`${i18n('imprint.medicalProductInfo.basic_UDI_DI')}: ${
                    impressumData?.medicalProductInfo?.basicUdiDi
                  }`}
                />
                <ModalText
                  title={`${i18n('imprint.medicalProductInfo.UDI_PI_date_of_manufacture')}: ${
                    impressumData?.medicalProductInfo?.udiPiDateOfManufacture
                  }`}
                />
                <ModalText
                  title={`${i18n('imprint.medicalProductInfo.UDI_PI_software_version')}: ${
                    impressumData?.medicalProductInfo?.udiPiSoftwareVersion
                  }`}
                />
              </div>
            </div>
          </div>
          {impressumData?.medicalProductInfo.ceMark && (
            <img src={impressumData.medicalProductInfo.ceMark.path} className={styles.ceMark} alt="CE Mark" />
          )}
        </>
      ) : (
        <LoadingIndicator />
      )}
    </BaseModal>
  )
}
