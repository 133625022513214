import React, { FC } from 'react'
import { Typography } from 'antd'
import { useI18N, useServiceIconProperties } from '../../../../../../../hooks'
import { CustomIcon } from '../../../../../../CustomIcon/CustomIcon'
import { SpecializationType, TranslationCodeLiteral } from '../../../../../../../models'
import { CSS_VARS } from '../../../../../../../resources/cssVariableConfig'
import {
  Category,
  CategoryIcon,
  CategoryRow,
  SpecializationCategory,
} from './SpecializationsFilterDetailedViewFilterItem.styled'

/**
 * @param props The props object
 * @param props.icon The filter icon
 * @param props.label The filter label
 * @param props.isSelected Whether the filter is selected
 * @param props.onClick The onClick method
 * @returns The SpecializationsFilterDetailedViewFilterItem component
 */
export const SpecializationsFilterDetailedViewFilterItem: FC<{
  icon: SpecializationType
  label: TranslationCodeLiteral
  isSelected: boolean
  onClick: () => void
}> = ({ icon, label, isSelected, onClick }) => {
  const { getServiceTypeColor } = useServiceIconProperties()

  const { i18n } = useI18N()

  return (
    <SpecializationCategory onClick={onClick}>
      <Typography style={{ fontSize: CSS_VARS.chatFontSize }}>
        <CategoryRow align="middle" justify="space-between">
          <Category selected={isSelected}>
            <CategoryIcon
              style={{
                height: `calc(${CSS_VARS.chatFontSize} + 4px)`,
                width: `calc(${CSS_VARS.chatFontSize} + 4px)`,
              }}
            >
              <CustomIcon
                icon={
                  icon === SpecializationType.Doctor
                    ? 'doctorFilled'
                    : icon === SpecializationType.Hospital
                    ? 'hospitalFilled'
                    : 'pharmacyFilled'
                }
                style={{
                  height: CSS_VARS.chatFontSize,
                  width: CSS_VARS.chatFontSize,
                  fill: getServiceTypeColor(icon),
                }}
              />
            </CategoryIcon>

            {i18n(label)}
          </Category>

          <div>{isSelected && <CustomIcon icon="check" />}</div>
        </CategoryRow>
      </Typography>
    </SpecializationCategory>
  )
}
