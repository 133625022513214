import { Question, QUESTION_STATE_PROFILE, QUESTION_TYPE_BMI } from 'health-check-api'

export const mockBMIQuestion: Question = {
  id: 'bmiQuestion',
  text: 'What is your BMI?',
  type: QUESTION_TYPE_BMI,
  state: QUESTION_STATE_PROFILE,
  answers: [],
  params: null,
}
