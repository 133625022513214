import React, { FC, useMemo } from 'react'
import { Col, Row } from 'antd'

import { CheckType } from '../../models'
import { useI18N, useLanguage } from '../../hooks'
import { getEnvironmentType } from '../../utils/getEnvironmentType'
import { useApiKeysScopes } from '../../hooks/useApiKeyScopes'
import { ContentLibrarySearch } from './ContentLibrarySearch/ContentLibrarySearch'
import {
  CtaButton,
  Header,
  IconBackground,
  Module,
  SearchHeader,
  StyledCustomIcon,
  StyledRow,
  SubHeader,
  WaveContainer,
} from './StartScreen.styled'

/**
 * @param props The props object
 * @param props.showTermsAndConditions The method for showing the terms and conditions
 * @returns The StartScreen component
 */
export const StartScreen: FC<{
  showTermsAndConditions: (param: CheckType) => void
}> = ({ showTermsAndConditions }) => {
  const { i18n, getCustomTranslationWithLocalFallback } = useI18N()
  const { currentLanguage } = useLanguage()
  const { hasApiKeyScope } = useApiKeysScopes()

  const useCompactBreakpoints = ['fr', 'fr-formal'].includes(currentLanguage)

  const isSoteMode = window.xundEnvironment.IS_SOTE_MODE === 'true'
  const isHealthCheckActive = useMemo(
    () => getEnvironmentType() === 'DEVELOPMENT' || getEnvironmentType() === 'UAT' || getEnvironmentType() === 'BETA',
    [],
  )

  const cards = useMemo(() => {
    const symptomCheck = {
      key: 'symptom_check',
      title: i18n('start.symptomCheckHeader'),
      description: i18n('start.symptomCheckDescription'),
      ctaText: i18n('start.startSymptomCheck'),
      ctaOnClick: () => showTermsAndConditions('SYMPTOM_CHECK'),
      icon: 'scIcon',
    }

    if (isSoteMode) {
      return [symptomCheck]
    }

    const baseFeatures = [
      symptomCheck,
      {
        key: 'illness_check',
        title: i18n('start.illnessCheckHeader'),
        description: i18n('start.illnessCheckDescription'),
        ctaText: i18n('start.startIllnessCheck'),
        ctaOnClick: () => showTermsAndConditions('ILLNESS_CHECK'),
        icon: 'icIcon',
      },
    ]
    if (isHealthCheckActive) {
      return [
        ...baseFeatures,
        {
          key: 'health_check',
          title: 'Meet our Health Check',
          description:
            'Provide asymptomatic patients 360 degrees risk assessments with our Health Check by asking about lifestyle, medication and medical history. The outcome is a risk profile for all organ groups to guide prevention and enchance accuracy in our Symptom and Illness Checks.',
          ctaText: 'Start Health Check',
          ctaOnClick: () => showTermsAndConditions('HEALTH_CHECK'),
          icon: 'hcIcon',
        },
      ]
    } else {
      return baseFeatures
    }
  }, [i18n, isHealthCheckActive, isSoteMode, showTermsAndConditions])

  const computedBreakpoints = useMemo(() => {
    const normalBreakpoints = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 }
    const compactBreakpoints = { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 }
    const healthCheckBreakpoints = { xs: 24, sm: 24, md: 12, lg: 12, xl: 8 }

    return isHealthCheckActive ? healthCheckBreakpoints : useCompactBreakpoints ? compactBreakpoints : normalBreakpoints
  }, [isHealthCheckActive, useCompactBreakpoints])

  return (
    <>
      <WaveContainer>
        <div>
          <Row justify="center" style={{ flex: 1 }}>
            <Col span={19}>
              <StyledRow>
                <Col span={24}>
                  <Header>{getCustomTranslationWithLocalFallback('welcomeGreeting')}</Header>
                  <SubHeader level={5}>{getCustomTranslationWithLocalFallback('welcomeMessage')}</SubHeader>
                </Col>
              </StyledRow>

              <Row
                gutter={[0, 55]}
                style={isSoteMode ? { flex: 1 } : undefined}
                justify={isSoteMode ? 'center' : undefined}
              >
                {cards.map((card, i) => {
                  return (
                    <Col {...computedBreakpoints} key={card.key}>
                      <Module
                        title={card.title}
                        bordered={false}
                        actions={[
                          <CtaButton type="primary" shape="round" key="cta" onClick={card.ctaOnClick}>
                            {card.ctaText}
                          </CtaButton>,
                        ]}
                        needsExtraMargin={false} //TODO remove if unnecessary
                        useCompactBreakpoints={useCompactBreakpoints}
                      >
                        <IconBackground />
                        <StyledCustomIcon icon={card.icon} />

                        {card.description}
                      </Module>
                    </Col>
                  )
                })}
              </Row>
            </Col>
          </Row>
        </div>
      </WaveContainer>

      {hasApiKeyScope('medical_library') && !isSoteMode && (
        <StyledRow justify="center" style={{ flex: 1 }}>
          <Col xs={19} sm={19} md={19} lg={12} xl={12}>
            <Row>
              <Col span={24}>
                <SearchHeader level={3}>{i18n('start.searchHeader')}</SearchHeader>

                <ContentLibrarySearch showTermsAndConditions={showTermsAndConditions} />
              </Col>
            </Row>
          </Col>
        </StyledRow>
      )}
    </>
  )
}
