import React, { FC } from 'react'
import { Col } from 'antd'
import { ProtectiveRiskFactor } from 'health-check-api'
import { getTempTranslation } from '../../utils/getTempTranslation'
import { Flex } from '../Flex'
import { EmptyState } from './EmptyState'
import {
  PositiveRiskFactorContainer,
  RiskFactorPill,
  RiskFactorTitleIcon,
  SectionTitle,
  StyledRow,
  StyledText,
} from './Report.styled'

interface Props {
  protectiveRiskFactors: ProtectiveRiskFactor[]
}

export const ProtectiveRiskFactorSection: FC<Props> = (props) => {
  const { protectiveRiskFactors } = props
  return (
    <>
      <StyledRow gutter={[0, 10]} margin="0 0 40px 0">
        <Col span={24}>
          <Flex alignItems="center">
            <RiskFactorTitleIcon icon="addCircle" color="#00ADEF" />
            <SectionTitle>{getTempTranslation('healthCheck.report.protectiveRiskFactor.title')}</SectionTitle>
          </Flex>
        </Col>
        <Col span={24}>
          <StyledText>{getTempTranslation('healthCheck.report.protectiveRiskFactor.description')}</StyledText>
        </Col>
      </StyledRow>
      <StyledRow margin="0 0 80px 0">
        <Col span={24}>
          {protectiveRiskFactors.length > 0 && (
            <PositiveRiskFactorContainer>
              {protectiveRiskFactors.map((riskFactor) => (
                <RiskFactorPill key={`positive-risk-factor-${riskFactor.factor}`}>{riskFactor.factor}</RiskFactorPill>
              ))}
            </PositiveRiskFactorContainer>
          )}
          {protectiveRiskFactors.length === 0 && <EmptyState type="protective" />}
        </Col>
      </StyledRow>
    </>
  )
}
