import styled from '@emotion/styled'
import { CSS_VARS } from '../../../../../../resources/cssVariableConfig'

export const InnerContainer = styled.div`
  height: calc(100vh - 195px);
  overflow: scroll;
`

export const Location = styled.div`
  width: 100%;
  color: white;
  line-height: 1.1;
  cursor: pointer;
  padding: 10px 0;
  transition: all 0.2s ease-in-out;

  article {
    width: 100%;
    color: ${CSS_VARS.chatFontColor};
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: ${CSS_VARS.fontFamily};
  }
`

export const LocationRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
