import { BasicQuestion, QUESTION_STATE_RISK_TOPIC, QUESTION_TYPE_RISK_TOPIC } from 'health-check-api'

export const mockRiskTopicQuestion: BasicQuestion = {
  id: 'riskTopicId',
  text: 'Select your risk topic(s)',
  type: QUESTION_TYPE_RISK_TOPIC,
  answers: [
    {
      id: 'cardio',
      text: 'Cardiovascular disease',
      params: null,
    },
  ],
  state: QUESTION_STATE_RISK_TOPIC,
  params: null,
}
