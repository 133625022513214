import React, { FC } from 'react'
import { Col, Collapse, Row } from 'antd'
import { UpOutlined } from '@ant-design/icons'
import { NegativeRiskFactor } from 'health-check-api'
import { getTempTranslation } from '../../utils/getTempTranslation'
import { Flex } from '../Flex'
import {
  AssociatedRiskFactorContainer,
  RecommendationContainer,
  RecommendationPanel,
  RiskFactorTitleIcon,
  SectionTitle,
  StyledRow,
  StyledText,
} from './Report.styled'
import { EmptyState } from './EmptyState'

interface Props {
  negativeRiskFactors: NegativeRiskFactor[]
}

export const RecommendationSection: FC<Props> = (props) => {
  const { negativeRiskFactors } = props

  //TODO reactivate once categories are implemented
  // const [selectedCategory, setSelectedCategory] = useState<string>('All')
  // const filterLogic = useMemo(() => {
  //   if (selectedCategory === 'All') {
  //     return () => true
  //   } else {
  //     return (recommendation: Record<string, any>) => recommendation.category === selectedCategory
  //   }
  // }, [selectedCategory])
  // const isCategoryDisabled = useCallback((categoryName: string) => {
  //   return false
  // if (categoryName === 'All') {
  //   return false
  // }
  // const numberOfRecommendationsInCategory =
  //   negativeRiskFactors.filter((riskFactor) => riskFactor.category === categoryName)?.length ?? 0
  // return numberOfRecommendationsInCategory === 0
  // }, [])

  return (
    <>
      <StyledRow gutter={[0, 10]} margin="0 0 40px 0">
        <Col span={24}>
          <Flex alignItems="center">
            <RiskFactorTitleIcon icon="arrowUpperRight" />
            <SectionTitle>{getTempTranslation('healthCheck.report.negativeRiskFactors.title')}</SectionTitle>
          </Flex>
        </Col>
        <Col span={24}>
          <StyledText>{getTempTranslation('healthCheck.report.negativeRiskFactors.description')}</StyledText>
        </Col>
      </StyledRow>
      <Row gutter={[0, 20]}>
        {/*TODO reactivate once categories are implemented*/}
        {/*<Col span={24} style={{ display: 'flex' }}>*/}
        {/*  {['All', 'General', 'Family history', 'Lifestyle', 'Medical history'].map((category) => (*/}
        {/*    <RiskFactorCategoryButton*/}
        {/*      key={`risk-factor-category-${category}`}*/}
        {/*      onClick={() => setSelectedCategory(category)}*/}
        {/*      fontWeight={selectedCategory === category ? 700 : 400}*/}
        {/*      data-text={category}*/}
        {/*      disabled={isCategoryDisabled(category)}*/}
        {/*    >*/}
        {/*      {category}*/}
        {/*    </RiskFactorCategoryButton>*/}
        {/*  ))}*/}
        {/*</Col>*/}
        <Col span={24}>
          <Collapse
            defaultActiveKey={['0']}
            ghost={true}
            expandIconPosition="end"
            expandIcon={({ isActive }) => <UpOutlined rotate={isActive ? 180 : 0} style={{ marginTop: 8 }} />}
          >
            {negativeRiskFactors.map((riskFactor, index) => (
              <RecommendationPanel
                key={index}
                header={
                  <Flex alignItems="center" justifyContent="space-between" style={{ flexWrap: 'wrap' }}>
                    <StyledText color="#00417A" isBold={true}>
                      {riskFactor.title}
                    </StyledText>
                    <Flex alignItems="center">
                      <StyledText fontSize={12}>
                        {getTempTranslation('healthCheck.report.associatedRiskFactor')}
                      </StyledText>
                      <AssociatedRiskFactorContainer>
                        <StyledText fontSize={12}>{riskFactor.factor}</StyledText>
                      </AssociatedRiskFactorContainer>
                    </Flex>
                  </Flex>
                }
              >
                <RecommendationContainer>
                  <Col span={24}>
                    <StyledText isBold={true}>{getTempTranslation('healthCheck.report.recommendations')}</StyledText>
                  </Col>
                  <Col span={24}>
                    <StyledText>{riskFactor.description}</StyledText>
                  </Col>
                </RecommendationContainer>
              </RecommendationPanel>
            ))}
            {negativeRiskFactors.length === 0 && <EmptyState type="negative" />}
          </Collapse>
        </Col>
      </Row>
    </>
  )
}
