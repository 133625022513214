import 'antd/dist/reset.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import './xundTest.less'
import './App.less'
import React, { FC } from 'react'
import { createRoot } from 'react-dom/client'
import { ErrorBoundary } from './components/ErrorBoundary'
import { AppRoutes } from './AppRoutes'
import inMemoryLocalStorage from './utils/inMemoryLocalStorage'
import { defaultWebAppConfig } from './resources/defaultWebAppConfig'

try {
  window.appStorage = window.localStorage

  window.appLocation = {
    reload: () => window.location.reload(),
    replace: (url: string | URL) => window.location.replace(url),
  }
} catch (e) {
  window.appStorage = inMemoryLocalStorage

  window.appStorage.setItem('xundWebAppLanguage', defaultWebAppConfig.defaultLanguage)
  window.appLocation = {
    reload: () => {
      window.location.reload()
      window.parent.postMessage({ type: 'refresh' }, '*')
    },
    replace: (url: string | URL) => {
      window.location.replace(url)
      window.parent.postMessage({ type: 'refresh' }, '*')
    },
  }
}

/**
 * The App component
 *
 * @returns The router wrapped in the error boundary
 */
export const App: FC = () => (
  <ErrorBoundary>
    <AppRoutes />
  </ErrorBoundary>
)

const rootElement = document.getElementById('root') || document.createElement('div')

const root = createRoot(rootElement)

root.render(<App />)
