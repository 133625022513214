import { createContext, useContext } from 'react'

const initialValue: {
  isServicesOpen: boolean
  setServicesOpen: (value: boolean) => void
  isSideMenuOpen: boolean
  setSideMenuOpen: (value: boolean) => void
  isContentLibraryOpen: boolean
  setContentLibraryOpen: (value: boolean) => void
} = {
  isServicesOpen: false,
  setServicesOpen: () => '',
  isSideMenuOpen: false,
  setSideMenuOpen: () => '',
  isContentLibraryOpen: false,
  setContentLibraryOpen: () => '',
}

export const AppStateContext = createContext(initialValue)

/**
 * The custom hook for the app state context
 *
 * @returns The custom hook for the app state context
 */
export const useAppStateContext = () => useContext(AppStateContext)
