import styled from '@emotion/styled'
import { Typography } from 'antd'
import { CSS_VARS } from '../../../../../resources/cssVariableConfig'

export const StyledTitle = styled(Typography.Title)`
  color: ${CSS_VARS.chatFontColor} !important;
  font-size: ${CSS_VARS.chatFontSize} !important;
  font-weight: 700 !important;
  line-height: 17px !important;
  margin-bottom: 0 !important;
  font-family: ${CSS_VARS.fontFamily};
`

export const StyledTypography = styled(Typography)`
  color: ${CSS_VARS.darkTextColor};
  line-height: 17px;
  font-family: ${CSS_VARS.fontFamily};
  font-style: ${CSS_VARS.chatFontStyle};
  font-weight: ${CSS_VARS.chatFontWeight};
  font-size: ${CSS_VARS.chatFontSize};

  span {
    font-family: ${CSS_VARS.fontFamily};
  }
`
