import React from 'react'
import { Typography } from 'antd'
import { useI18N, useBreakpoints } from '../../hooks'
import droppy from '../../assets/images/droppy-404.svg'
import { CSS_VARS } from '../../resources/cssVariableConfig'
import styles from './PageNotFound.module.less'

/**
 * @returns The PageNotFound component
 */
export const PageNotFound = () => {
  const { i18nFromLocallyProvidedJSON } = useI18N()
  const { isScreenSizeXS } = useBreakpoints()

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <img src={droppy} className={styles.droppy} alt="Page not found" />

      <Typography.Title level={isScreenSizeXS ? 2 : 3} style={{ color: CSS_VARS.DARK_BLUE_COLOR, textAlign: 'center' }}>
        {i18nFromLocallyProvidedJSON.errors.pageNotFound}
      </Typography.Title>
    </div>
  )
}
