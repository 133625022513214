import React, { FC, useMemo, useState } from 'react'
import { Col, Popover } from 'antd'
import mapboxgl from 'mapbox-gl'
import { useMap } from 'react-map-gl'
import { CSSTransition } from 'react-transition-group'
import styled from '@emotion/styled'
import { ServiceWithDetailedOpeningHours } from '../../../../models'
import { useServicesContext } from '../../../../context'
import { useI18N } from '../../../../hooks'
import { StyledRow } from '../SearchItem/SearchItem.styled'
import { StyledTypography } from '../SearchItem/SearchItemInfo/SearchItemInfo.styled'
import { CustomIcon } from '../../../CustomIcon/CustomIcon'
import { CSS_VARS } from '../../../../resources/cssVariableConfig'
import { ServiceDetailsModal } from './ServiceDetailsModal/ServiceDetailsModal'
import { OpeningHoursDisplay } from './OpeningHoursDisplay/OpeningHoursDisplay'
import { IconContainer, LocationContainer, StyledCol } from './ServiceDetails.styled'

export const StyledIconContainer = styled(IconContainer)`
  svg {
    fill: ${CSS_VARS.sideMenuBackgroundColor} !important;
    stroke: ${CSS_VARS.sideMenuBackgroundColor} !important;
  }
`

/**
 * @param props The props object
 * @param props.service The current service
 * @returns The ServiceDetails component
 */
export const ServiceDetails: FC<{ service: ServiceWithDetailedOpeningHours }> = ({ service }) => {
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false)

  const isMapboxSupported = useMemo(() => mapboxgl.supported(), [])

  const { i18n } = useI18N()
  const { map } = useMap()

  const { setSelectedService } = useServicesContext()

  return (
    <CSSTransition in={true} timeout={500} classNames="innerWindowAnimation" appear unmountOnExit>
      <div>
        <StyledRow>
          <StyledCol flex="auto">
            <StyledTypography>
              {service?.insurers?.map((insurer, index) => (
                <span key={index}>{insurer} </span>
              ))}
            </StyledTypography>

            <LocationContainer>
              <StyledTypography>{service.location.address}</StyledTypography>

              <StyledTypography>
                {service.location.zip} {service.location.province}
              </StyledTypography>
            </LocationContainer>
          </StyledCol>

          <Col flex="20px">
            <StyledIconContainer onClick={() => setIsDetailsModalOpen(true)}>
              <CustomIcon icon="mail" />
            </StyledIconContainer>

            <Popover content={isMapboxSupported ? '' : i18n('services.mapViewNotSupported')}>
              <StyledIconContainer
                onClick={() => {
                  if (isMapboxSupported) {
                    map?.flyTo({
                      center: [service.location.longitude, service.location.latitude],
                      zoom: map.getZoom() + 1,
                    })
                    setSelectedService(service)
                  }
                }}
              >
                <CustomIcon icon="navigation" />
              </StyledIconContainer>
            </Popover>
          </Col>
        </StyledRow>

        <StyledRow>
          <Col flex="auto">
            <OpeningHoursDisplay openingHours={service.openingHours} />
          </Col>
        </StyledRow>

        <ServiceDetailsModal
          service={service}
          isDetailsModalOpen={isDetailsModalOpen}
          setIsDetailsModalOpen={setIsDetailsModalOpen}
        />
      </div>
    </CSSTransition>
  )
}
