import React, { FC, ReactNode, useState } from 'react'
import { Select } from 'antd'
import { WrapperWithTitleAndAnimation } from '../../../components/WrapperWithTitleAndAnimation'

interface Props {
  options: Array<{ value: string; label: string }>
  title: string
  isLoading: boolean
  onSelect: (selected: string) => void
  placeholder?: string
  prefixIcon?: ReactNode
  isMulti?: boolean
}

export const SelectInput: FC<Props> = (props) => {
  const { options, title, isLoading, onSelect, placeholder, prefixIcon, isMulti } = props
  const [selectedAnswer, setSelectedAnswer] = useState<string | null>()

  return (
    <WrapperWithTitleAndAnimation title={title} isAnimated animationStartsWhen={!isLoading}>
      <div className="buttonContainer">
        <div style={{ margin: '40px auto 30px', position: 'relative', width: '100%' }}>
          {prefixIcon && (
            <div
              style={{
                position: 'absolute',
                zIndex: 1,
                width: '40px',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {prefixIcon}
            </div>
          )}
          <Select
            onChange={(val: string) => {
              setSelectedAnswer(val)
              onSelect(val)
            }}
            value={selectedAnswer}
            placeholder={placeholder}
            style={{ width: '100%' }}
            size="large"
            options={options}
            mode={isMulti ? 'multiple' : undefined}
          />
        </div>
      </div>
    </WrapperWithTitleAndAnimation>
  )
}
