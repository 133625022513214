import React, { FC, useCallback, useMemo, useState } from 'react'
import { TransitionGroup } from 'react-transition-group'
import { RiskTopicItem } from './RiskTopicItem'
import { RiskTopicListContainer } from './RiskTopicSelector.styled'

export interface RiskTopicView {
  id: string
  name: string
  isDisabled: boolean
  isSelected: boolean
}

interface Props {
  riskTopicViews: RiskTopicView[]
  isLoading: boolean
  onSelect: (answer: string | null) => void
}

export const RiskTopicList: FC<Props> = (props) => {
  const { riskTopicViews, isLoading, onSelect } = props
  const [selectedRiskTopicIds, setSelectedRiskTopicIds] = useState<string[]>([])

  const onRiskTopicSelect = useCallback(
    (id: string) => {
      setSelectedRiskTopicIds([id])
      onSelect(id)
    },
    [onSelect, setSelectedRiskTopicIds],
  )

  const riskTopicViewsWithSelection = useMemo(
    () =>
      riskTopicViews.map((riskTopic) => ({ ...riskTopic, isSelected: selectedRiskTopicIds.includes(riskTopic.id) })),
    [riskTopicViews, selectedRiskTopicIds],
  )

  return (
    <TransitionGroup component={null}>
      <RiskTopicListContainer>
        {riskTopicViewsWithSelection.map((riskTopicView, index) => (
          <RiskTopicItem
            key={riskTopicView.id}
            onSelected={() => onRiskTopicSelect(riskTopicView.id)}
            isLoading={isLoading}
            riskTopicView={riskTopicView}
          />
        ))}
      </RiskTopicListContainer>
    </TransitionGroup>
  )
}
