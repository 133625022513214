import React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { CSS_VARS } from '../../resources/cssVariableConfig'

/**
 * @returns The LoadingIndicator component
 */
export const LoadingIndicator = () => {
  return (
    <div className="loadingIndicator">
      <Spin
        indicator={<LoadingOutlined style={{ color: CSS_VARS.filledButtonBackgroundColor }} />}
        style={{ width: '100%' }}
      />
    </div>
  )
}
