import { createContext, useContext } from 'react'
import { Question, Report } from 'health-check-api'
import { startQuestion } from '../mock'
import { HealthCheckApi } from '../hooks/useApi'
import { FooterButtonState, StatusStep } from '../models'

export type HealthCheckContext = {
  report?: Report
  setReport: (val: Report) => void
  currentQuestion: Question
  setCurrentQuestion: (val: Question) => void
  isLoading: boolean
  setLoading: (val: boolean) => void
  checkId: string
  setCheckId: (checkId: string) => void
  selectedAnswer: string | null | string[]
  setSelectedAnswer: (selectedAnswer: string | null | string[]) => void
  api: HealthCheckApi
  footerButtonState: FooterButtonState
  setFooterButtonState: (state: FooterButtonState) => void
  statusStep: StatusStep
  setStatusStep: (step: StatusStep) => void
}

const initialValue: HealthCheckContext = {
  report: undefined,
  setReport: () => null,
  currentQuestion: startQuestion,
  setCurrentQuestion: () => null,
  isLoading: false,
  setLoading: () => null,
  checkId: '',
  setCheckId: () => null,
  selectedAnswer: null,
  setSelectedAnswer: () => null,
  api: {
    initialize: () => Promise.resolve(''),
    getQuestion: () => Promise.resolve(startQuestion),
    setAnswer: () => Promise.resolve(),
    getReport: () =>
      Promise.resolve({
        riskTopics: [],
        recommendation: { protective: [], negative: [] },
        profile: { age: 0, bmi: 0, height: 0, weight: 0, gender: 'Male' },
      }),
  },
  footerButtonState: {
    onNextButtonClick: null,
    nextButtonTitle: '',
    isNextButtonDisabled: false,
    isNextButtonHidden: false,
    isSkipButtonHidden: true,
    onSkipButtonClick: null,
  },
  setFooterButtonState: () => null,
  statusStep: 0,
  setStatusStep: () => null,
}

export const QuestionContext = createContext(initialValue)

export const useQuestionContext = () => useContext(QuestionContext)
