import React, { FC } from 'react'
import { Col } from 'antd'
import { useI18N } from '../../../../hooks'
import { SectionSubtitle, StyledRow, StyledText } from './Report.styled'

export const DisclaimerSection: FC = () => {
  const { i18n } = useI18N()

  return (
    <StyledRow gutter={[10, 10]} margin="0 0 36px 0">
      <Col span={24}>
        <SectionSubtitle>{i18n('general.important')}</SectionSubtitle>
      </Col>
      <Col span={24}>
        <StyledText>{i18n('report.bottomBoxText')}</StyledText>
      </Col>
    </StyledRow>
  )
}
