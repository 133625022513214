import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
  BasicQuestion,
  GrowingScaleQuestion,
  QUESTION_STATE_HEALTH_DATA,
  QUESTION_STATE_PROFILE,
  QUESTION_STATE_REPORT,
  QUESTION_STATE_RISK_TOPIC,
  QUESTION_TYPE_AGE,
  QUESTION_TYPE_BMI,
  QUESTION_TYPE_CONFIRM,
  QUESTION_TYPE_GENDER,
  QUESTION_TYPE_GROWINGSCALE,
  QUESTION_TYPE_MULTISELECT,
  QUESTION_TYPE_RISK_TOPIC,
  QUESTION_TYPE_SINGLESELECT,
  QUESTION_TYPE_TERMS,
  CHECK_ANSWER_TYPE_SKIPPED,
} from 'health-check-api'
import { LoadingIndicator } from '../../../components/LoadingIndicator/LoadingIndicator'
import { useQuestionContext } from '../context'
import { useWebAppConfigContext } from '../../../context'
import { getTempTranslation } from '../utils/getTempTranslation'
import { FooterButtonState } from '../models'
import { LegalDisclaimer } from './LegalDisclaimer'
import { AgeSelect } from './AgeSelect'
import { SingleSelect } from './SingleSelect'
import { SliderInput } from './SliderInput'
import { RiskTopicSelector } from './RiskTopicSelector'
import { Info } from './Info'
import { MultiSelect } from './MultiSelect'
import { BMI } from './BMI'

export const Renderer: FC = () => {
  const {
    currentQuestion,
    setFooterButtonState,
    api,
    checkId,
    setCheckId,
    setCurrentQuestion,
    setReport,
    setStatusStep,
    isLoading,
    selectedAnswer,
    setSelectedAnswer,
  } = useQuestionContext()
  const [component, setComponent] = useState(<LoadingIndicator />)
  const {
    webAppConfig: { privacyPolicyUrl, termsAndConditionsUrl },
  } = useWebAppConfigContext()

  const questionType = useMemo(() => currentQuestion.type, [currentQuestion])
  const currentState = useMemo(() => currentQuestion.state, [currentQuestion])

  const handleClick = useCallback(
    async (answer: string | null | string[]) => {
      await api.setAnswer(checkId, currentQuestion.id, answer)
      const question = await api.getQuestion(checkId)
      setCurrentQuestion(question)
      setSelectedAnswer(null)
      setComponent(<LoadingIndicator />)
    },
    [api, checkId, currentQuestion.id, setCurrentQuestion, setSelectedAnswer],
  )

  const handleReportClick = useCallback(async () => {
    await api.setAnswer(checkId, currentQuestion.id, '')
    const reportResponse = await api.getReport(checkId)
    setReport(reportResponse)
  }, [api, checkId, currentQuestion.id, setReport])

  const handleSingleSelectClick = useCallback(
    (answer: string) => {
      void handleClick(answer)
    },
    [handleClick],
  )

  const handleStartCheck = useCallback(async () => {
    const checkIdFromResponse = await api.initialize()
    const questionFromResponse = await api.getQuestion(checkIdFromResponse)
    setCheckId(checkIdFromResponse)
    setCurrentQuestion(questionFromResponse)
  }, [api, setCheckId, setCurrentQuestion])

  const setStatus = useCallback(() => {
    switch (currentQuestion.state) {
      case QUESTION_STATE_PROFILE:
        setStatusStep(1)
        break
      case QUESTION_STATE_RISK_TOPIC:
        setStatusStep(2)
        break
      case QUESTION_STATE_HEALTH_DATA:
        setStatusStep(3)
        break
      case QUESTION_STATE_REPORT:
        setStatusStep(4)
        break
      default:
        setStatusStep(0)
    }
  }, [currentQuestion.state, setStatusStep])

  const isMultiSelectNextButtonDisabledCondition = useMemo(() => {
    if (Array.isArray(selectedAnswer)) {
      return selectedAnswer.length < 1
    }
    return !selectedAnswer
  }, [selectedAnswer])

  useEffect(() => {
    const defaultFooterButtonState: FooterButtonState = {
      onNextButtonClick: () => handleClick(selectedAnswer),
      nextButtonTitle: 'Next',
      isNextButtonDisabled: !selectedAnswer,
      isNextButtonHidden: false,
      isSkipButtonHidden: true,
      onSkipButtonClick: null,
    }

    switch (questionType) {
      case QUESTION_TYPE_TERMS:
        setComponent(
          <LegalDisclaimer
            onStartCheck={handleStartCheck}
            onCancelCheck={() => window.location.reload()}
            termsAndConditionsUrl={termsAndConditionsUrl}
            privacyPolicyUrl={privacyPolicyUrl}
          />,
        )
        break
      case QUESTION_TYPE_AGE:
        setFooterButtonState(defaultFooterButtonState)
        setComponent(
          <AgeSelect
            title={currentQuestion.text}
            isLoading={isLoading}
            onSelect={(answer) => setSelectedAnswer(answer)}
          />,
        )
        break
      case QUESTION_TYPE_BMI:
        setFooterButtonState(defaultFooterButtonState)
        setComponent(
          <BMI
            isLoading={isLoading}
            title={currentQuestion.text}
            onCalculation={(calculatedBMI) => setSelectedAnswer(calculatedBMI)}
          />,
        )
        break
      case QUESTION_TYPE_SINGLESELECT:
        setFooterButtonState({
          onNextButtonClick: () => handleClick(selectedAnswer),
          nextButtonTitle: getTempTranslation('healthCheck.footer.next'),
          isNextButtonDisabled: !selectedAnswer,
          isNextButtonHidden: (currentQuestion.answers?.length ?? 0) < 7,
          isSkipButtonHidden: false,
          onSkipButtonClick: () => handleClick(CHECK_ANSWER_TYPE_SKIPPED),
        })
        setComponent(
          <SingleSelect
            title={currentQuestion.text}
            onDropdownSelect={(answerId) => setSelectedAnswer(answerId)}
            onSingleSelectClick={handleSingleSelectClick}
            answers={(currentQuestion as BasicQuestion).answers}
            isLoading={isLoading}
          />,
        )
        break
      case QUESTION_TYPE_MULTISELECT:
        setFooterButtonState({
          onNextButtonClick: () => handleClick(selectedAnswer),
          nextButtonTitle: getTempTranslation('healthCheck.footer.next'),
          isNextButtonDisabled: isMultiSelectNextButtonDisabledCondition,
          isNextButtonHidden: false,
          isSkipButtonHidden: false,
          onSkipButtonClick: () => handleClick(CHECK_ANSWER_TYPE_SKIPPED),
        })
        setComponent(
          <MultiSelect
            title={currentQuestion.text}
            onDropdownSelect={(answerId) => setSelectedAnswer(answerId)}
            onSingleSelectClick={handleSingleSelectClick}
            answers={(currentQuestion as BasicQuestion).answers}
            isLoading={isLoading}
          />,
        )
        break
      case QUESTION_TYPE_GENDER:
        setFooterButtonState({
          onNextButtonClick: () => handleClick(selectedAnswer),
          nextButtonTitle: '',
          isNextButtonDisabled: !selectedAnswer,
          isNextButtonHidden: true,
          isSkipButtonHidden: true,
          onSkipButtonClick: null,
        })
        setComponent(
          <SingleSelect
            title={currentQuestion.text}
            onDropdownSelect={(answerId) => setSelectedAnswer(answerId)}
            onSingleSelectClick={handleSingleSelectClick}
            answers={(currentQuestion as BasicQuestion).answers}
            isLoading={isLoading}
          />,
        )
        break
      case QUESTION_TYPE_GROWINGSCALE:
        setFooterButtonState({
          onNextButtonClick: () => handleClick(selectedAnswer),
          nextButtonTitle: 'Next',
          isNextButtonDisabled: !selectedAnswer,
          isNextButtonHidden: false,
          isSkipButtonHidden: false,
          onSkipButtonClick: () => handleClick(CHECK_ANSWER_TYPE_SKIPPED),
        })
        setComponent(
          <SliderInput
            title={currentQuestion.text}
            isLoading={isLoading}
            answers={(currentQuestion as GrowingScaleQuestion).answers}
            onSelect={(answerId) => setSelectedAnswer(answerId)}
          />,
        )
        break
      case QUESTION_TYPE_RISK_TOPIC:
        setFooterButtonState(defaultFooterButtonState)
        setComponent(
          <RiskTopicSelector
            title={currentQuestion.text}
            isLoading={isLoading}
            answers={(currentQuestion as BasicQuestion).answers}
            onSelect={(answer: string | null) => setSelectedAnswer(answer)}
          />,
        )
        break
      case QUESTION_TYPE_CONFIRM:
        setFooterButtonState({
          onNextButtonClick: handleReportClick,
          nextButtonTitle: getTempTranslation('healthCheck.footer.understood'),
          isNextButtonHidden: false,
          isNextButtonDisabled: false,
          isSkipButtonHidden: true,
          onSkipButtonClick: null,
        })
        setComponent(<Info questionText={currentQuestion.text} isLoading={isLoading} />)
        break
      default:
        setComponent(<LoadingIndicator />)
        break
    }
    setStatus()
  }, [
    setStatus,
    currentQuestion,
    handleClick,
    handleReportClick,
    questionType,
    selectedAnswer,
    setFooterButtonState,
    currentState,
    setStatusStep,
    isLoading,
    setSelectedAnswer,
    handleSingleSelectClick,
    handleStartCheck,
    termsAndConditionsUrl,
    privacyPolicyUrl,
    isMultiSelectNextButtonDisabledCondition,
  ])

  return component
}
