import styled from '@emotion/styled'
import { Button, Row, Typography } from 'antd'
import { containerCommon } from '../Filters.common.styled'
import { CSS_VARS } from '../../../../../resources/cssVariableConfig'

export const Container = styled(Row)`
  ${containerCommon}

  article {
    line-height: 30px;
  }
`

export const DateButton = styled(Button)`
  box-shadow: none;
  border-radius: 7px !important;
  font-size: 10px !important;
  width: 45%;

  span {
    font-family: ${CSS_VARS.fontFamily};
  }
`

export const StyledTypography = styled(Typography)`
  width: 17%;
  color: ${CSS_VARS.darkTextColor};
  font-size: 11px;
`

export const DateRow = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;

  article {
    font-family: ${CSS_VARS.fontFamily};
  }
`

export const DateContainer = styled(Row)`
  width: 83%;
`
