import { PlusOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Button, Row } from 'antd'
import { ApplyFilterButton, ResetFilterButton } from './Filters.styled'

export const containerCommon = css`
  padding: 0 0 10px;
`

export const Container = styled(Row)`
  ${containerCommon}
`

export const buttonCommon = css`
  margin-right: 5px;
  margin-top: 5px;
  border-radius: 30px;

  &:hover,
  &:active {
    opacity: 0.8;
  }
`

export const FilterButton = styled(Button)`
  ${buttonCommon}
`

export const RemoveFilterIcon = styled(PlusOutlined)`
  transform: rotate(-45deg);
`

export const FilterDetailedViewContainer = styled.div`
  margin: 0 20px;
`

export const FilterDetailedViewClearButton = styled(ResetFilterButton)`
  min-width: 116px;
`

export const FilterDetailedViewSubmitButton = styled(ApplyFilterButton)`
  min-width: 116px;
`
